import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import history from "../store/history"
import api from "../middleware/api"
import thunk from 'redux-thunk'
import rootReducer from '../reducers'

const middlewareEnhancer = applyMiddleware(thunk, api, routerMiddleware(history))
const composedEnhancers = compose(
  middlewareEnhancer,
)

export default function configureStore(preloadedState) {
  return createStore(rootReducer(history), preloadedState, composedEnhancers)
}
