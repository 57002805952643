import React from 'react'
import Role from "./Role"
import {RadarChart, PolarGrid, PolarAngleAxis, Radar, Legend, PolarRadiusAxis} from "recharts"
import {
  Link
} from "react-router-dom";
import { roleUrls } from "../components/roles/Main"
import colors from "../constants/colors"
import roles from "../utils/roles"
class RoleChart extends React.Component {
  render() {
    const {scoredRoles, size = "lg", name = "You"} = this.props
    let maxMark = 0
    const orderedRoles = roles.map((role) => {
      const resp = scoredRoles.find((r) => role.id === r.id)
      return resp
    }) 
    let iw = window.innerWidth < 800 ? 400 : 800
    let ih = window.innerWidth < 800 ? 250 : 500
    let io = window.innerWidth < 800 ? 90 : 220
    let fontSize = "14px"
    if (this.props.size == "md") {
      iw = 500
      ih = 300
      io = 130
    } else if (this.props.size == "sm") {
      iw = 280
      ih = 200
      io = 50
      fontSize = "12px"
    }

    return <RadarChart outerRadius={io} width={iw} height={ih} data={orderedRoles}>
      <PolarGrid />
      <PolarAngleAxis dataKey="name" tick={{fill: "rgba(1,1,1,.8)", fontSize}}/>
      { size !== "sm" &&
        <PolarRadiusAxis angle={30} domain={[0, 100]} />
      }
      <Radar name={name} animationDuration={1000} legendType="none" dataKey="percent" stroke={colors.primary} fill={colors.primary} fillOpacity={0.6} />
    </RadarChart>
  }
}

export default RoleChart
