import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import {
  Link
} from "react-router-dom";
import {entitiesGetAccount} from "../../actions/entities"

class Account extends Component {
  constructor() {
    super()
    this.state = {
    }
  }
  componentDidMount() {
    this.props.entitiesGetAccount()
  }
  render() {
    const {currentUser, account, users, organizations} = this.props

    if (!account) return <div>No account associated to your user</div>
    return <div>
      <h4>{account.name}</h4>
      <div>Users</div>
      <div style={{padding: "5px"}}>
        {
          account.users && account.users.map((uid) => {
            const user = users[uid]
            return <div key={uid}>
              {user.email}
            </div>

          })

        }
      </div>
      <hr/>
      <div>Team Sessions</div>
      <div style={{padding: "5px"}}>
        <table className="table">
          <thead>
            <tr>
              <th>Trainer</th>
              <th>Session Name</th>
              <th>Active / Expired</th>
              <th>Created At</th>
            </tr>
          </thead>
          <tbody>
            {account.organizations && account.organizations.map((oId) => {
              const organization = organizations[oId]
              const user = users[organization.userId]
              return <tr key={oId}>
                <td>
                  {user.email}
                </td>
                <td>
                  <Link to={organization.slug}>{ organization.name }</Link>
                </td>
                <td>
                  {organization.expired ? "Expired" : "Active"}
                </td>
                <td>
                  { moment(organization.createdAt).format("LLLL") }
                </td>
              </tr>
            })}
          </tbody>
        </table>
      </div>
    </div>
  }
}

function mapStateToProps(state, props) {
  const {currentUser} = state.currentUser
  const {accounts, users, organizations} = state.entities
  const account = accounts[currentUser.account]
  return {
    currentUser,
    account,
    users,
    organizations
  }
}
export default connect(mapStateToProps, {
  entitiesGetAccount
})(Account)
