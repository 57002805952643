import Cookies from 'cookies-js';
import { push } from 'connected-react-router'
import { CALL_API } from '../middleware/api'
import {Schemas} from "../middleware/schemas";

export const currentUserClear = () => {
  return {
    type: "CURRENT_USER_CLEAR",
  }
}

export const currentUserGet = () => {
  return (dispatch, getState) => {
    const auth = getState().currentUser.auth
    if (!auth) return Promise.resolve()

    return dispatch(
      {
        ['CALL_API']: {
          types: [ "GET_REQUEST", "CURRENT_USER_SUCCESS", "CURRENT_USER_FAILURE" ],
          endpoint: `current_user`,
          ext_req: {method: "get"},
          schema: Schemas.user
        }
      }
    )
  }
};

export const currentUserLogout = () => {
  return {
    ['CALL_API']: {
      types: [ "GET_REQUEST", "CURRENT_USER_LOGOUT_SUCCESS", "CURRENT_USER_LOGOUT_FAILURE" ],
      endpoint: `current_user`,
      ext_req: {method: "delete"},
      schema: Schemas.user
    }
  };
};

export const userForgotPassord = (email) => {
  return {
    [CALL_API]: {
      types: ['REQUEST', 'USERS_FORGOT_PASSWORD_SUCCESS', 'FAILURE'],
      endpoint: 'users/forgot_password',
      schemaless: true,
      ext_req: { method: 'post', body: {email}}
    }
  }
}

export const userResetPassord = (form) => {
  return {
    [CALL_API]: {
      types: ['REQUEST', 'LOGIN_USER_SUCCESS', 'FAILURE'],
      endpoint: 'users/reset_password',
      ext_req: {
        method: 'post',
        body: form,
      },
      schema: Schemas.user
    }
  }
}
// export const currentUser = () => {
//   return { type: 'LOGOUT_USER' }
// }

export const currentUserSignup = (params) => {
  return (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: ['SIGNUP_USER_REQUEST', 'LOGIN_USER_SUCCESS', 'SIGNUP_USER_FAILURE'],
        endpoint: 'users',
        ext_req: { method: 'post', body: params },
        schema: Schemas.user
      }
    }).then((action) => {
      if (action.type === "LOGIN_USER_SUCCESS") {
        dispatch(push({
          pathname: "/u/results",
        }))
      }

      return Promise.resolve(action)
    })
  }
}

export const currentUserLogin = (params) => {
  return (dispatch, getState) => {
    return dispatch({
      [CALL_API]: {
        types: ['LOGIN_USER_REQUEST', 'LOGIN_USER_SUCCESS', 'LOGIN_USER_FAILURE'],
        endpoint: 'users/login',
        ext_req: { method: 'post', body: params },
        schema: Schemas.user
      }
    }).then((action) => {
      if (action.type === "LOGIN_USER_SUCCESS") {
        dispatch(push({
          pathname: "/u/results",
        }))
      }

      return Promise.resolve(action)
    })
  }
}
