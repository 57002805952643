import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import {
  Link
} from "react-router-dom";
import _ from "lodash"
import BreadCrumbs from "../../components/BreadCrumbs"
import { accountSearchUser } from "../../actions/account"


class Users extends Component {
  constructor() {
    super()
    this.state = {
      value: "",
      results: []
    }

    this.debounced =  _.debounce((inp) => {
      this.search()
    }, 300, {
      'leading': true,
      'trailing': false
    })
  }

  componentDidMount() {
  }

  search() {
    const value = this.state.value
    this.props.accountSearchUser(this.state.value).then((action) => {
      if (value === this.state.value) {
        this.setState({results: action.response.data})
      }
    })
  }

  onChange(e) {
    const value = e.target.value
    this.setState({value}, () => {
      if (value.length > 2) {
        this.search()
      } else {
        this.setState({results: []})
      }
    })
  }
  render() {
    const crumbs = [
      {name: "Users " },
    ]

    return <div>
      <BreadCrumbs crumbs={crumbs}/>
      <input
        value={this.state.value}
        placeholder="Search for user"
        className="form-control"
        onChange={(e) => {
          this.onChange(e)
        }}
      />
      <table className="table">
        <tr>
          <th>Email</th>
          <th>Quiz Link</th>
          <th>Org Link</th>
          <th>Created At</th>
        </tr>
        {
          this.state.results.map((user) => {
            return <tr id={user.id}>
              <td>{user.email}</td>
              <td><a href={user.slug}>Results</a></td>
              <td><a href={user.organizationSlug}>{user.organizationName}</a></td>
              <td>{user.createdAt.split("T")[0]}</td>
            </tr>
          })
        }
      </table>
    </div>
  }
}

function mapStateToProps(state, props) {
  return {
  }
}

export default connect(mapStateToProps, {
  accountSearchUser
})(Users)

