import React from 'react'
import {
  Link
} from "react-router-dom";
import SideNav from "./SideNav"
import logo from "../assets/images/small-logo.jpg"

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export default class LeftNav extends React.Component {
  constructor() {
    super()
    this.state = {
      height: this.height()
    }
    this.resize = this.resize.bind(this)
  }
  componentDidMount() {
    this.resizeListener = window.addEventListener("resize", this.resize)
    // this.props.accountsFetchUsers().then(() => {
    // })
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize)
  }
  resize() {
    this.setState({height: this.height()})
  }
  height() {
    return window.innerHeight
  }

  renderNavItem({ name, icon, url, exactUrl, color }) {
    let regexp = `^${escapeRegExp(url)}`
    const pn = window.location.pathname
    let selected = null
    if (exactUrl) {
      selected = pn === exactUrl
    } else {
      selected = pn.match(regexp)
    }
    return <Link key={name} to={exactUrl || url}>
        <div style={{padding: "10px 5px", position: "relative", backgroundColor: selected ? "rgba(1,1,1,.05)" : "transparent", cursor: "pointer", borderBottom: "1px solid rgba(1,1,1,.1)"}}
        className="text-center">
          <button  className="btn btn-link" style={{ color: color || "rgba(1,1,1,.8)", padding: 0}}>
            <i className={`fas ${icon}`} style={{fontSize: "20px"}}></i>
            <div>
              {name}
            </div>
        </button>
      </div>
    </Link>
  }
  renderUtilityNav() {
    return <SideNav
      style={{width: "120px"}}
      height={this.state.height}
      onClose={() => {}}
      paddingless
      static
    >
      <div style={{borderBottom: "1px solid rgba(1,1,1,.1)"}}>
        <div className="text-center" style={{margin: "10px 0"}}>
          <Link  to={"/"} style={{ textDecoration: "none", color: "black"}}>
            <img style={{width: "50px"}} src={logo}/>
          </Link>
        </div>
      </div>
      <div>
        { this.props.navItems.map((item) => {
          return this.renderNavItem(item) 
        })
        }
      </div>
    </SideNav>
  }
  render() {
    return <div style={{display: "flex"}}>
      {this.renderUtilityNav()}
      <div style={{display: "flex", flex: 1, flexDirection: "row", minHeight: this.state.height}}>
        {this.props.children}
      </div>
    </div>
  }
}

