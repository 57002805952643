import _ from "lodash"
import Cookies from 'cookies-js';
import mixpanel from '../utils/mixpanel'

const merge = (state, other) => {
  return _.mergeWith({}, state, other, (a, b) => {
    if (b && b.constructor === Array) {
      return b
    }
  })
} 

const defaultState = (auth) => {
  return {
    currentUser: null,
    email: null,
    auth
  }
}

const actions = {
  CURRENT_USER_SUCCESS: (state, action) => {
    const {result, entities: {users}} = action.response
    const newState = merge(state, {currentUser: users[result]})
    mixpanel.identify(newState.currentUser.email)
    newState.email = newState.currentUser.email
    newState.auth = newState.currentUser.authToken
    Cookies.set("auth", newState.auth, { expires: Infinity })
    return newState
  },
  CURRENT_USER_CLEAR: (state, action) => {
    return defaultState(null)
  },
  CURRENT_USER_FAILURE: (state, action) => {
    return defaultState(null)
  },
  CURRENT_USER_LOGOUT_SUCCESS: (state, action) => {
    Cookies.set("auth", null)
    return defaultState(null)
  }
}
export default function currentUser(state = defaultState(Cookies.get("auth")), action) {
  if (action.type === "LOGIN_USER_SUCCESS") {
    return actions.CURRENT_USER_SUCCESS(state, action)
  } else if (action.type.match(/^CURRENT_USER_/)) {
    if (actions[action.type]) {
      return actions[action.type](state, action)
    } else {
      console.error("Action not included in currentUser reducer", action)
    }
  }

  return state
}
