import React from 'react'
import { connect } from 'react-redux'
import NavUser from "../../components/NavUser"
import {
  Switch,
  Route,
} from "react-router-dom";
import {
  Link
} from "react-router-dom";
import Organizations from "./Organizations"
import Organization from "../organizations/Show"
import AccountSessions from "./AccountSessions"
import Users from "./Users"
import Companies from "./Companies"
import Company from "./Company"
import Department from "./Department"
import LeftNav from "../../components/LeftNav"
import logo from "../../assets/images/small-logo.jpg"
import colors from "../../constants/colors"


function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

class AccountIndex extends React.Component {
  render() {
    const {currentUser, account, users, organizations} = this.props

    if (!account) return <div>No account associated to your user</div>
    const navItems = [
      {
        name: "Team Sessions",
        icon: "fa-laptop-house",
        exactUrl: "/account",
        url: "/account",
        color: colors.account
      },
      {
        name: "Account Sessions",
        icon: "fa-laptop-house",
        exactUrl: "/account/account_sessions",
        url: "/account/account_sessions",
        color: colors.account
      },
      {
        name: "Companies",
        icon: "fa-users-cog",
        url: "/account/companies",
        color: colors.account
      },
      {
        name: "Users",
        icon: "fa-users",
        url: "/account/users",
        color: colors.account
      },
      {
        name: "My Results",
        icon: "fa-list",
        url: "/u"
      }
    ]
    if (currentUser.role == "admin") {
      navItems.push({
        name: "Site Admin",
        icon: "fa-user",
        color: colors.admin,
        url: "/admin"
      })
    }
    return <LeftNav navItems={navItems}>
      <div style={{width: "100%"}}>
        <div className="container">
          <div style={{borderBottom: "1px solid rgba(1,1,1,.1)", padding: "20px 0 10px", marginBottom: "15px"}}>
            <h4 style={{marginLeft: "15px"}}>Account: {account.name}</h4>
          </div>
        </div>
        <div className="container">
          <Switch>
            <Route exact path="/account" component={Organizations} />
            <Route exact path="/account/account_sessions" component={AccountSessions} />
            <Route exact path="/account/companies" component={Companies} />
            <Route exact path="/account/companies/:idString" component={Company} />
            <Route exact path="/account/users" component={Users} />
            <Route exact path="/account/departments/:idString" component={Department} />
            <Route path="/account/organizations/:organizationId" component={Organization} />
          </Switch>
        </div>
      </div>
    </LeftNav>
  }
}

function mapStateToProps(state, props) {
  const {currentUser} = state.currentUser
  const {accounts, users} = state.entities
  const account = accounts[currentUser.account]
  return {
    currentUser,
    account,
    // users,
    // organizations
  }
}

export default connect(mapStateToProps, {
})(AccountIndex)
