import React from 'react'
import {LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Line, Legend } from "recharts"
import {
  Link
} from "react-router-dom";
import { roleUrls } from "../components/roles/Main"
import colors from "../constants/colors"
import roles from "../utils/roles"
let data = [
  {
    "name": "Quiz A",
    "uv": 4000,
    "pv": 2400,
    // "amt": 2400
  },
  {
    "name": "Quiz B",
    "uv": 3000,
    "pv": 1398,
    // "amt": 2210
  },
]
  

class RoleChart extends React.Component {
  render() {
    const {quizInstances, roleKey} = this.props
    const data = quizInstances.map((qi, i) => {
      const resp = {
        name: `${qi.name.split(" ")[0]}`,
      }

      qi.normalizedSummary.roles.forEach((r) => {
        resp[r.name] = r.percent
      })

      return resp
    })

    return <div style={{display: "flex", justifyContent: "center"}}>
      <LineChart width={900} height={250} data={data}
      >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      {
        roles.map((role) => {
          if (roleKey && role.name !== roleKey) return null
          return <Line key={role.name} type="monotone" dataKey={role.name} stroke={role.color} />
        })
      }
    </LineChart>
    </div>
  }
}

export default RoleChart
