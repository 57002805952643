import React from 'react'
import {
  Link
} from "react-router-dom";
import colors from '../constants/colors'
const TakeQuiz = ({}) =>{
  return <Link to={"/quiz"}>
    <button className="btn" style={{verticalAlign: "middle", borderRadius: "50px", backgroundColor: colors.secondary, color: "white", border: "none", fontWeight: "bold", width: "220px", padding: "7px"}} >
      <i style={{marginRight: "10px", fontSize: "15px"}} className="fa fa-wpforms" aria-hidden="true"></i>
      TAKE THE QUIZ
    </button>
  </Link>

}
export default TakeQuiz
