import React, { Component } from 'react'
import humps from "humps"
import { connect } from 'react-redux'
import { userResetPassord } from '../actions/currentUser'
import queryString from 'query-string';
import mixpanel from '../utils/mixpanel'

export const parseQueryString = (search) => {
  if (!search) search = window.location.search

  const resp = humps.camelizeKeys(queryString.parse(search, {arrayFormat: 'bracket'}))
  return resp
}

export const parseQuery = () => {
  const {search} = window.location
  return parseQueryString(search)
}

class PasswordReset extends Component {
  constructor() {
    super()
    this.state = {
      saving: false,
      password: "",
      confirmPassword: ""
    }

    this.save = this.save.bind(this)
  }
  componentDidMount() {
    mixpanel.page()
  }

  redirect() {
    window.location = "/u/results"
  }
  disabled() {
    if (this.state.saving) return true
    if (this.state.password.length < 6) return true
    if (this.state.password.length !== this.state.confirmPassword.length) return true
    return false
  }

  save() {
    if (this.disabled()) return
    this.setState({saving: true}, () => {
      this.props.userResetPassord({token: this.props.token, password: this.state.password}).then((action) => {
        if (action.type !== "FAILURE") {
          this.redirect()
        }
      })
    })
  }

  render() {
    return <div className="container">
      <div style={{width: "350px", margin: "15px auto"}}>
        <h4 className="text-center">Reset Password</h4>
        <input style={{marginBottom: "10px"}} onChange={(e) => this.setState({password: e.target.value})} className="form-control" type="password" placeholder="Password"/>
        <input style={{marginBottom: "10px"}} onChange={(e) => this.setState({confirmPassword: e.target.value})} className="form-control" type="password" placeholder="Confirm Password"/>
        <button className="btn btn-success" onClick={this.save}  disabled={this.disabled()} style={{width: "100%"}}>Reset Password</button>
        <div style={{marginTop: "10px"}} className="text-muted text-center">Passwords must be > 6 characters</div>
      </div>
    </div>
  }
}

function mapStateToProps(state, props) {
  const parsed = parseQuery()
  return {
    token: parsed.resetPasswordToken
  }
}
export default connect(mapStateToProps, {
  userResetPassord
})(PasswordReset)
