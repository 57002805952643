import React, { Component } from 'react'
import { connect } from 'react-redux'
import { entitiesGetTeam } from "../actions/entities"
import moment from "moment"
import {RadarChart, PolarGrid, PolarAngleAxis, Radar, Legend, PolarRadiusAxis} from "recharts"
import RoleTable from "../components/RoleTable"
import RoleChart from "../components/RoleChart"
import StrongestWeakest from "../components/StrongestWeakest"
import oRoles from "../utils/roles"
import mixpanel from '../utils/mixpanel'
import "../stylesheets/team.css"

class Team extends Component {
  componentDidMount() {
    mixpanel.page()
    this.props.entitiesGetTeam(this.props.teamId)
  }
  getRoles() {
    const {team} = this.props
    let roles = {}

    team.quizInstances.forEach((qi) => {
      qi.normalizedSummary.roles.forEach((role) => {
        const percent = role.percent
        if (!roles[role.id]) {
          roles[role.id] = JSON.parse(JSON.stringify(role))
          roles[role.id].percent = percent
        } else {
          roles[role.id].percent += percent
        }
      })
    })
    const preResp = Object.keys(roles).map((key) => {
      const role = roles[key]
      return {percent: Math.round(role.percent / team.quizInstances.length), id: role.id, name: role.name}
    })
    return oRoles.map((persona) => {
      const role = preResp.find((role) => role.id === persona.id)
      role.persona = persona
      return role
    }) 
  }
  renderRoles() {
    const roles = this.getRoles()
    const {team} = this.props

    return <div>
        <h3 className="text-center" style={{marginTop: "30px", marginBottom: "30px"}}>Our Team Role Results</h3>
        <div style={{marginTop: "15px", display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
          <div style={{marginBottom: "30px"}}>
            <RoleTable orderedRoles={roles}/>
          </div>
        <div style={{display: "flex", justifyContent: "center"}}>
            <RoleChart name="Your Team" size="md" scoredRoles={roles}/>
        </div>
      </div>
      <hr/>
      <div>
        <h3 className="text-center">Your Team</h3>
        {this.renderTeamGrid()}
      </div>
      <hr/>
      <StrongestWeakest quizId={team.quizId} orderedRoles={roles}/>
    </div>

  }
  renderTeamGrid() {
    const {team} = this.props
    return <div className="team-grid">
        {team.quizInstances.map((qi) => {
          const orderedRoles = qi.summary.roles.sort((a, b) => {
            if (a.percent > b.percent) return -1
            if (a.percent < b.percent) return 1
            return 0
          })
          // const quizInstance = qi
          // const summary = quizInstance.summary
          // const roles = summary.roles
          // const orderedRoles = quizInstance.personas.map((persona) => {
          //   const role = roles.find((role) => role.id === persona.id)
          //   role.fullMark = 16
          //   role.orderKey = persona.orderKey
          //   role.persona = persona
          //   return role
          // }) 
          return <div key={qi.id} className="member" style={{marginBottom: "5px"}}>
            <div className="text-center">
              <h5 style={{margin: 0}}>{qi.name}</h5>
              <hr/>
              <div className="text-muted">{qi.email}</div>
              <div className="text-muted">{moment(qi.createdAt).fromNow()}</div>
            </div>

            <div style={{display: "flex", justifyContent: "center", margin: "15px 0"}}>
              <div>
                <RoleTable size="sm" orderedRoles={orderedRoles}/>
              </div>
              <RoleChart name={qi.name || qi.email} size="sm" scoredRoles={orderedRoles}/>
            </div>
            <textarea className="form-control"/>
          </div>
        })}
      </div>
  }
  render() {
    const {team} = this.props
    if (!team) return <div className="text-center" style={{marginTop: "15px"}}>Loading...</div>
    if (!team.quizInstances.length === 0) return <div className="text-center" style={{marginTop: "15px"}}>No results to show. Have your team take the quiz</div>

    return <div className="container print" style={{marginTop: "15px"}}>
      {this.renderRoles()}
    </div>
  }
}

function mapStateToProps(state, props) {
  const {teamId} = props.match.params
  const {teams} = state.entities
  const team = teams[teamId]

  return {
    teamId,
    team
  }
}
export default connect(mapStateToProps, {
  entitiesGetTeam
})(Team)
