import React from 'react'
import { connect } from 'react-redux'
import { entitiesGetQuiz, entitiesUpdateQuiz } from "../../actions/entities"
import Question from "../../components/admin/Question"
import NewQuestion from "../../components/admin/NewQuestion"

class Quiz extends React.Component {
  constructor() {
    super()
    this.state = {
      newQuestion: false,
      description: "",
      name: ""
    }
  }
  componentDidMount() {
    this.props.entitiesGetQuiz(this.props.id).then((action) => {
      const {result, entities: {quizzes}} = action.response
      const quiz = quizzes[result]
      this.setState({name: quiz.name, description: quiz.description})
    })
  }

  render() {
    const {quiz, questions, personas} = this.props
    if (!quiz) return <div className="text-center">Loading...</div>

    return <div className="container"  style={{marginBottom: "30px"}}>
      <div className="card" style={{marginTop: "15px"}}>
        <div className="card-body">
          <h5>Quiz</h5>
          <input className="form-control" placeholder="Quiz Name" value={this.state.name}/>
          <div style={{margin: "15px 0"}}>
            <textarea className="form-control" onChange={(e) => {
              this.setState({description: e.target.value})
            }} placeholder="Quiz Description" value={this.state.description}/>
          </div>
          <div>
            <button onClick={() => {
              const {name, description} = this.state
              this.props.entitiesUpdateQuiz(quiz.id, {
                name,
                description
              })
              }} className="btn btn-success" style={{width: "200px"}}>
              Save
            </button>
          </div>
        </div>
      </div>
          { questions.map((question, i) => {
              return <Question number={i + 1} key={question.id} question={question} quiz={quiz}/>
            })
          }
      <div >
      { this.state.newQuestion &&
        <NewQuestion number={questions.length + 1} quiz={quiz} onCreate={() => this.setState({newQuestion: false})}/>
      }
      </div>
        <div className="text-right" style={{marginTop: "15px"}}>
          <button style={{marginTop: "15px", width: "200px"}} className="btn btn-primary" onClick={() => {
            this.setState({newQuestion: true})
          }}>
            New Question
          </button>
        </div>
    </div>
  }
}

const mapStateToProps = (state, props) => {
  const {id} = props.match.params
  const {quizzes, questions} = state.entities
  const quiz = quizzes[id]
  if (!quiz) return {
    id
  }
  return {
    id,
    quiz,
    questions: quiz.questions ? quiz.questions.map((q) => questions[q]) : []
  }
}

export default connect(mapStateToProps, {
  entitiesGetQuiz,
  entitiesUpdateQuiz
})(Quiz)
