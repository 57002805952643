import React, { Component } from 'react'
import { connect } from 'react-redux'
import prophet from "../../assets/images/prophet.png"
import implementor from "../../assets/images/implementor.jpg"
import conceptualizer from "../../assets/images/conceptualizer.jpg"
import luminary from "../../assets/images/luminary.jpg"
import investor from "../../assets/images/investor.png"
import organizer from "../../assets/images/organizer.jpg"
import teambuilder from "../../assets/images/teambuilder.jpg"
import { entitiesGetRoleSummary } from "../../actions/entities"

export const urlRoles = {
  "prophet": "Prophet",
  "implementor": "Implementor",
  "conceptualizer": "Conceptualizer",
  "luminary": "Luminary",
  "investor": "Investor",
  "organizer": "Organizer",
  "team-builder": "Team Builder",
}

const ru = {}

Object.keys(urlRoles).forEach((key) => {
  const v = urlRoles[key]
  ru[v] = key
})

export const roleUrls = ru

const roles =  {
  "Prophet": {
    title: "Clarifies Vision and Long Term Strategy",
    bio: "The Prophet role identifies the destination.  Not the near term, what are we doing tomorrow but the longer-term view, often referred to as the vision.  A key component of this role is a critique of the status quo to determine its weaknesses or shortcomings.  The vision that results addresses or solves these problems but usually not without meeting some resistance to the changes necessary.",
    image: prophet,
    questions: [
      "If the current path is continued, where will it eventually lead?  ",
      "What flaws does this reveal?",
      "What course correction is needed?",
    ],
    special: [
      "Sees the flaws in the existing system.",
      "Identifies a vision that addresses or solves the problems of the status quo.",
      "Clearly articulates the path to future success, the vision.",
    ],
    resistance: [
      "Most of the other roles work to make the status quo run better.  The Prophet Role introduces change to the status quo making others uncomfortable with the new expectations changes bring.",
      "The Prophet Role can introduce change too quickly, without sufficient engagement or understanding of others.  It is important that this role takes the time to allow for each of the other roles to process what changes are required to implement the new vision.",
    ]
  },
  "Implementor": {
    title: "Execution Focused, Task Motivated",
    bio: "The Implementor Role executes the steps needed to complete the required tasks.  This role is motivated by a completed task list.  When a task is presented, the Implementor Role envisions the incremental steps needed to complete it.  This planning skill can be used to project time estimates and resource requirements.  There is a focus on details and an orderly progression of the necessary steps.  Supporting and helping others to complete their tasks is also associated with this role but they prefer to work on their own.",
    image: implementor,
    questions: [
      "What needs to be done?",
      "How can the job be broken down into identifiable, manageable tasks?",
      "How can I help?",
    ],
    special: [
      "The Implementor Role is focused on execution.  This role gets things done.",
      "Clearly see the steps necessary to complete an idea or task.",
      "Availability is a core strength of this role.",
    ],
    resistance: [
      "The Implementor Role is motivated by task execution and completion.  If the task is not clearly defined, or the resources unavailable to complete it, they lose momentum..   It is important that vision be clearly articulated for the role to thrive.",
      "Since the role values being helpful, time management can be a challenge as they can agree to too many tasks. Prioritization of tasks becomes a useful and necessary skill for this role.",
    ]
  },
  "Conceptualizer": {
    title: "Knowledgeable, Analytical and Validates Options",
    bio: "The Conceptualizer role seeks to understand, clarify and validate processes.  The role invests in acquiring information and uses it to validate the credibility of the company or idea.  Details, facts and specifics are all important to this role and when it comes to data, more is better.   This role is also important for clarifying descriptors of a system including definitions, concepts, training material, and analytics.",
    image: conceptualizer,
    questions: [
      "What information is needed to understand this system?",
      "Can the information be validated?",
      "How can this information be accurately shared with others?",
    ],
    special: [
      "This role invests in understanding the system and values precision in the details.",
      "Decision making is thoughtful and based on supportable facts.",
      "Can clarify the way a system works in definitions, concepts, training or analytics.",
    ],
    resistance: [
      "The Conceptualizer Role is focussed on information gathering to optimize actions.  This can result in getting stuck in the details.   This role can frustrate people by placing a higher value on data and logic than emotion or gut.",
      "The Conceptualizer Role can be overly focussed on verifying or validating data which cannot be definitively proved.  This can lead to being risk-averse.  It is important that this role assesses this through self-inspection and more importantly through trusted advisors.  Adhering to a time schedule can also help.",
    ]
  },
  "Luminary": {
    title: "Dynamic, Engaging and Inspiring",
    bio: "The Luminary Role attracts people to a process.  They are focused on expansion by increasing interest, enthusiasm or engagement.  They are motivated by competition.  This role exerts influence on others through encouragement, intensity or by challenging them.  A key component of the Luminary Role is maintaining an active and diverse network.  Their network is frequently accessed and often shared.  They enjoy connecting people.",
    image: luminary,
    questions: [
      "Who will I talk to today to expand or enhance this project?",
      "Who needs to be challenged to do or be more and how can I engage them toward that?",
      "Who can I connect with today?",
    ],
    special: [
      "The Luminary Role attracts others to a team, product or project.",
      "They are likable and bold, making them influential.",
      "This role is a good communicator, generates enthusiasm, and expands their network.",
    ],
    resistance: [
      "The Luminary role is intensely people-oriented.  The role seeks to expand their reach.  This can be seen by others as overreaching and even encroaching.  Their boldness can be intimidating to those who are more introverted or cautious.  Their tenacity can look more like stubborness.",
      "It is important that this role take the time to invest in the less vocal team members.  Not everyone will respond to their enthusiasm and could find this role overbearing.",
    ]
  },
  "Investor": {
    title: "Seeks Tangible Results, Identifies Value Model",
    image: investor,
    bio: "The Investor role focuses on profitability and overall  system viability.  This role is adept and finding the right resources, people and products to achieve success.  They are not impulsive, even risk adverse but this role does not miss opportunities. Options or changes are evaluated through the mindset of a financially successful outcome.  The role is skilled at negotiation and they are not easily taken advantage of.   When they see potential in people or products, their assessments are confirmed over time.",
    questions: [
      "Is this project financially viable?",
      "What steps can be taken to improve its financial stability?",
      "Who will add value to this project?",
    ],
    special: [
      "Assesses profitability of current projects and potential projects.",
      "Committed to increasing profits and reducing costs.  Growth is measured by the bottom line.",
      "Identifies talent and resources necessary for success.",
    ],
    resistance: [
      "The role of the investor is to secure resources, especially financial.   This can result in making decisions that are seen as overly frugal or even manipulative.   Clear communication of goals and the outcomes of those goals for the benefit of others can address this.",
      "The Investor Role rightly focuses on the bottom line.  However, this can frustrate and alienate those who do not.  It is important that the Investor Role balances the success of a project with open generosity.",
    ]
  },
  "Organizer": {
    title: "Provides Structure, Streamlines Growth Mode",
    image: organizer,
    bio: "The Organizer Role defines and orchestrates structure but their real passion is on growth, efficiency and expansion.   This role helps people and resources work together in an efficient manner toward a defined goal.  This efficiency focus filters into their own work, offering them, and those they influence, excellent time management skills.",
    questions: [
      "What can be done to make the existing system better, faster or bigger?",
      "How can the existing system be made more efficient?",
      "Who can help this work be more productive and where do they best fit?",
    ],
    special: [
      "The Organizer Role effectively groups people and/or processes into a functioning whole.",
      "Sees value in delegation to maximize growth.",
      "This role is focused on efficiency, productivity and expansion.",
    ],
    resistance: [
      "The Organizer role focuses on efficiency while implementing or maximizing necessary structures.   This can result in applying too much pressure on people without moderation.  It can also be viewed as insensitive if there is not an adequate acknowledgment of individual and team contributions. ",
      "It is important that this role take the time to nurture or invest in the team members so that organizational structures and demands that are made are not viewed as rigid but responsive to a common goal.",
    ]
  },
  "Team Builder": {
    title: "People-oriented, Focused on Purpose and Meaning",
    bio: "The Team Builder Role promotes harmony.  The role performs like an effective diplomat, choosing words carefully to avoid damage or harm while promoting the cause.  They build trust because they genuinely care about the people they engage with. They create connections on the team between themselves and between others.  They are also strongly motivated to engage with purpose-driven goals.  Seeking, finding and sharing purpose contributes to team cohesion and ultimately performance. ",
    image: teambuilder,
    questions: [
      "Who are the people around me and what motivates them?",
      "How can I engage with others to inspire purpose-filled lives?",
      "What issues are creating conflict and how can they be resolved?",
    ],
    special: [
      "The Team Builder Role is motivated toward connection and harmony.",
      "They value cooperation, seeking win/win solutions in negotiation.",
      "They focus on and encourage others with the greater value or purpose of work.",
    ],
    resistance: [
      "The Team Builder role focus on the primacy of cooperative relationship can make them avoidant or inert toward conflict.  This can make others see them as ineffective.   It is important that this role practice confronting techniques.",
      "For some of the other roles, the tactful verbal style of the Team Builder can be received as disingenuous.  This can be addressed by acquiring the skill to speak plainly when necessary.",
    ]
  },
}
class MainRole extends React.Component {
  constructor() {
    super()
    this.state = {
      id: null
    }
  }
  componentDidMount() {
    // alert(this.props.quizId)
    const {quizId, role} = this.props
    this.props.entitiesGetRoleSummary(role, quizId).then((action) => {
      if (action.response)
      this.setState({id: action.response.result})
    })
  }
  render() {
    const {role, all} = this.props
    const ri = roles[role]
    if (!this.state.id) return <div>Loading...</div>
    const r = this.props.roleSummaries[this.state.id]
    if (!r) return <div>role does not exist</div>
    const mobStyle = window.innerWidth < 750 ? {textAlign: "center"} : {display: "flex"}
    return <div>
      <h4 style={{fontWeight: "bold"}}>{role} Role:</h4>
      <div style={{marginLeft: "15px"}}>{r.title}</div>
      <hr/>
      <div style={mobStyle}>
        <div>
          <div style={{width: "200px", backgroundSize: "cover", height: "200px", backgroundImage: `url(${ri.image})`}}/>
        </div>
        <div style={{flex: 1, padding: "0 15px", textAlign: "left"}}>{r.bio}</div>
      </div>
      { all &&
        <>
          <hr/>
          <h6 style={{marginTop: "15px", fontWeight: "bold"}}>Questions the {role} Role asks:</h6>
          <ul>
            {
              r.questions.map((q, i) => {
                return <li key={i}>{q}</li>
              })
            }
          </ul>
          <h6 style={{marginTop: "15px", fontWeight: "bold"}}>What makes the {role} Role special? </h6>
          <ul>
            {
              r.special.map((q, i) => {
                return <li key={i}>{q}</li>
              })
            }
          </ul>
          <h6 style={{marginTop: "15px", fontWeight: "bold"}}>Where the {role} Role runs into resistance:</h6>
          <div style={{marginLeft: "15px"}}>
            {r.resistance.map((q, i) => {
              return <p key={i}>{q}</p>
            })}
          </div>
        </>
      }
    </div>
  }
}
const mapStateToProps = (state, props) => {
  const {roleSummaries} = state.entities
  return {
    roleSummaries
  }
}

export default connect(mapStateToProps, {
  entitiesGetRoleSummary
})(MainRole)
