import prophet from "../assets/images/roles/prophet.png"
import implementor from "../assets/images/roles/implementor.png"
import conceptualizer from "../assets/images/roles/conceptualizer.png"
import luminary from "../assets/images/roles/luminary.png"
import investor from "../assets/images/roles/investor.png"
import organizer from "../assets/images/roles/organizer.png"
import teambuilder from "../assets/images/roles/teambuilder.png"
const roles = [
  {
    id: 6,
    url: "/roles/prophet",
    name: "Prophet",
    color: "#dc5e5e",
    info: "Generates the idea",
    image: prophet
  },
  {
    id: 2,
    name: "Implementor",
    url: "/roles/implementor",
    color: "#ffac59",
    info: "Executes the steps",
    image: implementor
  },
  {
    id: 7,
    name: "Conceptualizer",
    url: "/roles/conceptualizer",
    color: "black",
    info: "Clarifies the idea",
    image: conceptualizer
  },
  {
    id: 4,
    name: "Luminary",
    url: "/roles/luminary",
    color: "#59ac59",
    info: "Promotes the idea",
    image: luminary
  },
  {
    id: 5,
    name: "Investor",
    url: "/roles/investor",
    color: "#5a5ac2",
    info: "Monetizes the idea",
    image: investor
  },
  {
    id: 3,
    name: "Organizer",
    url: "/roles/organizer",
    color: "#8a59ae",
    info: "Structure for the idea",
    image: organizer
  },
  {
    id: 1,
    name: "Team Builder",
    url: "/roles/team-builder",
    color: "#f4aef4",
    info: "Purpose of the idea",
    image: teambuilder
  }
]
export const colors = {}
roles.forEach((r) => {
  colors[r.name] = r.color
})
export default roles

export const findRole = (name) => {
  return roles.find((role) => role.name.match(name) || name.match(role.name))
}
