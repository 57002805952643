import React from 'react'
import Role from "../components/Role"
import { connect } from 'react-redux'
import { entitiesGetQuizInstance } from "../actions/entities"
import {RadarChart, PolarGrid, PolarAngleAxis, Radar, Legend, PolarRadiusAxis} from "recharts"
import RoleTable from "../components/RoleTable"
import RoleChart from "../components/RoleChart"
import StrongestWeakest from "../components/StrongestWeakest"
import {
  Link
} from "react-router-dom";
import { roleUrls } from "../components/roles/Main"
import CoachingLink from "../components/CoachingLink"
import colors from "../constants/colors"
import mixpanel from '../utils/mixpanel'
class QuizInstance extends React.Component {
  constructor() {
    super()
    this.state = {data: null}
  }

  componentDidMount() {
    mixpanel.page()
    const {match: {params: {token}}} = this.props
    this.props.entitiesGetQuizInstance(token)
    window.scrollTo(0,0)
  }

  totalPoints() {
  }
  render() {
    const {quizInstance} = this.props
    if (!quizInstance || !quizInstance.summary || !quizInstance.summary.roles) return <div className="text-center">Loading...</div>

    const summary = quizInstance.summary
    const roles = summary.roles
    const orderedRoles = quizInstance.personas.map((persona) => {
      const role = roles.find((role) => role.id === persona.id)
      role.fullMark = 16
      role.orderKey = persona.orderKey
      role.persona = persona
      return role
    }) 
    return <div className="container">
        <h3 className="text-center" style={{marginTop: "30px", marginBottom: "30px"}}>My Team Role Results</h3>
        <div style={{marginTop: "15px", display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
          <div style={{marginBottom: "30px"}}>
            <RoleTable orderedRoles={orderedRoles}/>
          </div>
          <div>
            <RoleChart scoredRoles={orderedRoles}/>
        </div>
      </div>
      <StrongestWeakest quizId={quizInstance.quizId} orderedRoles={orderedRoles}/>
      <div className="text-center" style={{padding: "10px", zIndex: "999", width: "300px", border: "1px solid rgba(0,0,0,.125)", backgroundColor: "white", position: "fixed", bottom: 0, right:0}}>
        <h5>
          Want to work on your Roleset?
        </h5>
        <CoachingLink/>
      </div>
    </div>
  }
}

const mapStateToProps = (state, props) => {
  const {match: {params: {token}}} = props
  const {entities: {quizInstances}} = state
  const quizInstance = quizInstances[token]

  return {
    quizInstance,
  }
}

export default connect(mapStateToProps, {
  entitiesGetQuizInstance
})(QuizInstance)
