import React from 'react'
import { connect } from 'react-redux'
import moment from "moment"
import {
  Link
} from "react-router-dom";
import { entitiesGetQuizzes, entitiesCreateOrganization} from "../../actions/entities"

class TeamSessionNew extends React.Component {

  constructor() {
    super()
    this.state = {
      name: "",
      identifier: "email",
      quizId: null,
      quizzes: [],
      saving: false
    }
  }
  componentDidMount() {
    this.props.entitiesGetQuizzes().then((action) => {
      if (action.error) {
        return
      }
      const quizzes = action.response.result
      const quizId = quizzes.find((q) => action.response.entities.quizzes[q].category === "main")
      this.setState({
        quizzes,
        quizId
      })
    })
  }
  render() {
    const {name, identifier, saving, quizId} = this.state
    return <div>
      <button style={{margin: "15px 0"}}
        onClick={() => {
          this.setState({addNew: !this.state.addNew})
        }}
        className="btn btn-success"><i className="fas fa-plus" style={{marginRight: "5px"}}/>New Team Session</button>
      {this.state.addNew &&
        <div className="card" style={{marginBottom: "15px", width: "400px"}}>
          <div className="card-header">New Team Session</div>
          <div className="card-body">
            <input
              className="form-control"
              value={name}
              onChange={(e) => this.setState({name: e.target.value})}
              placeholder="Team Session Name *"
            />
            <div style={{display: "flex", marginTop: "15px"}}>
              <div style={{marginRight: "15px", lineHeight: "40px"}}>Quiz</div>
              <select className="form-control" value={this.state.quizId} onChange={(e) => {
                this.setState({quizId: e.target.value})
                }}>
                {
                  this.state.quizzes.map((q) => {
                    const quiz = this.props.quizzes[q]
                    return <option key={q} value={q}>{quiz.category}</option>
                  })
                }
              </select>
            </div>
            <button
              style={{width: "100%", marginTop: "15px"}}
              className="btn btn-success"
              disabled={(name == "" || saving)}
              onClick={() => {
                this.setState({saving: true})
                this.props.entitiesCreateOrganization({name, identifier, quizId, departmentId: this.props.departmentId}).then(() => {
                  this.setState({addNew: false})
                  this.props.onCreate()
                })
              }}
            >{ saving ? "Creating..." : "New Team Session"}</button>
          </div>
        </div>
      }
    </div>
  }
}

const mapStateToProps = (state, props) => {
  const {
    users,
    userOrganizations,
    organizations,
    quizzes
  } = state.entities
  const {
    email,
    currentUser
  } = state.currentUser
  if (!currentUser) {
    return {
      email
    }
  }
  const user = users[currentUser.id]
  return {
    email,
    user,
    userOrganizations,
    organizations,
    quizzes
  }
}

export default connect(mapStateToProps, {
  entitiesCreateOrganization,
  entitiesGetQuizzes
})(TeamSessionNew)
