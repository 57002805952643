import React, { Component } from 'react'
import { connect } from 'react-redux'
import Role from "../components/Role"
import roles from "../utils/roles"
import {parseQuery} from "../utils/queryParams"
import Section, {EvenSection, OddSection} from "../components/Section"
import squarepeg from "../assets/images/squarepeg.png"
import music from "../assets/images/music.jpg"
import rolesetgraph from "../assets/images/rolesetgraph.png"
import TakeQuiz from "../components/TakeQuiz"
import mixpanel from '../utils/mixpanel'

const fontSize = "20px"
class WhyRoles extends Component {
  componentDidMount() {
    mixpanel.page()
  }
  render() {
    const {name} = this.props
    const query = parseQuery()
    return <div>
      <EvenSection>
        <Section faClass="fa-seedling" header="Why the Roles?">
          <div className="row" style={{marginTop: "30px"}}>
            <div className="col-md-6" style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
              <img src={music} style={{width: "100%"}}/>
            </div>
            <div className="col-md-6" style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
              <p style={{fontSize}}>
                Roles offer the opportunity for growth.  A role is a set of behaviors which means, you can choose to use that role or not.   We offer a framework of roles that contribute to virtually any goal.   The idea is that as you understand your role set, and each of the roles, you will become more comfortable with switching roles for the given task.
              </p>
            </div>
          </div>
        </Section>
      </EvenSection>
      <OddSection>
        <Section faClass="fa-users" header={<span>Roles <i aria-hidden="true" className={`fas fa-not-equal`} style={{verticalAlign: "middle", fontSize: "20px"}}></i> Personalities</span>}>
          <div className="row" style={{marginTop: "30px"}}>
            <div className="col-md-6" style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
              <p style={{fontSize}}>
                 Roles are not personalities!  We all play different roles every day giving us the opportunity to select the right role for the job.    Roles are activity based,  they are defined by the actions we take, the behaviors we choose to use and the values that motivate them.  Of course, we might have a preference for one role  over another but the point is that we have a choice.  
              </p>
            </div>
            <div className="col-md-6" style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
              <img src={squarepeg} style={{width: "100%"}}/>
            </div>
          </div>
        </Section>
      </OddSection>
        <EvenSection>
        <Section faClass="fa-crown" header="Our Technology Offers">
          <div>
            <div className="row" style={{marginTop: "30px"}}>
              <div className="col-md-6" style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                <img src={rolesetgraph} style={{width: "100%"}}/>
              </div>
              <div className="col-md-6" style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                <p style={{fontSize}}>
                  Our technology offers a method to identify and rank the seven roles; this is your unique roleset.   The Seven Roles are  statistically validated and collectively contribute to successful outcomes across a wide range of differing goals and with thousands of participants.   We provide training, consultation, classes, coaching and more to help make your weaker roles stronger, and your stronger roles...even stronger.  
                </p>
              </div>
            </div>
            <div className="text-center" style={{marginTop: "15px"}}>
              <TakeQuiz/>
            </div>
          </div>
        </Section>
      </EvenSection>
    </div>
  }
}

function mapStateToProps(state, props) {
  const {match: {params: {name}}} = props
  return {
    name
  }
}
export default connect(mapStateToProps, {
})(WhyRoles)
