import React from 'react'
import { connect } from 'react-redux'
import {
  Link
} from "react-router-dom";
import { entitiesAdminUpdateRoleSummary } from "../../actions/entities"

class RoleSummary extends React.Component {
  constructor(props) {
    super()

    const newState = this.resetState(props)
    newState.saving = false
    this.state = newState
  }
  componentDidMount() {
  }
  extractKeys(rs) {
    const {title, special, resistance, questions, bio} = rs
    return {title, special, resistance, questions, bio}
  }

  hasChanged() {
    const d1 = JSON.stringify(this.extractKeys(this.state))
    const d2 = JSON.stringify(this.extractKeys(this.props.roleSummary))
    return d1 !== d2
  }
  resetState(props) {
    return JSON.parse(JSON.stringify(this.extractKeys(props.roleSummary)))
  }
  renderList(field) {
    const list = this.state[field]
    if (!list) return <div style={{color: 'red'}}>error loading {field}</div>
    return <div>
      {field}: <ul>
        {list.map((item, i) => {
          return <li key={i}>
            <textarea style={{width: "100%"}} value={item} onChange={(e) => {
              const value = e.target.value
              const newList = JSON.parse(JSON.stringify(list))
              newList[i] = value
              const newState = {}
              newState[field] = newList
              this.setState(newState)
            }}></textarea>
            <button className="btn btn-link" onClick={() => {
              const newList = JSON.parse(JSON.stringify(list))
              newList.splice(i, 1)
              const newState = {}
              newState[field] = newList
              this.setState(newState)
            }}>Remove</button>
            </li>
        })}
      </ul>
      <button className="btn btn-link" onClick={() => {
        const newList = JSON.parse(JSON.stringify(list))
        newList.push("")
        const newState = {}
        newState[field] = newList
        this.setState(newState)
      }}>Add {field}</button>
    </div>
  }
  renderText(field) {
    return <div>
      {field}:
      <textarea style={{width: "100%"}} value={this.state[field]} onChange={(e) => {
        const value = e.target.value
        const newState = {}
        newState[field] = value
        this.setState(newState)
      }}></textarea>
    </div>
  }
  render() {
    const {roleSummary} = this.props
    if (!roleSummary) return <div>Loading...</div>
    const hasChanged = this.hasChanged()

    return <div className="card" style={{marginBottom: "5px"}}>
      <div className="card-header">
        <h4>{roleSummary.persona.name}</h4>
      </div>
      <div className="card-body">
        {this.renderText('title')}
        {this.renderText('bio')}
        {this.renderList('special')}
        {this.renderList('resistance')}
        {this.renderList('questions')}
        <div>
          <hr/>
          <button className="btn btn-link" onClick={() => {
            this.setState(this.resetState(this.props))
          }} disabled={!hasChanged}>Cancel</button>
          <button className="btn btn-primary" disabled={!hasChanged} onClick={() => {
            if (this.state.saving) return
            this.setState({saving: true}, () => {
              this.props.entitiesAdminUpdateRoleSummary(roleSummary.id, this.extractKeys(this.state)).then(() => {
                this.setState({saving: false})
              })
            })
          }}>Save</button>
        </div>
      </div>
    </div>
  }
}

const mapStateToProps = (state, props) => {
  const {rsId} = props
  const {roleSummaries} = state.entities
  return {roleSummary: roleSummaries[rsId]}
}

export default connect(mapStateToProps, {
  entitiesAdminUpdateRoleSummary
})(RoleSummary)
