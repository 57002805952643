import React from 'react'
import { connect } from 'react-redux'
import {
  Link
} from "react-router-dom";
import {currentUserLogin, userForgotPassord} from "../actions/currentUser"
import validEmail from "../utils/validEmail"
import mixpanel from '../utils/mixpanel'


class Login extends React.Component {
  constructor() {
    super()
    this.state = {
      email: "",
      password: "",
      saving: false,
    }
  }
  componentDidMount() {
    mixpanel.page()
  }
  login() {
    const {email, password} = this.state
    this.setState({
      saving: true
    }, () => {
      this.props.currentUserLogin({
        email,
        password
      }).then((action) => {
        if (action.type === "LOGIN_USER_FAILURE") {
          this.setState({saving: false})
        }
      }).catch((e) => {
        this.setState({saving: false})
        console.error(e)
      })
    })
  }

  valid() {
    const {email} = this.state
    if (email.split("@").length !== 2) return false
    if (this.invalidPassword()) return false
    if (this.state.saving) return false
    return true

  }
  invalidPassword() {
    const {password} = this.state
    if (password.length <= 6) return true
    return false
  }

  forgotPassword() {
    if (!this.state.saving) {
      if (!validEmail(this.state.email)) {
        alert("You must enter a valid email")
        return
      }

      this.setState({saving: true}, () => {
        this.props.userForgotPassord(this.state.email).then((action) => {
          this.setState({forgotPassword: true})
        }).catch(() => {
          this.setState({saving: false})
        })
      })
    }
  }
  render() {
    const {password, forgotPassword} = this.state
    return <div>
      { forgotPassword && <div className="container text-center" style={{marginTop: "15px", fontSize: "20px"}}>
        We sent an email to {this.state.email}. Please follow the instructions provided to reset your password.
      </div>
      }
      <div style={{width: "400px", backgroundColor: "white", border: "1px solid rgba(1,1,1,.1)", padding: "15px", margin: "100px auto"}}>
        <h4>Login</h4>
        <input
          onChange={(e) => this.setState({email: e.target.value})}
          style={{marginTop: "15px"}}
          className="form-control" type="text"
          name="email"
          placeholder="Email"
        />
        <input
          onChange={(e) => this.setState({password: e.target.value})}
          value={this.state.password}
          style={{marginTop: "15px"}}
          className="form-control"
          type="password"
          name="password"
          placeholder="Password"/>
        { password.length > 0 && password.length <= 6 && <div style={{color: "#dc3545"}}>Password must be > 6 characters</div>}
        <div className="text-right">
          <button style={{marginTop: "5px"}} onClick={() => {
            this.forgotPassword()
          }} className="btn btn-link">Forgot Password</button>
        </div>
        <button
          disabled={!this.valid()}
          className="btn btn-primary"
          style={{width: "100%", marginTop: "5px"}}
          onClick={() => {
            this.login()
          }}
        >{ this.state.saving ? "Processing..." : "Login"}</button>
        <div className="text-center" style={{paddingTop: "5px", borderTop: "1px solid rgba(1,1,1,.1)", marginTop: "15px"}}>
          <Link to="/signup">I need to signup</Link>
        </div>
      </div>
    </div>
  }
}

const mapStateToProps = (state, props) => {
  return {
  }
}

export default connect(mapStateToProps, {
  userForgotPassord,
  currentUserLogin
})(Login)
