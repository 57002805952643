import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import {
  Link
} from "react-router-dom";
import {entitiesGetCompany, entitiesCreateDepartment} from "../../actions/entities"
import BreadCrumbs from "../../components/BreadCrumbs"

class Company extends Component {
  constructor() {
    super()
    this.state = {
      name: "",
    }
  }
  componentDidMount() {
    this.fetchCompany()
  }
  fetchCompany() {
    this.props.entitiesGetCompany(this.props.companyId).then((action) => {
    })
  }
  create() {
    this.setState({saving: true})
    this.props.entitiesCreateDepartment({name: this.state.name, companyId: this.props.companyId}).then(() => {
      this.setState({saving: false, name: ""})
      this.fetchCompany()
    })
  }
  render() {
    const {companyId, company} = this.props
    const {saving} = this.state
    if (!company) return <div>Loading...</div>
    const crumbs = [
      {name: "Company - " + company.name},
    ]
    return <div>
      <BreadCrumbs crumbs={crumbs}/>
      <div>
        <button style={{margin: "15px 0"}}
          onClick={() => {
            this.setState({addNew: !this.state.addNew})
          }}
          className="btn btn-success"><i className="fas fa-plus" style={{marginRight: "5px"}}/>New Department</button>
        {
          this.state.addNew &&
            <div>
              <div className="card" style={{marginBottom: "15px", width: "400px"}}>
                <div className="card-header">New Department</div>
                <div className="card-body">
                  <input
                    className="form-control"
                    value={this.state.name}
                    onChange={(e) => this.setState({name: e.target.value})}
                    placeholder="Department Name*"
                  />
                  <button
                    style={{width: "100%", marginTop: "15px"}}
                    className="btn btn-success"
                    disabled={(this.state.name == "" || saving)}
                    onClick={() => {
                      this.create()
                    }}
                  >{ saving ? "Creating..." : "New Department"}</button>
                </div>
              </div>
            </div>
        }
    </div>

      <div style={{padding: "5px"}}>
        <table className="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Departments</th>
            </tr>
          </thead>
          <tbody>
          {company.departments.map((did) => {
            const department = this.props.departments[did]
              return <tr key={did}>
                <td>
                  <Link to={department.accountSlug}>{department.name}</Link>
                </td>
                <td>
                  {1 }
                </td>
              </tr>
            })}
          </tbody>
        </table>
      </div>
  </div>
  }
}

function mapStateToProps(state, props) {
  const {match: {params: {idString}}} = props
  const {companies, departments} = state.entities
  const id = idString.split("-")[0]
  return {
    companyId: id,
    company: companies[id],
    departments
  }
}

export default connect(mapStateToProps, {
  entitiesGetCompany,
  entitiesCreateDepartment,
  entitiesCreateDepartment
})(Company)
