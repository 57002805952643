import React from 'react';
import { connect } from 'react-redux'
import ReactDOM from 'react-dom';
// import {loadStripe} from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import {CardElement, Elements, ElementsConsumer} from '@stripe/react-stripe-js';
import {entitiesCreateQuizPurchase} from "../actions/entities"
require("../stylesheets/payment.css")
let key = "pk_live_giMaO7mnCEzfl2bU3jRH6oV000D14guxiY"


if (window.location.host.match("localhost")) {
  key = 'pk_test_c58Ic8E9QzwBPUKOThH0RjzR00Gjx4eEyZ'
  console.log("dev")
}

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
    },
  },
}
function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

loadStripe.setLoadParameters({advancedFraudSignals: false})
let stripePromise = loadStripe(key);
      
class CheckoutForm extends React.Component {
  constructor() {
    super()
    this.state = {
      cardValid: false,
      name: "",
      email: "",
      voucher: "",
      paymentType: "card",
      error: null
    }
  }
  handleSubmit = async (event) => {
    event.preventDefault();
    if (!this.props.paying) {
      const {stripe, elements} = this.props;
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make  sure to disable form submission until Stripe.js has loaded.
        return;
      }

      const card = elements.getElement(CardElement);
      const result = await stripe.createToken(card);

      if (result.error) {
        // Show error to your customer.
        // console.log(result.error.message);
        this.setState({
          error: result.error.message
        })

      } else {
        // Send the token to your server.
        // This function does not exist yet; we will define it in the next step.
        // stripeTokenHandler(result.token);
        // const {email, name} = this.state
        const {name, email} = this.state
        const data = {stripeToken: result.token.id, name, email}
        this.props.onSave(data)
        // console.log(result.token)
      }
    }
  };

  validPayment() {
    const {paying} = this.props
    const {cardValid, name, email} = this.state
    if (!cardValid || !name || paying || name.length < 2 || !validateEmail(email)) return false
    return true
  }
  validVoucher() {
    const {paying} = this.props
    const voucher = this.state.voucher
    if (!voucher || voucher.length < 7) return false
    return true
  }
  renderVoucher() {
    const {paying} = this.props
    return <div>
      <label>Enter a Voucher Code</label>
      <input className="form-control" placeholder="Voucher Code" onChange={(e) => {
        this.setState({voucher: e.target.value})}}/>
      <button className="btn btn-primary"
        onClick={() => {
          window.location = `/quiz?o=${this.state.voucher}`
        }}
        disabled={!this.validVoucher()} style={{width: "100%", marginTop: "15px"}}>{"Use Code" }</button>
    </div>
  }
  renderCreditCard() {
    const {stripe, name, amount, paying} = this.props
    return <div>
      <div style={{padding: "10px", border: "1px solid rgba(1,1,1,.15)", borderRadius: "3px"}}>
        <CardElement options={CARD_OPTIONS} onChange={(e) => {
          this.setState({cardValid: e.complete})
        }}/>
      </div>
      <button className="btn btn-success"
        disabled={paying || !this.validPayment()} style={{width: "100%", marginTop: "15px"}}>{paying ? "Processing..." : ("Pay $99" )}</button>
    </div>
  }
  render() {
    const {stripe, name, amount, paying} = this.props
    return <div className="card" style={{width: "400px", margin: "50px auto"}}>
      <div className="card-body">
        <h4 style={{marginBottom: "15px"}}>Take the Quiz</h4>
        <form autoComplete="none" onSubmit={this.handleSubmit}>
          <div>
            <input className="form-control" placeholder="Name" onChange={(e) => {this.setState({name: e.target.value})}}/>
          </div>
          <div style={{margin: "15px 0"}}>
            <input className="form-control" placeholder="Email" onChange={(e) => {this.setState({email: e.target.value})}}/>
          </div>
          {this.state.error && <div style={{marginTop: "15px", color: "red"}}>{this.state.error}</div>}
          
          { this.renderCreditCard()}
          <div className="text-center text-muted" style={{margin: "15px 0"}}>OR</div>
          { this.renderVoucher()}
        </form>
      </div>
    </div>
  }
}

const InjectedCheckoutForm = ({onSave, paying, name, amount}) => (
  <ElementsConsumer>
    {({stripe, elements}) => (
      <CheckoutForm paying={paying} onSave={onSave} name={name} amount={amount} stripe={stripe} elements={elements} />
    )}
  </ElementsConsumer>
);


class Payment extends React.Component {
  constructor() {
    super()
    this.state = {
      paying: false
    }
    this.onCreate = this.onCreate.bind(this)
  }
  onCreate(data) {
    this.setState({paying: true}, async () => {
      this.props.entitiesCreateQuizPurchase(data).then((action) => {
        this.setState({paying: false}, () => {
          if (action.type === "ENTITIES_UPDATE") {
            const {entities: {quizPurchases}, result} = action.response
            const qp = quizPurchases[result]
            window.location = qp.slug
          } else {
            // alert("Error chargin")
          }
        })

      })
    })
  }
  render() {
    return <Elements stripe={stripePromise}>
      <InjectedCheckoutForm
        paying={this.state.paying}
        name={this.props.name}
        amount={9900}
        onSave={this.onCreate}/>
    </Elements>
  }
}

const mapStateToProps = (state, props) => {
  return {
  }
}

export default connect(mapStateToProps, {
  entitiesCreateQuizPurchase
})(Payment)

