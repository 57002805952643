import React, { Component } from 'react'
import { connect } from 'react-redux'
import Section from "../components/Section"
import SingleBlog from "../components/blogs/Components/SingleBlog/SingleBlog"
import mixpanel from '../utils/mixpanel'

class Article extends Component {
  componentDidMount() {
    mixpanel.page()
  }
  render() {
    return <div className="container">
      <SingleBlog title={this.props.articleTitle} />
    </div>
  }
}

function mapStateToProps(state, props) {
  return {
    articleTitle: props.match.params.articleTitle
  }
}
export default connect(mapStateToProps, {
})(Article)
