import { CALL_API } from '../middleware/api'
import {Schemas} from "../middleware/schemas";


export const accountSearchUser = (term) => {
  return (dispatch, getState) => {
    return dispatch(
      {
        ['CALL_API']: {
          types: [ "GET_REQUEST", "ACCOUNT_USER_SEARCH_SUCCESS", "FAILURE" ],
          endpoint: `accounts/users_search?q=${term}`,
          ext_req: {method: "get"},
          schemaless: true,
        }
      }
    )
  }
};
