import React from 'react'
import { connect } from 'react-redux'
import {
  Link
} from "react-router-dom";
class Analysis extends React.Component {
  constructor() {
    super()
    this.state = {
      email: "",
      name: "",
      saving: false
    }
  }
  render() {
    const {
      organization,
      quizInstances
    } = this.props

    return <div>
      <h4>Analysis</h4>
      {quizInstances.length === 0 &&
        <div>No data to analyize</div>
      }
      <ol style={{marginTop: "15px"}}>
        { quizInstances.map((quizInstance) => {
          const {roles} = quizInstance.summary
          const r = roles.sort((a, b) => {
            if (a.percent > b.percent) return -1
            if (a.percent < b.percent) return 1
            return 0
          })
          
          return <li key={quizInstance.id}>
            <div>{ quizInstance.name }</div>
            <div>{ quizInstance.email }</div>
            <div>Strongest: { r[0].name }</div>
            <div>Weakest: { r[6].name }</div>
          </li>
        }) }
      </ol>
    </div>
  }
}

const mapStateToProps = (state, props) => {
  const {match: { params: { organizationId } } } = props
  const {
    organizations,
    quizInstances
  } = state.entities
  const organization = organizations[organizationId]

  return {
    organization,
    quizInstances: organization.quizInstances.map((qid) => quizInstances[qid]),
  }
}

export default connect(mapStateToProps, {
})(Analysis)
