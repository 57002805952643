import React from 'react'
import { connect } from 'react-redux'
import {
  Switch,
  Route,
} from "react-router-dom";
import colors from "../../constants/colors"
import LeftNav from "../../components/LeftNav"
import logo from "../../assets/images/small-logo.jpg"
import QuizInstances from "./QuizInstances"
import AdminOrganizations from "./Organizations"
import SummaryGroups from "./SummaryGroups"
import SummaryGroup from "./SummaryGroup"
import Admin from "./Admin"
import AdminQuiz from "./Quiz"

class Index extends React.Component {

  constructor() {
    super()
    this.state = {
      loaded: false,
      height: this.height()
    }
    this.resize = this.resize.bind(this)
  }
  componentDidMount() {
    this.resizeListener = window.addEventListener("resize", this.resize)
    // this.props.accountsFetchUsers().then(() => {
      this.setState({loaded: true})
    // })
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize)
  }
  resize() {
    this.setState({height: this.height()})
  }
  height() {
    return window.innerHeight - 80
  }
  render() {
    const {user, account} = this.props
    const navItems = [
      {
        name: "Main",
        icon: "fa-cog",
        color: colors.admin,
        exactUrl: "/admin",
        url: "/admin"
      },
      {
        name: "Summary Group",
        icon: "fa-object-group",
        color: colors.admin,
        url: "/admin/summary_groups"
      },
      {
        name: "Account",
        icon: "fa-users-cog",
        color: colors.account,
        url: "/account"
      },
      {
        name: "My Results",
        icon: "fa-list",
        url: "/u"
      }
    ]
    return <LeftNav navItems={navItems}>
      <div style={{width: "100%"}}>
        <div className="container">
          <div style={{borderBottom: "1px solid rgba(1,1,1,.1)", padding: "20px 0 10px", marginBottom: "15px"}}>
            <h4 style={{marginLeft: "15px"}}>Admin Panel</h4>
          </div>
        </div>
        <div className="container">
          <Switch>
            <Route exact path="/admin/organizations" component={AdminOrganizations} />
            <Route exact path="/admin/quizzes/:id" component={AdminQuiz} />
            <Route exact path="/admin/quiz_instances" component={QuizInstances} />
            <Route exact path="/admin/summary_groups" component={SummaryGroups} />
            <Route exact path="/admin/summary_groups/:id" component={SummaryGroup} />
            <Route exact path="/admin" component={Admin} />
          </Switch>
        </div>
      </div>
    </LeftNav>
  }
}

const mapStateToProps = (state, props) => {
  const {currentUser: { currentUser }, entities: {accounts}} = state
  let account = null
  if (currentUser && currentUser.account) {
    account = accounts[currentUser.account]
  }
  return {
    user: currentUser,
    account
  }
}

export default connect(mapStateToProps, {
})(Index)
