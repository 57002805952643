import React from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string';
import Question from "../components/Question"
import Payment from "../components/Payment"
import { entitiesGetMainQuiz, entitiesGetOrganization, entitiesSubmitQuiz } from "../actions/entities"
import mixpanel from '../utils/mixpanel'
import { userAnswersAnswer } from "../actions/userAnswers"
import colors from '../constants/colors'

class Home extends React.Component {
  constructor() {
    super()
    this.state = {
      data: null,
      email: null,
      // gender: null,
      // age: null,
      name: null,
      error: null,
      prompt: false,
      ip_address: null,
      saving: false,
      error: null
    }
  }
  componentDidMount() {
    mixpanel.page()
    const {organizationId, quiz_token} = this.props
    if (quiz_token || organizationId) {
      this.props.entitiesGetMainQuiz(quiz_token, organizationId).then((action) => {
        if (action.type === "GET_FAILURE") {
          this.setState({error: action.error})
        } else {
          if (organizationId) {
            this.props.entitiesGetOrganization(organizationId)
          }
        }
      })
    }
    // fetch("https://www.cloudflare.com/cdn-cgi/trace").then((data) => {
    //   data.text().then((txt) => {
    //     this.setState({ip_address: txt})
    //   })
    // })
  }

  completed() {
    const {userAnswers } = this.props
    const {email, name, gender, age} = this.state
    let count = Object.keys(userAnswers.answers).length
    if (email) {
      count += 1
    }
    if (name) {
      count += 1
    }
    if (age) {
      count += 1
    }
    if (gender) {
      count += 1
    }
    return count
  }

  totalQuestions() {
    const {questions, organization} = this.props
    let total = questions.length
    if (!organization) {
      // total += 
    } else if (organization.identifier === "name") {
      total += 1
    } else {
      total += 2
    }
    return total
  }

  isComplete() {
    const {userAnswers} = this.props
    return this.completed() === this.totalQuestions()
  }

  unanswered() {
    const {userAnswers} = this.props
    return this.totalQuestions() - this.completed()
  }

  onSubmit() {
    const {saving} = this.state
    if (saving) return
    this.setState({saving: true}, () => {
      this.submit()
    })

  }
  submit() {
    const {email, name, ip_address, saving} = this.state
    const {quiz_token} = this.props
    if (!this.isComplete()) {
      mixpanel.track("Submit Quiz Attempt", {
        email,
        name,
        isComplete: this.isComplete(),
        answers: this.props.userAnswers.answers
      })
      this.setState({prompt: true, saving: false})
      return
    }

    mixpanel.track("Submit Quiz", {
      email,
      name,
      answers: this.props.userAnswers.answers
    })

    if (email) {
      mixpanel.identify(email)
    } else {
      mixpanel.track("Submit Quiz Email Not Provided", {
        quiz_token,
        email,
        name,
        answers: this.props.userAnswers.answers
      })
    }

    this.props.entitiesSubmitQuiz({
      answers: this.props.userAnswers.answers,
      quiz_id: this.props.quiz.id,
      quiz_token,
      user: {
        email,
        name,
        ip_address
      }
    }).then((action) => {
      if (action.type === "GET_FAILURE") {
        mixpanel.track("Error Submitting", {error: action.error})
        this.setState({error: action.error, saving: false})
        setTimeout(() => {
          this.setState({error: null})
        }, 10000)
        return
      }
      const {response: {result, entities}} = action
      const qi = entities.quizInstances[result]
      this.props.history.push(`/results/${qi.token}`)
    })
  }
  renderNamePrompt() {
    const {name} = this.state
    const {quiz_token} = this.props
    const question = {question: "What is your name?"}
    const answered = name !== null
    if (quiz_token) return null

    return <Question answered={answered} prompt={this.state.prompt} question={question}>
      <div style={{width: "400px"}}>
        <input className="form-control" value={this.state.name || ""} style={{marginTop: "15px"}} onChange={(e) => {
          this.setState({name: e.target.value})

        }} placeholder="Name"/>
      </div>
    </Question>
  }
  renderEmailPrompt() {
    const {email} = this.state
    const {quiz_token} = this.props
    const question = {question: "What is your email?"}
    const answered = email !== null
    if (quiz_token) return null

    return <Question answered={answered} prompt={this.state.prompt} question={question}>
      <div className="text-muted text-left">We will send a copy of the results</div>
      <div style={{width: "400px"}}>
        <input className="form-control" value={this.state.email || ""} style={{marginTop: "15px"}} onChange={(e) => {
          this.setState({email: e.target.value})

        }} placeholder="Email"/>
      </div>
    </Question>
  }
  // renderAgePrompt() {
  //   const {age} = this.state
  //   const question = {question: "How old are you?"}
  //   const answered = age !== null

  //   return <Question answered={answered}  prompt={this.state.prompt} question={question}>
  //     <div style={{width: "400px"}}>
  //       <input className="form-control" value={this.state.age} type="number" style={{marginTop: "15px"}} onChange={(e) => {
  //         this.setState({age: e.target.value})
  //       }} placeholder="age"/>
  //     </div>
  //   </Question>
  // }
  // renderGenderPrompt() {
  //   const {gender} = this.state
  //   const question = {question: "Are you female or male?"}
  //   const answered = gender !== null

  //   return <Question answered={answered} prompt={this.state.prompt} question={question}>
  //     <div style={{display: "flex", width: "400px", paddingTop: "15px"}}>
  //       <div style={{flex: 1}}>
  //         <span style={{marginRight: "15px"}}>Female</span>
  //         <input type="radio" checked={gender === "female"} onChange={() => {
  //           this.setState({gender: "female"})
  //         }}/>
  //       </div>
  //       <div style={{flex: 1}}>
  //         <span style={{marginRight: "15px"}}>Male</span>
  //         <input type="radio" checked={gender === "male"} onChange={() => {
  //           this.setState({gender: "male"})
  //         }}/>
  //       </div>
  //     </div>
  //   </Question>
  // }
  renderPayment() {
    return <Payment/>
  }
  render() {
    const {userAnswers, quiz_token, organizationId, organization, questions, possibleAnswers, quiz} = this.props
    if (this.state.error) return <div style={{marginTop: "15px"}} className="text-center">
      <div style={{color: "red"}}>{this.state.error}</div>
      <a href="/quiz">Purchase a new quiz</a>
    </div>
    if (!quiz_token && !organizationId) {
      return this.renderPayment()
    }
    if (!quiz || (organizationId && !organization)) return <div className="text-center">Loading</div>
      if (organization && organization.expired) return <div className="text-center" style={{marginTop: "15px"}}>This link has expired</div>
    return <div>
      { this.state.error &&
        <div class="alert alert-danger" style={{position: "fixed", top: "55px", left: 0, zIndex: 999, width: "100%"}} role="alert">
          {this.state.error}
        </div>
      }
      <div  className="container" >
        <h2 style={{marginTop: "15px", margin: "40px 0"}} className="text-center">{ quiz.name }</h2>
        {organization && <h4 style={{marginTop: "15px", margin: "40px 0"}} className="text-center">{ organization.name }</h4> }

        <div className="text-center" style={{fontSize: "12px", marginBottom: "120px"}}>
          <div>
            { questions.map((question, i) => {
              const userAnswer = userAnswers.answers[question.id]
              return <Question question={question} prompt={this.state.prompt} answered={userAnswer !== undefined} key={question.id}>
                <div>
                    <hr/>
                    <div style={{display: "flex", flexDirection: "row", padding: "0 15px 15px"}}>
                      <div className="text-left" style={{flex: 1}}>Not like me at all</div>
                      <div className="text-right" style={{flex: 1}}>Very much like me</div>
                    </div>
                    <div style={{display: "flex", flexDirection: "row", padding: "0 15px"}}>
                      { possibleAnswers.map((pa, ii) => {
                        const matches = userAnswer === pa.value
                        return <div key={ii} className="text-center" style={{flex: 1, cursor: "pointer"}} onClick={() => {
                            mixpanel.track("Question Answered", {
                              number: i,
                              question_id: question.id,
                              answer: pa.value,
                              question: question.question
                            })
                            this.props.userAnswersAnswer(question.id, pa.value, pa.id)
                          }} >
                          <div style={{marginBottom: "15px"}}>{ii}</div>
                          <div>
                            <input type="radio" onChange={(e) => {
                              mixpanel.track("Question Answered", {
                                number: i,
                                question_id: question.id,
                                answer: pa.value,
                                question: question.question
                              })
                              this.props.userAnswersAnswer(question.id, pa.value, pa.id)

                            }} checked={matches} />
                          </div>
                        </div>
                        })
                      }
                  </div>
                </div>
              </Question>
              })
            }
            { this.renderNamePrompt() }
            { (!organization || (organization && organization.identifier !== "name")) && this.renderEmailPrompt() }
          </div>
        </div>
        <div className="text-center" style={{padding: "10px", zIndex: "999", width: "300px", border: "1px solid rgba(0,0,0,.125)", backgroundColor: "white", position: "fixed", bottom: "30px", right:0}}>
          <div>{this.completed()} / {this.totalQuestions()} Answered</div>
          { this.state.prompt && !this.isComplete() &&
            <div style={{color: colors.main.danger}} className="text-center">{this.unanswered()} unanswered questions</div>
          }
          <button className="btn btn-primary" onClick={() => this.onSubmit()}
            style={{width: "100%", borderRadius: "20px",marginTop: "15px", backgroundColor: colors.secondary, border: "none"}}>
            { !this.state.saving ? "Show me my role strengths" : "Calculating..."}
          </button>
        </div>
      </div>
    </div>
  }
}
const mapStateToProps = (state, props) => {
  const {userAnswers, entities: {
    mainQuiz,
    questions,
    quizzes,
    possibleAnswers,
    organizations
  }} = state
  const parsed = queryString.parse(window.location.search);
  let organizationId = parsed.o
  if (!mainQuiz) return {
    questions: [], organizationId, userAnswers,
    organizationId,
    quiz_token: parsed.quiz_token,
  }
  const quiz = quizzes[mainQuiz]
  return {
    userAnswers,
    mainQuiz,
    organization: organizations[organizationId],
    organizationId,
    quiz_token: parsed.quiz_token,
    quiz,
    possibleAnswers: quiz.possibleAnswers.map((paid) => possibleAnswers[paid]),
    questions: quiz.questions.map((qid) => questions[qid])
  }
}
export default connect(mapStateToProps, {
  entitiesGetOrganization,
  userAnswersAnswer,
  entitiesGetMainQuiz,
  entitiesSubmitQuiz
})(Home)
