import React from 'react'
import { connect } from 'react-redux'
import { entitiesCreateQuiz} from "../../actions/entities"

class NewQuiz extends React.Component {
  constructor(props) {
    super()
    this.state = this.defaultState(props)
  }
  defaultState(props) {
    return {
      name: "",
      category: "",
    }
    this._saving = false
  }

  createQuiz() {
    if (this._saving) {
      return
    }
    this._saving = true
    this.props.entitiesCreateQuiz(this.state).then((action) => {
      window.location.reload()
    })
  }
  toType(value) {
    return value.replace(/[\W]+/g," ").replace(" ", "_")
  }
  render() {
    return <div >
      New Quiz 
      <div style={{width: "400px"}}>
        <input style={{marginTop: "5px"}} value={this.state.name} onChange={(e) => {
          this.setState({name: e.target.value})
        }} className="form-control" placeholder="Name"/>
        <input onChange={(e) => {
          this.setState({category: this.toType(e.target.value)})
        }} value={this.state.category} style={{marginTop: "5px"}} className="form-control" placeholder="Type: (main, personal)"/>
        <button style={{width: "100%", marginTop: "5px"}} onClick={() => {
            this.createQuiz()
        }} className="btn btn-success">{this._saving ? "Creating...": "Create Quiz"}</button>
      </div>
    </div>
  }
}

const mapStateToProps = (state, props) => {
  return {
  }
}

export default connect(mapStateToProps, {
  entitiesCreateQuiz,
})(NewQuiz)
