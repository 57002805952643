import React from 'react'
import {
  Link
} from "react-router-dom";
import roles from "../utils/roles"
import Main from "./roles/Main"

require("../stylesheets/switch.css")

const Linker = ({url, children}) => {
  if (url) return <Link style={{color: "#212529"}} to={url}>{children}</Link>
  return <div>{children}</div>
}
export default ({role, quizId, addLink, detail, persona, all, roleName}) => {
  const rn = persona ? persona.name : roleName
  const r = rn ? roles.find((r) => r.name == rn) : role
  const mobStyle = window.innerWidth < 750 ?  {} : {display: "flex"}
  return <div key={r.name} >
    <div style={mobStyle}>
      <div style={{textAlign: "center"}}>
        <img style={{width: "50px"}} src={r.image}/>
      </div>
      <div style={{flex: 1, marginLeft: "15px", marginTop: "5px"}}>
        { !detail && <Linker url={addLink ? r.url : null}>
            <h4>{r.name}</h4>
            <div>{r.info}</div>
          </Linker>
        }
        { detail && <Main quizId={quizId} role={r.name} all={all} />} 
      </div>
    </div>
  </div>
}
