import React from 'react'
import { connect } from 'react-redux'
import {
  Link
} from "react-router-dom";
import Question from "../components/Question"
import TeamCoachingLink from "../components/TeamCoachingLink"
import Role from "../components/Role"
import mixpanel from '../utils/mixpanel'
import colors from '../constants/colors'
import roles from "../utils/roles"
import stage from "../assets/images/Stage-1024x827.jpg"
import boat from "../assets/images/boat.jpg"
import esc from "../assets/images/es-c.jpg"
import logo from "../assets/logo.png"
import google from "../assets/images/google.png"
import slack from "../assets/images/slack.png"
import adobe from "../assets/images/adobe.png"
import Section, {EvenSection, OddSection} from "../components/Section"
import TakeQuiz from "../components/TakeQuiz"
const secondText = "Knowing who you are and acting in concert with that is important. However, many aspects of our work lives and personal lives require that we adjust our behaviors or the roles necessary in that scenario, to match the situation."

class Home extends React.Component {
  constructor() {
    super()
    this.state = {
      loaded: false,
      height: this.height(),
      width: this.width()
    }

    this.roleRef = React.createRef()
    this.resize = this.resize.bind(this)
  }
  componentDidMount() {
    mixpanel.page()
    this.resizeListener = window.addEventListener("resize", this.resize)
    this.setState({loaded: true})
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize)
  }
  resize() {
    this.setState({height: this.height(), width: this.width()})
  }
  width() {
    return window.innerWidth
  }
  height() {
    return window.innerHeight - 80
  }
  renderBlurb1() {
    return <div className="text-center" style={{fontSize: "1.5rem", marginBottom: "3rem"}}>
      <div>

        We all play different roles to accomplish different tasks.
      </div>
    </div>
  }
  render() {
    const isMobile = this.state.width <= 991
    const margin = !isMobile ? {marginLeft: "70px"} : {}
    return <div>
      <div style={{padding: "60px 0"}}>
        <div  className="container">
          <div>
            <div className="row">
              <div style={{margin: "40px auto 100px"}}>
                <h2 className="text-center hero-text" style={{fontWeight: "bold"}}>What Roles do you play?</h2>
                <div style={{margin: "30px 0"}} className="text-center">
                  <TakeQuiz />
                  <Link to="/the-roles/what-are-the-roles">
                    <button className="btn" style={{verticalAlign: "middle", backgroundColor: "transparent", border: "none", fontWeight: "bold", width: "220px", padding: "7px"}} >
                      EXPLORE THE ROLES
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            {this.renderBlurb1()}
          </div>
        </div>
      </div>
      <OddSection>
        <div className="row" >
          <div className="col-md-6" style={{marginTop: "40px"}}>
            <Section faClass="fa-cog" header="Why Look at Roles?">
              <div style={{lineHeight: "30px", marginTop: "15px"}}>
                <p>
                  Knowing who you are and acting in concert with that is important. However, many aspects of our work and personal lives require that we adjust our behaviors and the roles we play to best propel the team forward.
                </p>
                <p>
                  The problem is that we all tend to rely heavily on our strengths, assuming that if we do more of what we do best, we can fix whatever is not working well.  But sometimes what we need is a new perspective, and a new set of behavioral skills.
                </p>
                <p>
                  Learning your role set will help you approach your world with the right behavior.
                </p>
              </div>
            </Section>
          </div>
          <div className="col-md-6" style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
            <img src={boat} style={{width: "100%"}}/>
          </div>
        </div>
      </OddSection>
      <EvenSection>
        <div className="row">
            <Section faClass="fa-people-carry" header="The Roles">
                <div style={{lineHeight: "30px", marginTop: "15px"}}>
                  Research has validated seven roles that positively contribute to almost every endeavor.  More importantly, the right role depends on the stage your project or idea is in.   This makes understanding ALL seven roles not only necessary, but critical.
                </div>
            </Section>
          <div className="col-md-12" style={isMobile ? {display: "flex", justifyContent: "center", marginTop: "15px", textAlign: "center"} : {}}>
            <div className={`roles-grid`} style={{marginTop: "30px"}}>
              {roles.map((role, i) => {
                return <Role key={i} addLink role={role}/>
              })}
            </div>
          </div>
        </div>
      </EvenSection>
      <OddSection>
        <div className="row">
          <div className="col-md-6" style={{}}>
            <Section faClass="fa-thumbs-up" header="How to Get Started">
              <div style={{lineHeight: "30px", marginTop: "15px"}}>
                <p>
                  What are my role strengths?  What can I do about role weaknesses?  How do the roles work together?  
                </p>
                <p>
                  First, take the quiz.  The results will rank your role strengths and includes a brief summary of each role.
                </p>
                <p>
                  We all play roles... We will show you how to play them well.
                </p>
                <p>
                  Your teams Roleset is a guide to how you collectively approach work, people and problems. Expert teams know how to play all Seven Roles. Lumiere Sciences facilitates interactive trainings to train the Seven Roles and optimize team effectiveness.
                </p>
                <div className="text-center" style={isMobile ? {marginBottom: "15px"} : {}}>
                  <TakeQuiz/>
                  <div style={{marginTop: "15px"}}>
                    <TeamCoachingLink/>
                  </div>
                </div>
              </div>
            </Section>
          </div>
          <div className="col-md-6" style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
            <img src={esc} style={{width: "100%"}}/>
          </div>
        </div>
      </OddSection>
      <EvenSection>
        <div >
          <Section faClass="fa-users" header="Used By">
            <div style={{display: "flex", justifyContent: "space-between", marginTop: "45px"}}>
              <img src={google} style={{height: "40px"}}/>
              <img src={slack} style={{height: "40px"}}/>
              <img src={adobe} style={{height: "40px"}}/>
            </div>
          </Section>
        </div>
      </EvenSection>
    </div>
  }
}
const mapStateToProps = (state, props) => {
  return {}
}
export default connect(mapStateToProps, {
})(Home)
