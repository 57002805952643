import React from 'react'
import { connect } from 'react-redux'
import { entitiesAdminGetOrganizations } from "../../actions/entities"

class Quiz extends React.Component {
  constructor() {
    super()
    this.state = {
      organizationIds: []
    }
  }
  componentDidMount() {
    this.props.entitiesAdminGetOrganizations().then((action) => {
      this.setState({organizationIds: action.response.result})
    })
  }

  render() {
    const {organizations} = this.props
    const { organizationIds } = this.state

    return <div className="container"  style={{marginBottom: "30px"}}>
      {
        organizationIds.map((id) => {
          const o = organizations[id]
          return <div key={id} style={{display: "flex", justifyContent: "space-between"}}>
            <div>
              {id} - <a href={o.slug} target="_blank?">{o.token}</a>
            </div>
            <div>
              {o.user && <span>
                {o.user.email} - 
              </span>}
              {o.createdAt}
            </div>
          </div>
        })
      }
    </div>
  }
}

const mapStateToProps = (state, props) => {
  const {organizations} = state.entities
  return {organizations}
}

export default connect(mapStateToProps, {
  entitiesAdminGetOrganizations
})(Quiz)
