import React from 'react'
import { connect } from 'react-redux'
import {
  Link
} from "react-router-dom";
import Switch from "../../components/Switch"

class Settings extends React.Component {
  constructor(props) {
    super(props)
    const {
      organization
    } = props

    this.state = {
      email: "",
      name: "",
      saving: false,
      sendInvites: organization.sendInvites
    }
  }
  sendInvites(value) {
    const {
      organization
    } = this.props

    this.setState({sendInvites: value})
  }
  render() {
    const {
      organization
    } = this.props

    return <div>
      <h4>Settings</h4>
      <div className="card">
        <div className="card-body">
          <div style={{display: "flex"}}>
            <div style={{flex: 1, verticalAlign: "middle"}}>Send Invites</div>
            <div>
              <Switch
                onChange={(value) => {
                  this.sendInvites(value)
                }}
                value={this.state.sendInvites}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}

const mapStateToProps = (state, props) => {
  const {match: { params: { organizationId } } } = props
  const {
    organizations
  } = state.entities
  const organization = organizations[organizationId]

  return {
    organization
  }
}

export default connect(mapStateToProps, {
})(Settings)
