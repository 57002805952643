import React from 'react'
import { connect } from 'react-redux'
import { entitiesCreateQuestion, entitiesGetQuiz } from "../../actions/entities"

class NewQuestion extends React.Component {
  constructor(props) {
    super()
    this.state = this.defaultState(props)
  }
  defaultState(props) {
    console.log(props)
    const secondaryScores = {}
    Object.values(props.personas).forEach((persona, i) => {
      secondaryScores[persona.id] = 0
    })
    return {
      personaId: Object.keys(props.personas)[0],
      question: null,
      order_key: props.number,
      reverseCalculate: false,
      secondaryScores,
      quizId: props.quiz.id
    }
  }

  edited() {
    return true
    return Object.values(this.state).filter((i) => i).length === Object.keys(this.state).length
  }
  createQuestion() {
    const {question, quiz} = this.props
    this.props.entitiesCreateQuestion(this.state).then(() => {
      this.props.entitiesGetQuiz(quiz.id)
    })
    this.setState(this.defaultState(this.props))
    this.props.onCreate()
  }
  currentNumber() {
    const ok = this.state.order_key
    if (ok !== null) return ok
    return this.props.number
  }
  render() {
    const {personas, number, quiz} = this.props

    return <div >
      <div className="card" style={{marginTop: "15px"}}>
        <div className="card-body">
          New Question <input type="number" value={this.currentNumber()} onChange={(e) => {
            const val = e.target.value
            this.setState({
              order_key: val
            })
          }}/>
          <div style={{}}>
            <textarea onChange={(e) => {
              const val = e.target.value
              this.setState({
                question: e.target.value
              })
            }} className="form-control" placeholder="Question" value={this.state.question}/>
            <div style={{marginTop: "15px", maxWidth: "600px"}}>
              <select className="form-control" defaultValue={this.state.personaId} onChange={(e)=> {
                  const personaId = e.target.value
                  this.setState({personaId})
                }}>
                {
                  quiz.personas.map((pid) => {
                    const persona = personas[pid]
                    return <option key={persona.id} value={persona.id}>{persona.name}</option>
                  })
                }
              </select>
            </div>
          </div>
          <div style={{marginTop: "15px"}}>
            <input type="checkbox" onChange={() => {
              this.setState({reverseCalculate: !this.state.reverseCalculate})

            }} checked={this.state.reverseCalculate}/> Reverse Calculate
          </div>
          <div style={{marginTop: "15px"}}>
            Scores: (Max 1 and Min -1)
            <div style={{display: "flex",flexWrap: "wrap"}}>
              {
                quiz.personas.map((pid) => {
                  const persona = personas[pid]
                  return <div key={persona.id} value={persona.id} style={{minWidth: "300px"}}>
                    {persona.name}
                    <input value={this.state.secondaryScores[persona.id]} onChange={(e) => {
                      let nv = Math.min(parseFloat(e.target.value),1)
                      nv = Math.max(nv, -1)
                      let secondaryScores = JSON.parse(JSON.stringify(this.state.secondaryScores))
                      if (window.isNaN(nv)) nv = ""
                      secondaryScores[persona.id] = nv
                      this.setState({secondaryScores})
                    }} className="form-control" type="number"/>
                    </div>
                })
              }
            </div>
          </div>
          <div className="text-right">
            <button disabled={!this.edited()} onClick={() => {
              this.createQuestion()
            }} className="btn btn-success">Create</button>
          </div>
        </div>
      </div>
    </div>
  }
}

const mapStateToProps = (state, props) => {
  const {personas} = state.entities
  return {
    personas,
  }
}

export default connect(mapStateToProps, {
  entitiesCreateQuestion,
  entitiesGetQuiz
})(NewQuestion)
