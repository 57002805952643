import React from 'react'
import { connect } from 'react-redux'
import { entitiesGetQuizInstances, entitiesAdminGetSummaryGroups } from "../../actions/entities"
import NewSummaryGroup from "../../components/admin/NewSummaryGroup"
import {
  Link
} from "react-router-dom";
import queryString from "query-string"
import moment from "moment"

class Roles extends React.Component {
  constructor() {
    super()
    this.state = {
      summaryGroups: []
    }
  }
  componentDidMount() {
    this.props.entitiesAdminGetSummaryGroups().then((action) => {
      this.setState({summaryGroups: action.response.result})
    })
  }

  render() {

    return <div className="container"  style={{marginBottom: "30px"}}>
      Groups:
      <ul>
        {this.state.summaryGroups.map((sgId) => {
          const sg = this.props.summaryGroups[sgId]
          return <li key={sg.id}>
              <Link to={`/admin/summary_groups/${sg.id}`}>{sg.name} - {sg.id}</Link>
            </li>
        })}
      </ul>
      <NewSummaryGroup/>
    </div>
  }
}

const mapStateToProps = (state, props) => {
  const {summaryGroups} = state.entities
  return {summaryGroups}
}

export default connect(mapStateToProps, {
  entitiesAdminGetSummaryGroups
})(Roles)
