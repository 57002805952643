import React from 'react'
import {
  Link
} from "react-router-dom";
import { connect } from 'react-redux'
import colors from "../constants/colors"
// const logo = require("../assets/images/small-logo.jpg")
import {isDashboard} from "../utils/dashboard"
import logo from "../assets/images/small-logo.jpg"

console.log(1)
const navItemStyle = {
  height: "80px",
  padding: "0 15px",
  color: "white"
}
const navMobStyle = {
  height: "80px",
  padding: "0 5px",
  color: "white"
}
const items = [
  // {
  //   name: "HOME",
  //   url: "http://sevenroles.com/"
  // },
  // {
  //   name: "ABOUT",
  //   url: "http://sevenroles.com/about/"
  // },
  // {
  //   name: "LEARN",
  //   url: "http://sevenroles.com/learn/"
  // },
  // {
  //   name: "SURVEY",
  //   url: "http://sevenroles.com/survey/"
  // },
  // {
  //   name: "CONTACT",
  //   url: "http://sevenroles.com/contact/"
  // },
]
const minWidth = 1240
class Nav extends React.Component {
  constructor() {
    super()
    this.state = {
      menuActive: false,
      loaded: false,
      width: this.width(),
      subnavFocus: null
    }
    this.resize = this.resize.bind(this)
  }
  componentDidMount() {
    this.resizeListener = window.addEventListener("resize", this.resize)
    this.setState({loaded: true})
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize)
  }

  resize() {
    this.setState({width: this.width()})
  }

  width() {
    return window.innerWidth
  }

  toggleMenu() {
    this.setState({menuActive: !this.state.menuActive})
  }

  renderOptions(type) {
    return <div style={{display: "flex", color: "white"}}>
      {items.map((item, i) => {
        return <div key={i}><a style={type === "mobile" ? navMobStyle : navItemStyle} href={item.url} rel="noreferrer">{item.name}</a></div>
      })}
    </div>
  }

  renderMiddleContent(hoverable) {
    const { loggedIn } = this.props
    return <ul className="center-list">
      <li>
        <span
          style={{marginLeft: 0}}
          onMouseEnter={() => hoverable && this.setState({subnavFocus: "the-roles"})}
        >The Roles</span>
        {!hoverable && <div style={{marginTop: "5px"}}>
          {this.renderRolesLinks()}
        </div>
          }
      </li>
      <li>
        <span
          onMouseEnter={() => hoverable && this.setState({subnavFocus: "workplace-solutions"})}
        >Workplace solutions</span>
        {!hoverable && <div style={{marginTop: "5px"}}>
          { this.renderWorkplaceLinks()}
        </div>
          }
      </li>
      <li>
        <Link to="/about-us"
          onMouseEnter={() => hoverable && this.setState({subnavFocus: null})}
        >About Us</Link>
      </li>
      <li>
        <Link to="/articles"
          onMouseEnter={() => hoverable && this.setState({subnavFocus: null})}
        >Articles</Link>
      </li>
      <li>
        <Link
          style={{marginRight: 0}}
          onMouseEnter={() => hoverable && this.setState({subnavFocus: null})}
        to="/quiz">Take the Quiz</Link>
      </li>
    </ul>
  }

  renderRightContent() {
    const { loggedIn } = this.props
    return <div>
      { this.renderSignupSignin() }
    </div>
  }

  renderSignupSignin() {
    const { loggedIn } = this.props
    if (!loggedIn && this.state.width < minWidth) {
      return <ul className="center-list">
        <li>
          <Link to="/login">Signup</Link>
        </li>
        <li>
          <Link to="/login">Login</Link>
        </li>
      </ul>
    }

    if (!loggedIn) return  <div>
      <Link style={{color: "#232323", marginRight: "15px"}}to="/login">Login</Link><
        Link to="/signup"
          className="btn btn-primary"
        style={{border: "none", borderRadius: "30px 30px 30px 30px", width: "100px", backgroundColor: colors.secondary}}>Signup</Link>
    </div>
    return null
  }

  renderSubnav() {

    return <div className={`subnav ${this.state.subnavFocus ? 'active' : ""}`} style={{backgroundColor: colors.primary}}>
      <div className="container" style={{marginTop: "30px", marginBottom: "30px"}}>
        { this.state.subnavFocus == "workplace-solutions" && this.renderWorkplaceLinks() }
        { this.state.subnavFocus == "the-roles" && this.renderRolesLinks() }
      </div>
    </div>
  }
  renderWorkplaceLinks() {
    return <ul className="subnav-content">
      <li>
        <Link onClick={() => this.toggleMenu()}  to="/workplace-solutions/understand-your-organization">Understand your organization</Link>
      </li>
      <li>
        <Link onClick={() => this.toggleMenu()}  to="/workplace-solutions/live-training">Live training</Link>
      </li>
      <li>
        <Link onClick={() => this.toggleMenu()}  to="/workplace-solutions/online-training">Online training</Link>
      </li>
    </ul>
  }
  renderRolesLinks() {
    return <ul className="subnav-content">
      <li>
        <Link to="/the-roles/what-are-the-roles">What are the Roles?</Link>
      </li>
      <li>
        <Link to="/the-roles/why-the-roles">Why the Roles?</Link>
      </li>
    </ul>
  }
  render() {
    const { loggedIn, isDashboard } = this.props
    const {account} = this.props
    const style = { height: isDashboard ? 0 : "100px", position: "relative"}
    if (isDashboard) {
      style.padding = 0
    }
    return <div >
      {this.state.menuActive && <div onClick={() => this.setState({menuActive: false})} style={{position: "fixed", zIndex: 1, left: 0, top: 0, width: "100%", height: "100%"}}/>}

      <div style={{backgroundColor: colors.primary, color: "white"}}>
        <nav className="navbar" style={style}>
          { !isDashboard &&
            <div className={ "container-fluid"} style={{justifyContent: "space-between", display: "flex"}}>
              <div className="nav-left">
                <a  rel="noreferrer" href={"/"} style={{ textDecoration: "none", color: "black"}}>
                  <img style={{width: "40px", marginRight: "10px"}} src={logo}/>
                  <span id="main-logo" style={{lineHeight: "50px"}}>
                    My Team Role
                  </span>
                </a>
              </div>
              <div className="hide-mobile navbar-middle-content"
                onMouseLeave={() => this.setState({subnavFocus: null})}
              >
                {this.renderSubnav()}
                {this.renderMiddleContent(true)}
              </div>
              <div className="hide-mobile navbar-right-content right-nav text-right" style={{flex: 1}}>
                {!loggedIn && this.state.width >= minWidth && this.renderRightContent()}
              </div>
            </div>
          }
            { (loggedIn || this.state.width < minWidth) &&
            <div>
              <div style={{position: "absolute", zIndex: 2, top: 0, right: "15px"}} className="navbar-menu-button">
                <button onClick={() => this.toggleMenu()} className={`hamburger hamburger--slider ${this.state.menuActive ? "is-active" : ""}`} type="button">
                  <span className="hamburger-box">
                    <span className="hamburger-inner"></span>
                  </span>
                </button>  
            </div>
            <div className={`navbar-drawer ${this.state.menuActive ? "navbar-drawer-active" : "navbar-drawer-inactive"}`}>
              <div className={`${this.state.menuActive ? "" : "navbar-drawer-content-inactive" }`} style={{marginTop: "70px"}}>
                  {this.renderRightContent(false)}
                  { loggedIn &&
                    <ul className="center-list">
                      <li>
                        <Link onClick={() => this.toggleMenu()}  to="/logout">logout</Link>
                      </li>
                      <li>
                        <Link onClick={() => this.toggleMenu()}  to="/u/results">My Results</Link>
                      </li>
                      { (account && account.accountType == "licensee") &&
                        <>
                          <li>
                            <Link onClick={() => this.toggleMenu()} to="/account">Account</Link>
                          </li>
                        </>
                      }
                    </ul>
                  }
                  {this.renderMiddleContent()}
                </div>
              </div>
            </div>
            }
        </nav>
      </div>
    </div>
  }
}
const mapStateToProps = (state, props) => {
  const {
    users,
    userOrganizations,
    organizations,
    quizzes,
    accounts,
  } = state.entities
  const {
    email,
    currentUser
  } = state.currentUser
  if (!currentUser) {
    return {
      email
    }
  }
  const user = users[currentUser.id]
  if (!user) {
    return {
      email
    }
  }
  return {
    email,
    user,
    currentUser,
    account: accounts[currentUser.account]
  }
}

export default connect(mapStateToProps, {
})(Nav)

