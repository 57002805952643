import React from 'react'
import { connect } from 'react-redux'
import {
  Link
} from "react-router-dom";
import BreadCrumbs from "../../components/BreadCrumbs"
import {entitiesGetTrainings, entitiesGetCourses, entitiesGetPersonas} from "../../actions/entities"
import roles, { findRole }  from "../../utils/roles"
import ReactPlayer from 'react-player'

class Trainings extends React.Component {
  constructor() {
    super()
    this.state = {
      filterRole: "all",
      courses: []
    }
  }
  componentDidMount() {
    this.props.entitiesGetCourses().then((action) => {
      if (action && action.response) {
        this.setState({courses: action.response.result})
      }
    })
  }
  render() {
    const crumbs = [
      {name: "Trainings"}
    ]
    const {orderedRoles, personas} = this.props

    return <div>
      <BreadCrumbs crumbs={crumbs}/>
      <div  className="course-grid">
        {
          this.state.courses.map((id) => {
            const course = this.props.courses[id]
            return <div key={id}>
              <h5 className="text-center">{course.name}</h5>
              <ReactPlayer controls url={course.videoUrl} width= "100%" />
              <div className="text-center">Trainings: {course.trainingsCount}</div>
            </div>
        })
        }
      </div>
    </div>
  }
}

const mapStateToProps = (state, props) => {
  const {trainings, courses, personas, roles} = state.entities

  return {
    courses,
    trainings,
    personas,
    roles
  }
}

export default connect(mapStateToProps, {
  entitiesGetTrainings, entitiesGetPersonas, entitiesGetCourses
})(Trainings)

