import React from 'react'
import { connect } from 'react-redux'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { entitiesGetOrganization} from "../../actions/entities"
import TeamMembers from "./TeamMembers"
import Analysis from "./Analysis"
import Settings from "./Settings"

class Show extends React.Component {
  constructor() {
    super()
    this.state = {
    }
  }
  componentDidMount() {
    this.props.entitiesGetOrganization(this.props.organizationId)
  }
  renderSetting(name, url) {
    const active = window.location.pathname === url
    return <div style={{borderBottom: "1px solid rgba(1,1,1,.1)", display: "flex"}}>
      <div style={{width: "3px", backgroundColor: active ? "rgba(111, 107, 145, 0.94)" : "transparent"}}></div>
      <div style={{flex: 1, padding: "7px"}}>
        {active && name}
        {!active && <Link to={url}>{name}</Link>}
      </div>
    </div>
  }
  render() {
    const {
      email, organization
    } = this.props
    if (!email) return <div className="text-center" style={{marginTop: "15px"}}>You need to <a href="/login">login</a> to view this page</div>
    if (!organization ) return <div className="text-center">Loading...</div>

    return <div className="container" style={{marginTop: "15px"}}>
      <h4>{organization.name}</h4>
      <div style={{ display: "flex"}}>
        <div style={{marginRight: "15px", width: "300px"}}>
          <div className="card">
            { this.renderSetting("Team Members", organization.slug) }
            { this.renderSetting("Analysis", organization.slug + "/analysis") }
            { false &&  this.renderSetting("Settings", organization.slug +"/settings") }
          </div>
        </div>
        <div style={{flex: 1}}>
          <Switch>
            <Route exact path="/account/organizations/:organizationId" component={TeamMembers} />
            <Route exact path="/account/organizations/:organizationId/analysis" component={Analysis} />
            <Route exact path="/account/organizations/:organizationId/settings" component={Settings} />
          </Switch>
        </div>
      </div>
    </div>
  }
}

const mapStateToProps = (state, props) => {
  const {match: { params: { organizationId } } } = props
  const {
    organizations
  } = state.entities
  const organization = organizations[organizationId]
  const {
    email,
    currentUser
  } = state.currentUser
  if (!currentUser) {
    return {
      organizationId,
      email
    }
  }
  return {
    email,
    organizationId,
    organizations,
    organization
  }
}

export default connect(mapStateToProps, {
  entitiesGetOrganization
})(Show)
