import React, { Component } from 'react'
import { connect } from 'react-redux'
import erin from "../assets/images/erin.jpg"
import TeamCoachingLink from "../components/TeamCoachingLink"
import mixpanel from '../utils/mixpanel'

class LiveTraining extends Component {
  componentDidMount() {
    mixpanel.page()
  }
  render() {
    return <div className="container" >
      <h2 className="text-center" style={{fontWeight: "bold", marginTop: "30px", marginBottom: "30px"}}>Live Training</h2>
      <div className="row">
        <div className="col-md-6" style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
          <p style={{fontSize: "18px"}}>
            We offer live trainings, both virtual and in person through our sister company, Lumiere Sciences. For more information please visit lumieresciences.com or click the button!
          </p>

          <div style={{marginTop: "15px"}} className="text-center">
            <TeamCoachingLink />
          </div>
        </div>
        <div className="col-md-6">
          <img src={erin} style={{width: "100%"}}/>
        </div>
      </div>
    </div>
  }
}

function mapStateToProps(state, props) {
  return {
  }
}
export default connect(mapStateToProps, {
})(LiveTraining)
