import React from 'react'
import colors from '../constants/colors'
export const Bullet = ({faClass}) => {
  return <div className="d-none d-lg-block testclass">
    <div className="text-center" style={{paddingTop: "12px", fontSize: "23px", backgroundColor: colors.primary, color: "white", width: "63px", height: "63px"}} >
      <i aria-hidden="true" className={`fas ${faClass}`} style={{verticalAlign: "middle"}}></i>
    </div>
  </div>
}

export const EvenSection = ({children}) =>{
  return <div style={{backgroundColor: "white", padding: "100px 0"}} >
    <div className="container">
      {children}
    </div>
  </div>

}
export const OddSection = ({children}) =>{
  return <div style={{backgroundColor: "#EAECF3", padding: "100px 0"}} >
    <div className="container">
      {children}
    </div>
  </div>

}

class Section extends React.Component {
  constructor() {
    super()
    this.state = {
      loaded: false,
      height: this.height(),
      width: this.width()
    }

    this.roleRef = React.createRef()
    this.resize = this.resize.bind(this)
  }
  componentDidMount() {
    this.resizeListener = window.addEventListener("resize", this.resize)
    this.setState({loaded: true})
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize)
  }
  resize() {
    this.setState({height: this.height(), width: this.width()})
  }
  width() {
    return window.innerWidth
  }
  height() {
    return window.innerHeight - 80
  }
  render() {
    const {header, faClass, children} = this.props
    const isMobile = this.state.width <= 991
    const margin = !isMobile ? {marginLeft: "70px", flex: 1} : {flex: 1}

    return <div style={{display: "flex"}}>
      <Bullet faClass={faClass}/>
      <div style={margin}>
        <h2 className="text-left" style={{fontWeight: "bold", margin: "12px 0"}}>{header}</h2>
        {children}
      </div>
    </div>
  }
}

export default Section
