import React from "react"
import styled from 'styled-components'
import _ from "lodash"

const SideNavComponent = styled.div`
  background-color: white;
  border-right: 1px solid rgba(1,1,1,.1);
  box-shadow: 0 1px 3px rgb(13 26 44 / 16%), 0 15px 25px rgb(13 26 44 / 24%);
  overflow: hidden;
  top: 0;
  left: 0;
`
const Header = styled.div`
  border-bottom: 1px solid rgba(1,1,1,.1);
  padding: 10px;
  margin-bottom: 5px;

`
class SideNav extends React.Component {
  constructor(props) {
    super()
    let keep = true
    keep = keep === 1

    this.state = {
      keep: keep
    }
  }
  key(props) {
    return 'side-nav-' + props.keepable
  }

  // renderCollapsed() {
  //   return <div style={this.style()}
  //   >
  //     <h6 style={{marginTop: "15px"}}>{this.props.title}</h6>
  //   </div>
  // }

  render() {
    // if (!this.state.expanded) return this.renderCollapsed()
    const {keepable} = this.props
    const staticNav = this.props.static
    const baseZIndex = 10
    const style = {
      height: this.props.height,
      display: "flex",
      flexDirection: "column"
    }
    const childStyle = {
      flexDirection: "row",
      padding: this.props.paddingless ? 0 : 10,
      flex: 1
    }
    if (!staticNav) {
      childStyle.overflowY = "scroll"
    }
    let containerStyle = {
      zIndex: baseZIndex + this.props.baseIndex,
      width: "350px"
    }
    if (this.props.style) {
      containerStyle = _.merge(containerStyle, this.props.style)
    }
    if (this.props.hide && this.state.keep === false) return null

    return <SideNavComponent style={this.props.hide ? {display: "none"} : containerStyle}>
        <div style={style}
      >
        { !staticNav &&
          <Header>
              <button type="button" className="close" onClick={() => this.props.onClose()} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            <h6>
              { this.props.header}
            </h6>
          </Header>
        }
        <div style={childStyle}>
          {this.props.children}
        </div>
      </div>
    </SideNavComponent>
  }
}
SideNav.defaultProps = {
  baseIndex: 0
}

export default SideNav
