import React from 'react'
import { connect } from 'react-redux'
import { entitiesGetAdmins, entitiesAdminGetSummaryGroups,
  entitiesAdminUpdateQuizSummaryGroup,
  entitiesGetAdminQuizzes, entitiesSetQuizDefault, entitiesSetQuizDuplicate } from "../../actions/entities"
import {
  Link
} from "react-router-dom";
import _ from "lodash"
import Cookies from 'cookies-js'
import PersonaEdit from "../../components/admin/PersonaEdit"
import NewQuiz from "../../components/admin/NewQuiz"
import colors from "../../constants/colors"
/*
import AdminOrganizations from "./admin/Organizations"
import AdminQuiz from "./admin/Quiz"
                <Route exact path="/admin/quizzes/:id" component={AdminQuiz} />
                <Route exact path="/admin/quiz_instances" component={QuizInstances} />

*/
class Admin extends React.Component {
  constructor() {
    super()
    this.state = {
      newPersona: false,
      quizzes: [],
      summaryGroups: [],
      editing: null,
      newQuiz: false
    }
  }
  componentDidMount() {
    if (!Cookies.get("auth")) {
      window.location = "/login"
    } else {
      this.props.entitiesGetAdmins()
      this.props.entitiesAdminGetSummaryGroups().then((action) => {
        this.setState({summaryGroups: action.response.result})
      })
      this.props.entitiesGetAdminQuizzes().then((action) => {
        if (action.response && action.response.result) {
          this.setState({quizzes: action.response.result})
        } else {
          this.setState({notAdmin: true})
        }
      })
    }
  }

  render() {
    const {admin, personas} = this.props
    if (this.state.notAdmin) return <div className="text-center" style={{marginTop: "5px"}}>You are not an admin</div>
    if (!admin) return <div className="text-center">Loading...</div>
    const groupedQuizzes = _.groupBy(this.state.quizzes, (q) => {
      const quiz = this.props.quizzes[q]
      return quiz.category
    })

    return <div className="container" style={{marginBottom: "30px"}}>
      <div className="card" style={{marginTop: "15px"}}>
        <div className="card-header">Personas</div>
        <div className="card-body" style={{display: "flex"}}>
          {
            admin.personas.map((pid) => {
              const persona = personas[pid]
              return <div key={pid} style={{marginLeft: "15px"}}>{persona.name}</div>
            })
          }
        </div>
      </div>
      <div className="card" style={{marginTop: "15px"}}>
        <div className="card-header">Personas</div>
        <div className="card-body">
          <div>
            <div>Main Persona</div>
            <div>Quizzes Using: {["main", "Personal"].join(", ")}</div>
            <hr/>
            {
              admin.personas.map((pid) => {
                const persona = personas[pid]
                return <div key={pid}>{persona.name} <button className="btn btn-link" onClick={() => {
                    const val =  this.state.editing === pid ? null : pid
                    this.setState({editing: val})
                  }}>edit</button>
                  { this.state.editing === pid &&
                    <PersonaEdit personaId={pid}/>
                    }
                </div>
              })
            }
          </div>
        </div>
      </div>
      <div className="card" style={{marginTop: "15px"}}>
        <div className="card-header">Organizations</div>
        <div className="card-body">
          <Link className="btn btn-primary" to="/admin/organizations">View Orgs</Link>
        </div>
      </div>
      <div className="card" style={{marginTop: "15px"}}>
        <div className="card-header">Quiz</div>
        <div className="card-body">
          {
            Object.keys(groupedQuizzes).map((type) => {
              const qz = groupedQuizzes[type]
              return <div key={type}>
                <div style={{backgroundColor: "rgba(1,1,1,.1)", marginTop: "15px"}}>Quiz Category: {type}</div>
                <div className="profile-grid">
                  { qz.map((q) => {
                    const quiz = this.props.quizzes[q]
                    return <div key={q}>
                      <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div>
                          id
                        </div>
                        <div>
                          {quiz.id}
                        </div>
                      </div>
                      <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div>
                          Name
                        </div>
                        <div>
                          {quiz.name}
                        </div>
                      </div>
                      <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div>
                          Description
                        </div>
                        <div>
                          {quiz.description}
                        </div>
                      </div>
                      <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div>
                          Questions
                        </div>
                        <div>
                          {quiz.questionsCount}
                        </div>
                      </div>
                      <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div>
                          Quizzes Taken
                        </div>
                        <div>
                          {quiz.quizInstanceCount}
                        </div>
                      </div>
                      <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div>
                          Created
                        </div>
                        <div>
                          {quiz.createdAt.split("T")[0]}
                        </div>
                      </div>
                      <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div>
                          Summary Group
                        </div>
                        <div>
                          <select defaultValue={quiz.summaryGroup.id}
                            onChange={(e) => {
                              this.props.entitiesAdminUpdateQuizSummaryGroup(quiz.id, e.target.value).then(() => {
                                
                              })
                            }}
                          >
                            {
                              this.state.summaryGroups.map((sgId) => {
                                const sg = this.props.summaryGroups[sgId]
                                return <option key={sg.id} value={sgId}>{sg.name} - {sg.id}</option>
                              })
                            }
                          </select>
                        </div>
                      </div>
                      <Link className="btn btn-primary" style={{width: "100%"}} to={`/admin/quiz_instances?quiz_id=${q}`}>
                        <i className="fas fa-users" style={{marginRight: "5px"}}/>View Quizzes ({quiz.quizInstanceCount})
                      </Link>
                      <a href={`/api/admin/quiz_instances.csv?auth=${Cookies.get("auth")}&quiz_id=${q}`} className="btn btn-success" style={{marginTop: "5px", width: "100%"}} download={`roles-${new Date()}.csv`}>
                        <i className="fas fa-download" style={{marginRight: "5px"}}/>Download CSV ({quiz.quizInstanceCount})
                      </a>
                      <hr/>
                      <div style={{display: "flex", justifyContent: "space-between"}}>
                        <Link className="btn btn-link" to={`/admin/quizzes/${q}`}>Edit</Link>
                        { !quiz.default && <button className="btn btn-link" style={{color: colors.main.danger}} onClick={() => {
                          if (window.confirm(`Are you sure you would like to set this quiz as the default for ${type}`)) {
                            this.props.entitiesSetQuizDefault(q).then(() => {
                              window.location.reload()
                            })
                          }
                        }} >Set as Default</button>}
                        { quiz.default && <div className="text-muted" style={{marginTop: "7px"}}>is default</div>}
                        <button className="btn btn-link" style={{color: colors.main.success}}
                          onClick={() => {
                            if (this._duplicatiing) {
                              return
                            }
                            this._duplicatiing = true
                            this.props.entitiesSetQuizDuplicate(q).then(() => {
                              window.location.reload()
                            })
                          }}
                        >Duplicate</button>
                      </div>
                    </div>
                  })}
                </div>
              </div>
            })
          }
          <hr/>
          <div>
            <button className="btn btn-success" onClick={() => {
              this.setState({newQuiz: !this.state.newQuiz})
            }}><i className="fas fa-plus" style={{marginRight: "5px"}}/>New Quiz</button>
          </div>
          {
            this.state.newQuiz && <NewQuiz onComplete={() => this.setState({newQuiz: false})}/>
          }
        </div>
      </div>
    </div>
  }
}

const mapStateToProps = (state, props) => {
  const {personas, admins, quizzes, summaryGroups} = state.entities
  return {
    personas,
    quizzes,
    summaryGroups,
    admin: admins[1]
  }
}

export default connect(mapStateToProps, {
  entitiesGetAdmins,
  entitiesGetAdminQuizzes,
  entitiesSetQuizDefault,
  entitiesSetQuizDuplicate,
  entitiesAdminGetSummaryGroups,
  entitiesAdminUpdateQuizSummaryGroup
})(Admin)
