import React from 'react'
import { connect } from 'react-redux'
import Nav from "../components/Nav"
import Errors from "../components/Errors"
import {
  Link
} from "react-router-dom";
import Cookies from 'cookies-js';
import {currentUserGet} from "../actions/currentUser"
import {isDashboard} from "../utils/dashboard"

const Footer = () => {
  return <div style={{paddingBottom: "50px"}}>
    <div style={{
      position: "absolute",
      bottom: 0,
      backgroundColor: "white",
      width: '100%',
      borderTop: '1px solid #DDD'
    }}>
      <div className="container" style={{display: "flex"}}>
      <div style={{marginTop: "5px"}}>
        <Link to="/contact-us">
          <div className="btn btn-link">contact us</div>
        </Link>
      </div>
      <div className="text-center" style={{flex: 1, marginTop: "10px"}}>
        © 2020, All Rights Reserved
      </div>
      <div style={{marginTop: "5px"}}>
        <Link to="/terms-of-service">
          <div className="btn btn-link">terms of service</div>
        </Link>
      </div>
    </div>
  </div>
  </div>
}

class App extends React.Component {
  constructor() {
    super()
    this.state = {
      fetchUser: true
    }
  }
  componentDidMount() {
    this.props.currentUserGet().then((action) => {
      this.setState({fetchUser: false})
      if (action && action.type === "CURRENT_USER_FAILURE") {
        // Cookies.set('auth', null, {
        //   secure: window.location.host.match("localhost") || window.location.host.match("192.168.1.115")  ? false : true ,
        //   expires: 1
        // })
      }
    }).catch(() => {
      this.setState({fetchUser: false})
    })
  }
  render() {
    const { error, email, auth } = this.props
    return <div style={{height: "100%", minHeight: window.innerHeight + "px", position: "relative"}}>
      
      <Nav isDashboard={isDashboard()} email={email} loggedIn={auth != null}/>
      { error && <Errors/>}
      { !this.state.fetchUser && this.props.children }
      {!(isDashboard()) && <Footer/>}
    </div>
  }
}

const mapStateToProps = (state, props) => {
  const {email, auth} = state.currentUser
  const {message} = state.errors
  return {error: message, email, auth, pathname: window.location.pathname}
}

export default connect(mapStateToProps, {
  currentUserGet
})(App)
