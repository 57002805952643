import React, { Component } from 'react'
import { connect } from 'react-redux'
import mixpanel from '../utils/mixpanel'

class OnlineTraining extends Component {
  componentDidMount() {
    mixpanel.page()
  }
  render() {
    return <div className="container" style={{marginTop: "15px", marginTop: "300px"}} className="text-center">
      <h4>COMING SOON 10/1/21</h4>
    </div>
  }
}

function mapStateToProps(state, props) {
  return {
  }
}
export default connect(mapStateToProps, {
})(OnlineTraining)
