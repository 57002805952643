import React from 'react';
import { Provider } from 'react-redux'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { ConnectedRouter } from 'connected-react-router'
import Home from "./Home"
import QuizInstance from "./QuizInstance"
import App from "./App"
import Quiz from "./Quiz"
import Tos from "./Tos"
import Login from "./Login"
import Signup from "./Signup"
import Logout from "./Logout"
import OrganizationsShow from "./organizations/Show"
import Compare from "./Compare"
import FourOFour from "./FourOFour"
import PasswordReset from "./PasswordReset"
import TheRoles from "./TheRoles"
import Team from "./Team"
import AdminIndex from "./admin/Index"
import UserIndex from "./user/UserIndex"
import Role from "./Role"
import Articles from "./Articles"
import Article from "./Article"
import OnlineTraining from "./OnlineTraining"
import LiveTraining from "./LiveTraining"
import YourOrg from "./YourOrg"
import WhyRoles from "./WhyRoles"
import AboutUs from "./AboutUs"
import ContactUs from "./ContactUs"
import AccountIndex from "./accounts/Index"
import FacilitatorsIndex from "./facilitators/Index"
import configureStore from '../store/configureStore'
import history from "../store/history"
import '../stylesheets/bootstrap.css';
import '../App.css';
import '../stylesheets/hamburger.css';
import '../stylesheets/nav.css';
const store = configureStore()

export default () => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <div>
          <App>
            <div>
              <Switch>
                <Route exact path="/compare" component={Compare} />
                <Route exact path="/results/:token" component={QuizInstance} />
                <Route exact path="/terms-of-service" component={Tos} />
                <Route path="/organizations/:organizationId" component={OrganizationsShow} />
                <Route path="/admin" component={AdminIndex} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/signup" component={Signup} />
                <Route exact path="/logout" component={Logout} />
                <Route exact path="/signup" component={Signup} /> 
                <Route exact path="/logout" component={Logout} />
                <Route exact path="/articles" component={Articles} /> 
                <Route exact path="/workplace-solutions/online-training" component={OnlineTraining} /> 
                <Route exact path="/workplace-solutions/live-training" component={LiveTraining} /> 
                <Route exact path="/articles" component={Articles} /> 
                <Route exact path="/articles/:articleTitle" component={Article}></Route>
                <Route exact path="/about-us" component={AboutUs} /> 
                <Route exact path="/workplace-solutions/understand-your-organization" component={YourOrg} /> 
                <Route exact path="/contact-us" component={ContactUs} /> 
                <Route exact path="/the-roles/what-are-the-roles" component={TheRoles} /> 
                <Route exact path="/the-roles/why-the-roles" component={WhyRoles} /> 
                <Route exact path="/users/password/edit" component={PasswordReset} />
                <Route exact path="/quiz" component={Quiz} />
                <Route exact path="/teams/:teamId" component={Team} />
                <Route exact path="/roles/:name" component={Role} />
                <Route exact path="/" component={Home} />
                <Route path="/facilitators" component={FacilitatorsIndex}/>
                <Route path="/u" component={UserIndex} />
                <Route path="/account" component={AccountIndex} />
                <Route path="/" component={FourOFour} />
              </Switch>
            </div>
          </App>
        </div>
      </ConnectedRouter>
    </Provider>
  );
}
