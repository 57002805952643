import React, { Component } from 'react'
import { Link } from 'react-router-dom'
export default ({crumbs}) => {
  return <div style={{display: "flex", paddingLeft: "15px", fontSize: "20px", borderBottom: "1px solid rgba(1,1,1,.1)", paddingTop: "8px", paddingBottom: "5px", marginBottom: "15px"}}>
    {
      crumbs.map((cr) => {
        if (cr.url) return <React.Fragment key={cr.name}><Link to={cr.url}>{cr.name}</Link><div style={{padding: "0 15px"}}>/</div></React.Fragment>
        return <div key={cr.name}>{cr.name}</div>
      })
    }
  </div>
}
