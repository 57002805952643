import React from 'react'
import { connect } from 'react-redux'
import {
  Link
} from "react-router-dom";
import BreadCrumbs from "../../components/BreadCrumbs"
import { entitiesGetMyQuizInstances } from "../../actions/entities"
import moment from "moment"

class Results extends React.Component {
  constructor() {
    super()
    this.state = {
      results: []
    }
  }
  render() {
    const crumbs = [
      // {name: "Leads", url: "/lrm"},
      {name: "Departments"}
    ]
    const {orderedRoles, quizInstances} = this.props

    
    const departments = [
      {
        id: 1,
        name: "ABP Search",
        url: "/u/departments/1-abp-search",
        userCount: 30,
        high: "Team Builder",
        low: "Investor",
        roles: {
          prophet: 2,
          luminary: 5,
          conceptualizer: 5,
          organizer: 2,
          teambuilder: 15,
          investor: 1,
        }
      },
      {
        id: 2,
        name: "Search",
        url: "/u/departments/2-search",
        userCount: 60,
        high: "Organizer",
        low: "Prophet",
        roles: {
          prophet: 2,
          luminary: 5,
          conceptualizer: 5,
          organizer: 2,
          teambuilder: 15,
          investor: 1,
        }
      }
    ]
    return <div>
      <BreadCrumbs crumbs={crumbs}/>
      <div>
        {departments.map((d) => {
          return <div className="card" style={{maxWidth: "300px", marginBottom: "5px", padding: "5px"}} key={d.id}>
            <div>
              <Link to={d.url}>{d.name}</Link>
            </div>
            <div>People {d.userCount}</div>
            <div>High in {d.high}</div>
            <div>Low in {d.low}</div>
          </div>
        })}
      </div>
    </div>
  }
}
const mapStateToProps = (state, props) => {
  return {quizInstances: state.entities.quizInstances}
}
export default connect(mapStateToProps, {
  entitiesGetMyQuizInstances
})(Results)
