import queryString from 'query-string';
import moment from "moment"
import humps from "humps"
import {Schemas} from "../middleware/schemas";
import trainings, {personas} from "../utils/trainings"

export const entitiesGetTrainings = () => {
  return (dispatch) => {
    return dispatch({
      type: "ENTITIES_GET_DATA",
      response: trainings
    })
  }
}

export const entitiesGetMyQuizInstances = () => {
  return {
    ['CALL_API']: {
      types: [ "GET_REQUEST", "ENTITIES_GET_DATA", "GET_FAILURE" ],
      endpoint: "/quiz_instances",
      ext_req: {method: "get"},
      schema: Schemas.quizInstances
    }
  };
}

export const entitiesGetCourses = () => {
  return {
    ['CALL_API']: {
      types: [ "GET_REQUEST", "ENTITIES_GET_DATA", "GET_FAILURE" ],
      endpoint: "courses",
      ext_req: {method: "get"},
      schema: Schemas.courses
    }
  };
}


export const entitiesGetCompany = (id) => {
  return {
    ['CALL_API']: {
      types: [ "GET_REQUEST", "ENTITIES_GET_DATA", "GET_FAILURE" ],
      endpoint: `companies/${id}`,
      ext_req: {method: "get"},
      schema: Schemas.company
    }
  };
}

export const entitiesGetDepartment = (id) => {
  return {
    ['CALL_API']: {
      types: [ "GET_REQUEST", "ENTITIES_GET_DATA", "GET_FAILURE" ],
      endpoint: `departments/${id}`,
      ext_req: {method: "get"},
      schema: Schemas.department
    }
  };
}


export const entitiesGetCompanies = () => {
  return {
    ['CALL_API']: {
      types: [ "GET_REQUEST", "ENTITIES_GET_DATA", "GET_FAILURE" ],
      endpoint: "companies",
      ext_req: {method: "get"},
      schema: Schemas.companies
    }
  };
}

export const entitiesCreateDepartment = (data) => {
  return (dispatch, getState) => {
    return dispatch(
      {
        ['CALL_API']: {
          types: [ "GET_REQUEST", "ENTITIES_UPDATE", "GET_FAILURE" ],
          endpoint: `departments`,
          ext_req: {method: "post", body: humps.decamelizeKeys(data) },
          schema: Schemas.department
        }
      }
    )
  }
};

export const entitiesCreateCompany = (data) => {
  return (dispatch, getState) => {
    return dispatch(
      {
        ['CALL_API']: {
          types: [ "GET_REQUEST", "ENTITIES_UPDATE", "GET_FAILURE" ],
          endpoint: `companies`,
          ext_req: {method: "post", body: humps.decamelizeKeys(data) },
          schema: Schemas.company
        }
      }
    )
  }
};

export const entitiesGetPersonas = () => {
  return {
    ['CALL_API']: {
      types: [ "GET_REQUEST", "ENTITIES_GET_DATA", "GET_FAILURE" ],
      endpoint: "/personas",
      ext_req: {method: "get"},
      schema: Schemas.personas
    }
  };
}


export const entitiesGetMainQuiz = (quiz_token, organizationId) => {
  let url = `quizzes/main?`
  if (quiz_token) {
    url = url + `quiz_token=${quiz_token}`
  } else if (organizationId) {
    url = url + `organization_id=${organizationId}`
  }
  return {
    ['CALL_API']: {
      types: [ "GET_REQUEST", "ENTITIES_GET_MAIN_QUIZ", "GET_FAILURE" ],
      endpoint: url,
      ext_req: {method: "get"},
      schema: Schemas.quiz
    }
  };
};

export const entitiesAddQuizInstanceFromOrganzation = (organizationId, token) => {
  return {
    ['CALL_API']: {
      types: [ "GET_REQUEST", "ENTITIES_GET_DATA", "GET_FAILURE" ],
      endpoint: `organizations/${organizationId}/quiz_instances/${token}`,
      ext_req: {method: "post"},
      schema: Schemas.organization
    }
  };
};

export const entitiesRemoveQuizInstanceFromOrganzation = (organizationId, token) => {
  return {
    ['CALL_API']: {
      types: [ "GET_REQUEST", "ENTITIES_GET_DATA", "GET_FAILURE" ],
      endpoint: `organizations/${organizationId}/quiz_instances/${token}`,
      ext_req: {method: "delete"},
      schema: Schemas.organization
    }
  };
};

export const entitiesAdminGetOrganizations = () => {
  return {
    ['CALL_API']: {
      types: [ "GET_REQUEST", "ENTITIES_GET_DATA", "GET_FAILURE" ],
      endpoint: `admin/organizations`,
      ext_req: {method: "get"},
      schema: Schemas.organizations
    }
  };
};

export const entitiesAdminGetSummaryGroups = () => {
  return {
    ['CALL_API']: {
      types: [ "GET_REQUEST", "ENTITIES_GET_DATA", "GET_FAILURE" ],
      endpoint: `admin/summary_groups`,
      ext_req: {method: "get"},
      schema: Schemas.summaryGroups
    }
  };
};

export const entitiesAdminGetSummaryGroup = (id) => {
  return {
    ['CALL_API']: {
      types: [ "GET_REQUEST", "ENTITIES_GET_DATA", "GET_FAILURE" ],
      endpoint: `admin/summary_groups/${id}`,
      ext_req: {method: "get"},
      schema: Schemas.summaryGroup
    }
  };
};

export const entitiesAdminUpdateRoleSummary = (id, data) => {
  return (dispatch, getState) => {
    return dispatch(
      {
        ['CALL_API']: {
          types: [ "GET_REQUEST", "ENTITIES_UPDATE", "GET_FAILURE" ],
          endpoint: `admin/role_summaries/${id}`,
          ext_req: {method: "put", body: humps.decamelizeKeys(data) },
          schema: Schemas.roleSummary
        }
      }
    )
  }
};
export const entitiesAdminUpdateQuizSummaryGroup = (id, summaryGroupId) => {
  return (dispatch, getState) => {
    return dispatch(
      {
        ['CALL_API']: {
          types: [ "GET_REQUEST", "ENTITIES_UPDATE", "GET_FAILURE" ],
          endpoint: `admin/quizzes/${id}`,
          ext_req: {method: "put", body: humps.decamelizeKeys({summaryGroupId}) },
          schema: Schemas.quiz
        }
      }
    )
  }
};

export const entitiesAdminCreateSummaryGroup = (data) => {
  return (dispatch, getState) => {
    return dispatch(
      {
        ['CALL_API']: {
          types: [ "GET_REQUEST", "ENTITIES_UPDATE", "GET_FAILURE" ],
          endpoint: `admin/summary_groups`,
          ext_req: {method: "post", body: humps.decamelizeKeys(data) },
          schema: Schemas.summaryGroup
        }
      }
    )
  }
};

export const entitiesUpdateQuiz = (id, data) => {
  return {
    ['CALL_API']: {
      types: [ "GET_REQUEST", "ENTITIES_UPDATE", "GET_FAILURE" ],
      endpoint: `/admin/quizzes/${id}`,
      ext_req: {method: "put", body: humps.decamelizeKeys(data) },
      schema: Schemas.quiz
    }
  };
};

export const entitiesGetOrganization = (id) => {
  return {
    ['CALL_API']: {
      types: [ "GET_REQUEST", "ENTITIES_GET_DATA", "GET_FAILURE" ],
      endpoint: `organizations/${id}`,
      ext_req: {method: "get"},
      schema: Schemas.organization
    }
  };
};

export const entitiesGetAdminQuizzes = () => {
  return {
    ['CALL_API']: {
      types: [ "GET_REQUEST", "ENTITIES_GET_DATA", "GET_FAILURE" ],
      endpoint: `admin/quizzes`,
      ext_req: {method: "get"},
      schema: Schemas.quizzes
    }
  };
}


export const entitiesSetQuizDuplicate = (id) => {
  return {
    ['CALL_API']: {
      types: [ "GET_REQUEST", "ENTITIES_GET_DATA", "GET_FAILURE" ],
      endpoint: `admin/quizzes/${id}/duplicate`,
      ext_req: {method: "get"},
      schema: Schemas.quiz
    }
  };
}

export const entitiesSetQuizDefault = (id) => {
  return {
    ['CALL_API']: {
      types: [ "GET_REQUEST", "ENTITIES_GET_DATA", "GET_FAILURE" ],
      endpoint: `admin/quizzes/${id}/default`,
      ext_req: {method: "get"},
      schema: Schemas.quiz
    }
  };
}

export const entitiesCreateQuiz = (data) => {
  return (dispatch, getState) => {
    return dispatch(
      {
        ['CALL_API']: {
          types: [ "GET_REQUEST", "ENTITIES_GET_DATA", "GET_FAILURE" ],
          endpoint: `admin/quizzes`,
          ext_req: {method: "post", body: humps.decamelizeKeys(data) },
          schema: Schemas.quiz
        }
      }
    )
  }
};
export const entitiesGetTeam = (id) => {
  return {
    ['CALL_API']: {
      types: [ "GET_REQUEST", "ENTITIES_GET_DATA", "GET_FAILURE" ],
      endpoint: `teams/${id}`,
      ext_req: {method: "get"},
      schema: Schemas.team
    }
  };
};


export const entitiesGetOrganizations = () => {
  return {
    ['CALL_API']: {
      types: [ "GET_REQUEST", "ENTITIES_GET_DATA", "GET_FAILURE" ],
      endpoint: `user_organizations`,
      ext_req: {method: "get"},
      schema: Schemas.user
    }
  };
};

export const entitiesToggleOrganizationActive = (id, active, expires) => {
  return {
    ['CALL_API']: {
      types: [ "GET_REQUEST", "ENTITIES_UPDATE", "GET_FAILURE" ],
      endpoint: `/admin/organizations/${id}`,
      ext_req: {method: "put", body: humps.decamelizeKeys({active, expires}) },
      schema: Schemas.organization
    }
  };
};

export const entitiesGetQuizInstance = (token) => {
  return {
    ['CALL_API']: {
      types: [ "GET_REQUEST", "ENTITIES_GET_QUIZ_INSTANCE", "GET_FAILURE" ],
      endpoint: `quiz_instances/${token}`,
      ext_req: {method: "get"},
      schema: Schemas.quizInstance
    }
  };
};

export const entitiesGetRoleSummary = (name, quizId) => {
  return {
    ['CALL_API']: {
      types: [ "GET_REQUEST", "ENTITIES_GET_QUIZ_INSTANCE", "GET_FAILURE" ],
      endpoint: `role_summaries/${name.toLowerCase().split(" ").join("-")}?quiz_id=${quizId || ''}`,
      ext_req: {method: "get"},
      schema: Schemas.roleSummary
    }
  };
};

export const entitiesGetAccount = () => {
  return (dispatch, getState) => {
    const {currentUser: {currentUser}} = getState()
    return dispatch({
      ['CALL_API']: {
        types: [ "GET_REQUEST", "ENTITIES_UPDATE", "GET_FAILURE" ],
        endpoint: `accounts/me`,
        ext_req: {method: "get"},
        schema: Schemas.account
      }
    });
  }
};


export const entitiesGetQuizInstances = (quizId) => {
  return {
    ['CALL_API']: {
      types: [ "GET_REQUEST", "ENTITIES_GET_QUIZ_INSTANCES", "GET_FAILURE" ],
      endpoint: `admin/quiz_instances?quiz_id=${quizId}`,
      ext_req: {method: "get"},
      schema: Schemas.quizInstances
    }
  };
}
export const entitiesGetAdmins = (id) => {
  return {
    ['CALL_API']: {
      types: [ "GET_REQUEST", "ENTITIES_UPDATE", "GET_FAILURE" ],
      endpoint: `admin/index`,
      ext_req: {method: "get"},
      schema: Schemas.admin
    }
  };
};

export const entitiesGetQuizzes = (id) => {
  return {
    ['CALL_API']: {
      types: [ "GET_REQUEST", "ENTITIES_GET_QUIZ", "GET_FAILURE" ],
      endpoint: `quizzes`,
      ext_req: {method: "get"},
      schema: Schemas.quizzes
    }
  };
};

export const entitiesGetQuiz = (id) => {
  return {
    ['CALL_API']: {
      types: [ "GET_REQUEST", "ENTITIES_GET_QUIZ", "GET_FAILURE" ],
      endpoint: `quizzes/${id}`,
      ext_req: {method: "get"},
      schema: Schemas.quiz
    }
  };
};

export const entitiesUpdateQuestion = (questionId, data) => {
  return (dispatch, getState) => {
    return dispatch(
      {
        ['CALL_API']: {
          types: [ "GET_REQUEST", "ENTITIES_UPDATE", "GET_FAILURE" ],
          endpoint: `questions/${questionId}`,
          ext_req: {method: "put", body: humps.decamelizeKeys(data) },
          schema: Schemas.question
        }
      }
    )
  }
};

export const entitiesUpdatePersona = (id, data) => {
  return (dispatch, getState) => {
    return dispatch(
      {
        ['CALL_API']: {
          types: [ "GET_REQUEST", "ENTITIES_UPDATE", "GET_FAILURE" ],
          endpoint: `/personas/${id}`,
          ext_req: {method: "put", body: humps.decamelizeKeys(data) },
          schema: Schemas.persona
        }
      }
    )
  }
};

export const entitiesGetQuizPurchase = (token) => {
  return (dispatch, getState) => {
    return dispatch(
      {
        ['CALL_API']: {
          types: [ "GET_REQUEST", "ENTITIES_UPDATE", "GET_FAILURE" ],
          endpoint: `quiz_purchases/${token}`,
          ext_req: {method: "get"},
          schema: Schemas.quizPurchase
        }
      }
    )
  }
};

export const entitiesCreateQuizPurchase = (data) => {
  return (dispatch, getState) => {
    return dispatch(
      {
        ['CALL_API']: {
          types: [ "GET_REQUEST", "ENTITIES_UPDATE", "GET_FAILURE" ],
          endpoint: `quiz_purchases`,
          ext_req: {method: "post", body: humps.decamelizeKeys(data) },
          schema: Schemas.quizPurchase
        }
      }
    )
  }
};

export const entitiesCreateOrganizationUser = (organization_id, data) => {
  return (dispatch, getState) => {
    return dispatch(
      {
        ['CALL_API']: {
          types: [ "GET_REQUEST", "ENTITIES_UPDATE", "GET_FAILURE" ],
          endpoint: `organization_users?organization_id=${organization_id}`,
          ext_req: {method: "post", body: humps.decamelizeKeys(data) },
          schema: Schemas.organization
        }
      }
    )
  }
};
export const entitiesCreateOrganization = (data) => {
  return (dispatch, getState) => {
    return dispatch(
      {
        ['CALL_API']: {
          types: [ "GET_REQUEST", "ENTITIES_UPDATE", "GET_FAILURE" ],
          endpoint: `organizations`,
          ext_req: {method: "post", body: humps.decamelizeKeys(data) },
          schema: Schemas.user
        }
      }
    )
  }
};
export const entitiesCreateQuestion = (data) => {
  return (dispatch, getState) => {
    return dispatch(
      {
        ['CALL_API']: {
          types: [ "GET_REQUEST", "ENTITIES_UPDATE", "GET_FAILURE" ],
          endpoint: `questions`,
          ext_req: {method: "post", body: humps.decamelizeKeys(data) },
          schema: Schemas.question
        }
      }
    )
  }
};

export const entitiesSubmitQuiz = (data) => {
  return (dispatch, getState) => {
    const parsed = queryString.parse(window.location.search);
    if (parsed.o) {
      data.organizationId = parsed.o
    }
    return dispatch(
      {
        ['CALL_API']: {
          types: [ "GET_REQUEST", "ENTITIES_SUBMIT_QUIZ", "GET_FAILURE" ],
          endpoint: `quiz_instances`,
          ext_req: {method: "post", body: humps.decamelizeKeys(data) },
          schema: Schemas.quizInstance
        }
      }
    )
  }
};
