import React from 'react'
import {
  Link
} from "react-router-dom";
import moment from 'moment'
import { connect } from 'react-redux'

class TeamSessionsList extends React.Component {
  render() {
    const {organizations, users, organizationIds} = this.props
    if (!organizations) return null
    return <div>
      <table className="table">
        <thead>
          <tr>
            <th>Session Name</th>
            <th>Quizzes Taken</th>
            <th>Created At</th>
            <th>Active / Expired</th>
            <th>Days Active</th>
          </tr>
        </thead>
        <tbody>
          {organizationIds.map((oId) => {
            const organization = organizations[oId]
            let expiresOn = organization.expiresOn
            return <tr key={oId}>
              <td>
                <Link to={organization.slug}>{ organization.name }</Link>
              </td>
              <td>
                {organization.quizInstances.length}
              </td>
              <td>
                { moment(organization.createdAt).format("LLLL") }
              </td>
              <td>
                {organization.expired ? "Expired" : "Active"}
              </td>
              <td>
                {organization.activeForDays}
              </td>
            </tr>
          })}
        </tbody>
      </table>
    </div>
  }
}
function mapStateToProps(state, props) {
  const {currentUser} = state.currentUser
  const {accounts, users, organizations} = state.entities
  return {
    currentUser,
    users,
    organizations
  }
}

export default connect(mapStateToProps, {
})(TeamSessionsList)
