import React from 'react'
import { connect } from 'react-redux'
import NavUser from "../../components/NavUser"
import {
  Switch,
  Route,
} from "react-router-dom";
import Results from "./Results"
import Tasks from "./Tasks"
import Trainings from "./Trainings"
import Training from "./Training"
import Profile from "./Profile"
import Departments from "./Departments"
import Department from "./Department"
import LeftNav from "../../components/LeftNav"
import logo from "../../assets/images/small-logo.jpg"
import colors from "../../constants/colors"

class UserIndex extends React.Component {

  constructor() {
    super()
    this.state = {
      loaded: false,
      height: this.height()
    }
    this.resize = this.resize.bind(this)
  }
  componentDidMount() {
    this.resizeListener = window.addEventListener("resize", this.resize)
    // this.props.accountsFetchUsers().then(() => {
      this.setState({loaded: true})
    // })
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize)
  }
  resize() {
    this.setState({height: this.height()})
  }
  height() {
    return window.innerHeight - 80
  }
  render() {
    const {user, account} = this.props
    const navItems = [
      // {
      //   name: "Profile",
      //   icon: "fa-user",
      //   exactUrl: "/u",
      //   url: "/u"
      // },
      {
        name: "Quiz Results",
        icon: "fa-list",
        // exactUrl: "/u/results",
        exactUrl: "/u",
        url: "/u"
      },
    ]
    if (user.departments && user.departments.length > 0) {
      navItems.push({
        name: "Departments",
        icon: "fa-users",
        url: "/u/departments"
      })
    }
    if (account) {
      navItems.push({
        name: account.name + " Account",
        icon: "fa-users-cog",
        color: colors.account,
        url: "/account"
      })
    }
    if (user.role == "admin") {
      navItems.push({
        name: "Site Admin",
        icon: "fa-user",
        color: colors.admin,
        url: "/admin"
      })
    }
    return <LeftNav navItems={navItems}>
      <div style={{width: "100%"}}>
        <div className="container">
          <Switch>
            <Route exact path="/u/trainings" component={Trainings} />
            <Route exact path="/u/trainings/:trainingId" component={Training} />
            <Route exact path="/u/tasks" component={Tasks} />
            <Route exact path="/u/results" component={Results} />
            <Route exact path="/u/departments" component={Departments} />
            <Route exact path="/u/departments/:slug" component={Department} />
            <Route exact path="/u" component={Results} />
          </Switch>
        </div>
      </div>
    </LeftNav>
  }
}

const mapStateToProps = (state, props) => {
  const {currentUser: { currentUser }, entities: {accounts}} = state
  let account = null
  if (currentUser && currentUser.account) {
    account = accounts[currentUser.account]
  }
  return {
    user: currentUser,
    account
  }
}

export default connect(mapStateToProps, {
})(UserIndex)
