import  mixpanel from 'mixpanel-browser'


class Mixpanel {
  constructor() {
    this.mixpanel = null
    if (!window.location.host.match("localhost")) {
      this.mixpanel = mixpanel
      mixpanel.init("5aa50ffae839a4c1e95a81e0769c9f1b")
      mixpanel.track("Page Load")
    }
  }
  identify(inp, options) {
    if (!this.mixpanel) return
    this.mixpanel.identify(inp, options)
  }
  page() {
    if (!this.mixpanel) return
    this.mixpanel.track("Page", {location: window.location.pathname})
  }
  track(inp, options) {
    if (!this.mixpanel) return
    this.mixpanel.track(inp, options)
  }
}
export default new Mixpanel()
