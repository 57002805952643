import React from 'react'
import Role from "../components/Role"
import { connect } from 'react-redux'
import { entitiesGetQuizInstance } from "../actions/entities"
import {RadarChart, PolarGrid, PolarAngleAxis, Radar, Legend, PolarRadiusAxis} from "recharts"
import RoleTable from "../components/RoleTable"
import RoleChart from "../components/RoleChart"
import StrongestWeakest from "../components/StrongestWeakest"
import {parseQuery} from "../utils/queryParams"
import {
  Link
} from "react-router-dom";
import { roleUrls } from "../components/roles/Main"
import CoachingLink from "../components/CoachingLink"
import RoleCompareChart from "../components/RoleCompareChart"
import colors from "../constants/colors"
import roles from "../utils/roles"
import mixpanel from '../utils/mixpanel'
  
class QuizInstance extends React.Component {
  constructor() {
    super()
    this.state = {
      data: null,
      loaded: false
    }
  }

  componentDidMount() {
    mixpanel.page()
    const {qis} = this.props
    window.scrollTo(0,0)
    Promise.all(qis.map((token) => {
      return this.props.entitiesGetQuizInstance(token)
    })).then(() => {
      setTimeout(() => {
        this.setState({loaded: true})
      }, 1)
    })
  }

  totalPoints() {
  }
  render() {
    const {loaded} = this.state
    const {quizInstances} = this.props
    if (!loaded) return <div className="text-center">Loading...</div>
    if (quizInstances.filter((qi) => !qi).length !== 0) return <div className="text-center">Error Loading...</div>
    return <div className="container">
        <h3 className="text-center" style={{marginTop: "30px", marginBottom: "30px"}}>Compare Quiz Results</h3>
        <div className="card-lg">
          <h4 className="text-center">Summary</h4>
          <RoleCompareChart quizInstances={quizInstances}/>
        </div>
        {roles.map((role) => {
          return <div key={role.name} style={{marginTop: "30px"}} className="card-lg" >
            <h4 className="text-center">{role.name}</h4>
            <RoleCompareChart roleKey={role.name} quizInstances={quizInstances}/>
          </div>
        })}
        

    </div>
  }
}

const mapStateToProps = (state, props) => {
  const query = parseQuery()
  const qis = query.qis || []
  const {match: {params: {token}}} = props
  const {entities: {quizInstances}} = state

  return {
    query,
    qis: qis,
    quizInstances: qis.map((q) => quizInstances[q])
  }
}

export default connect(mapStateToProps, {
  entitiesGetQuizInstance
})(QuizInstance)
