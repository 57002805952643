import _ from "lodash"

const merge = (state, other) => {
  return _.mergeWith({}, state, other, (a, b) => {
      if (b && b.constructor === Array) {
        return b
      }
    })

} 

function shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}
const defaultState = () => {
  return {
    questions: {},
    quizzes: {},
    summaryGroups: {},
    possibleAnswers: {},
    quizInstances: {},
    mainQuiz: null,
    roleSummaries: {},
    personas: {},
    teams: {},
    companies: {},
    departments: {},
    accounts: {},
    quizInstanceIds: [],
    quizInstances: {},
    accounts: {},
    users: {},
    quizPurchases: {},
    userOrganizations: {},
    organizations: {},
    organizationUsers: {},
    trainings: {},
    challenges: {},
    admins: {}
  }
}

const actions = {
  ENTITIES_SUBMIT_QUIZ: (state, action) => {
    const newState = merge(state, action.response.entities)
    return newState
  },
  ENTITIES_GET_DATA: (state, action) => {
    const newState = merge(state, action.response.entities)
    return newState
  },
  ENTITIES_UPDATE: (state, action) => {
    const newState = merge(state, action.response.entities)
    return newState
  },
  ENTITIES_GET_QUIZ: (state, action) => {
    const newState = merge(state, action.response.entities)
    return newState
  },
  ENTITIES_GET_QUIZ_INSTANCES: (state, action) => {
    const newState = merge(state, action.response.entities)
    console.log({action})
    newState.quizInstanceIds = action.response.result
    return newState
  },
  ENTITIES_GET_QUIZ_INSTANCE: (state, action) => {
    const newState = merge(state, action.response.entities)
    return newState
  },
  ENTITIES_GET_MAIN_QUIZ: (state, action) => {
    const newState = merge(state, action.response.entities)
    newState.mainQuiz = action.response.result
    const quiz = newState.quizzes[newState.mainQuiz]
    shuffle(quiz.questions)
    return newState
  }
}
export default function entities(state = defaultState(), action) {
  if (action.type.match(/^ENTITIES_/) || action.type === "CURRENT_USER_SUCCESS") {
    if (action.type == "CURRENT_USER_SUCCESS") {
      return actions. ENTITIES_GET_DATA(state, action)
    } else if (actions[action.type]) {
      return actions[action.type](state, action)
    } else {
      console.error("Action not included in entities reducer", action)
    }
  }

  return state
}
