import {  schema } from 'normalizr';

const persona = new schema.Entity('personas', {}, {
});

const question = new schema.Entity('questions', {}, {
});

const user = new schema.Entity('users', {}, {
});


const userOrganization = new schema.Entity('userOrganizations', {}, {
});

const organizationUser = new schema.Entity('organizationUsers', {}, {
});

const organization = new schema.Entity('organizations', {}, {
});

const summaryGroup = new schema.Entity('summaryGroups', {}, {
});

const roleSummary = new schema.Entity('roleSummaries', {}, {
});

const course = new schema.Entity('courses', {}, {
});

const possibleAnswer = new schema.Entity('possibleAnswers', {}, {
});

const quizPurchase = new schema.Entity('quizPurchases', {}, {
});

const quiz = new schema.Entity('quizzes', {}, {
});

const account = new schema.Entity('accounts', {}, {
});

const company = new schema.Entity('companies', {}, {
});

const department = new schema.Entity('departments', {}, {
});

const team = new schema.Entity('teams', {}, {
});

const admin = new schema.Entity('admins', {}, {
});

const quizInstance = new schema.Entity('quizInstances', {}, {
  idAttribute: 'token'
});

const training = new schema.Entity('trainings', {}, {
});

const challenge = new schema.Entity('challenges', {}, {
});


summaryGroup.define({
  roleSummaries: [roleSummary],
})

training.define({
  challenges: [challenge],
  persona
})

company.define({
  departments: [department]
})

department.define({
  organizations: [organization],
  company
})

user.define({
  userOrganizations: [userOrganization],
  account: account
})


userOrganization.define({
  organization
})

organizationUser.define({
  user
})

account.define({
  users: [user],
  organizations: [organization]
})

organization.define({
  organizationUsers: [organizationUser],
  quizInstances: [quizInstance]
})
quiz.define({
  personas: [persona],
  questions: [question],
  possibleAnswers: [possibleAnswer],
});
admin.define({
  personas: [persona],
});

export const Schemas = {
  questions: [question],
  quiz,
  question,
  organization,
  user,
  userOrganization,
  userOrganizations: [userOrganization],
  organizations: [organization],
  organization,
  persona,
  quizInstance,
  quizInstances: [quizInstance],
  quizzes: [quiz],
  persona,
  quizPurchase,
  personas: [persona],
  account,
  team,
  trainings: [training],
  courses: [course],
  company,
  companies: [company],
  department,
  departments: [department],
  summaryGroup,
  summaryGroups: [summaryGroup],
  roleSummaries: [roleSummary],
  roleSummary: roleSummary,
  account,
  admin
};
