import React from "react"
import { Link } from 'react-router-dom'
import _ from "lodash"
import { connect } from 'react-redux'
import colors from "../constants/colors"

const snc = {
  backgroundColor: "white",
  borderRight: "1px solid rgba(1, 1, 1, 0.1)",
  boxShadow: "0 1px 3px rgb(13 26 44 / 16%), 0 15px 25px rgb(13 26 44 / 24%)",
  overflow: "hidden",
  top: 0,
  left: 0
}
const hc = {
  borderBottom: "1px solid rgba(1,1,1,.1)",
  padding: "10px",
  marginBottom: "5px",
}


function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

class NavUser extends React.Component {
  constructor(props) {
    super()

    this.state = {
    }
  }

  key(props) {
    return 'side-nav-' + props.keepable
  }
  renderNavItem({ name, admin, icon, url, exactUrl }) {
    let regexp = `^${escapeRegExp(url)}`
    const pn = window.location.pathname
    let selected = pn.match(regexp)
    if (exactUrl) selected = pn === exactUrl
    let style = {}
    if (admin) {
      style = {color: colors.main.primary}
    }
    return <Link key={name} to={exactUrl || url}>
      <div style={{padding: "10px 5px", position: "relative", backgroundColor: selected ? "rgba(1,1,1,.05)" : "transparent", cursor: "pointer", borderBottom: "1px solid rgba(1,1,1,.1)"}}
        className="text-center">
          <button  className="btn btn-link" style={{ color: "rgba(1,1,1,.8)", padding: 0}}>
            <div style={{}}>
              <i className={`fas ${icon}`} style={{fontSize: "20px"}}></i>
            </div>
            <div style={style}>
            {name}
          </div>
        </button>
      </div>
    </Link>
  }

  render() {
    // if (!this.state.expanded) return this.renderCollapsed()
    const {account} = this.props
    const staticNav = this.props.static
    const baseZIndex = 10
    const style = {
      height: this.props.height,
      display: "flex",
      flexDirection: "column"
    }
    const childStyle = {
      flexDirection: "row",
      flex: 1
    }
    if (!staticNav) {
      childStyle.overflowY = "scroll"
    }
    let containerStyle = {
      zIndex: baseZIndex + this.props.baseIndex,
      width: "110px"
    }
    if (this.props.style) {
      containerStyle = _.merge(containerStyle, this.props.style)
    }
    if (this.props.hide && this.state.keep === false) return null

    const navItems = [
      {
        name: "Profile",
        icon: "fa-cog",
        exactUrl: "/u",
        url: "/u"
      },
      {
        name: "Quiz Results",
        icon: "fa-poll-h",
        url: "/u/quizzes"
      },
      {
        name: "Roles Training",
        icon: "fa-atom",
        url: "/u/trainings"
      },
    ]
    if (account && account.accountType == "licensee") {
      navItems.push({
        admin: true,
        name: "My Team Sessions (Admin)",
        icon: "fa-users",
        url: "/u/team_sessions"
      })
      navItems.push({
        admin: true,
        name: "Account Sessions (Admin)",
        icon: "fa-users",
        url: "/u/account"
      })
    }

    return <div style={{...snc, ...(this.props.hide ? {display: "none"} : containerStyle)}}>
        <div style={style}>
        <div style={childStyle}>

          { navItems.map((item) => {
              return this.renderNavItem(item) 
            })
          }

        </div>
    </div>
    </div>
  }
}
NavUser.defaultProps = {
  baseIndex: 0
}

const mapStateToProps = (state, props) => {
  const {
    users,
    userOrganizations,
    organizations,
    quizzes,
    accounts,
  } = state.entities
  const {
    email,
    currentUser
  } = state.currentUser
  if (!currentUser) {
    return {
      email
    }
  }
  const user = users[currentUser.id]
  if (!user) {
    return {
      email
    }
  }
  return {
    email,
    user,
    currentUser,
    account: accounts[currentUser.account]
  }
}
export default connect(mapStateToProps, {
})(NavUser)
