import React from 'react'
import { connect } from 'react-redux'
import {
  Link
} from "react-router-dom";
import BreadCrumbs from "../../components/BreadCrumbs"
import {entitiesGetTrainings, entitiesGetPersonas} from "../../actions/entities"
import roles, { findRole }  from "../../utils/roles"
import ReactPlayer from 'react-player'

const training = {url: 'https://player.vimeo.com/video/581911857?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479', id: 1}

class Trainings extends React.Component {
  constructor() {
    super()
    this.state = {
    }
  }
  render() {
    const {trainingId} = this.props

    const crumbs = [
      {name: "Trainings", url: "/u/trainings"},
      {name: "Training " + trainingId},
    ]

    return <div>
      <BreadCrumbs crumbs={crumbs}/>
      <div style={{paddingLeft: "15px"}}>
        <div style={{marginBottom: "15px"}}>
          <ReactPlayer controls url={training.url} width= "100%" height="800px"/>
        </div>
      </div>
    </div>
  }
}

const mapStateToProps = (state, props) => {
  const {trainings, personas, roles} = state.entities
  const {trainingId} = props.match.params

  return {
    trainings,
    personas,
    roles,
    trainingId
  }
}

export default connect(mapStateToProps, {
  entitiesGetTrainings, entitiesGetPersonas
})(Trainings)

