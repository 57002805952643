import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import {
  Link
} from "react-router-dom";
import {entitiesGetDepartment} from "../../actions/entities"
import TeamSessionNew from '../../components/accounts/TeamSessionNew'
import TeamSessionsList from "../../components/TeamSessionsList"
import BreadCrumbs from "../../components/BreadCrumbs"

class Department extends Component {
  constructor() {
    super()
    this.state = {
      name: "",
    }
  }
  componentDidMount() {
    this.fetch()
  }
  fetch() {
    this.props.entitiesGetDepartment(this.props.departmentId).then((action) => {
    })
  }
  // create() {
  //   this.setState({saving: true})
  //   this.props.entitiesCreateDepartment({name: this.state.name, companyId: this.props.companyId}).then(() => {
  //     this.setState({saving: false, name: ""})
  //     this.fetchCompany()
  //   })
  // }
  render() {
    const {departmentId, companies, department} = this.props
    const {saving} = this.state
    if (!department || !department.company) return <div>Loading...</div>
    const company = this.props.companies[department.company]
    const crumbs = [
      {name: "Company - " + company.name, url: company.accountSlug},
      {name: "Department - " + department.name},
  ]
    return <div>
      <BreadCrumbs crumbs={crumbs}/>
      <div>
        <TeamSessionNew
          departmentId={department.id}
          onCreate={() => {
            this.fetch()
          }}
        />
    </div>
      <TeamSessionsList organizationIds={department.organizations}/>
  </div>
  }
}

function mapStateToProps(state, props) {
  const {match: {params: {idString}}} = props
  const {departments, companies} = state.entities
  const id = idString.split("-")[0]
  return {
    companies,
    departmentId: id,
    department: departments[id]
  }
}

export default connect(mapStateToProps, {
  entitiesGetDepartment,
  // entitiesCreateDepartment,
  // entitiesCreateDepartment
})(Department)
