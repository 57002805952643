import React, { Component } from 'react'
import { connect } from 'react-redux'
import Role from "../components/Role"
import roles from "../utils/roles"
import {parseQuery} from "../utils/queryParams"
import TakeQuiz from "../components/TakeQuiz"
import mixpanel from '../utils/mixpanel'

class TheRoles extends Component {
  componentDidMount() {
    mixpanel.page()
  }
  render() {
    const {name} = this.props
    const query = parseQuery()
    return <div className="container" style={{marginTop: "15px"}}>
      <div className="roles-detail-grid">
        {
          roles.map((role) => {
            return <Role key={role.name} roleName={role.name} detail all={query.type == "detail"}/>
          })
        }
      </div>
      <div className="text-center" style={{margin: "80px 0"}}>
        <TakeQuiz/>
      </div>
    </div>
  }
}

function mapStateToProps(state, props) {
  const {match: {params: {name}}} = props
  return {
    name
  }
}
export default connect(mapStateToProps, {
})(TheRoles)
