import React from 'react'
import { connect } from 'react-redux'
import {currentUserLogout} from "../actions/currentUser"
import Cookies from 'cookies-js';
import mixpanel from '../utils/mixpanel'
class Logout extends React.Component {
  componentDidMount() {
    mixpanel.page()
    if (this.props.auth) {
      this.props.currentUserLogout().then(() => {
        Cookies.set('auth', null, {
          secure: window.location.host.match("localhost") || window.location.host.match("192.168.1.115")  ? false : true ,
          expires: 1
        })

      })
    }
    setTimeout(() => {
      if (window.location.pathname === "/logout") {
        window.location = "/"
      }
    },3000)
  }

  render() {
    return <div>
      <h4 className="text-center" style={{marginTop: "100px", fontWeight: "normal"}}>Thanks for visiting</h4>
    </div>
  }
}

const mapStateToProps = (state, props) => {
  const {
    auth
  } = state.currentUser
  return {
    auth
  }
}

export default connect(mapStateToProps, {
  currentUserLogout
})(Logout)
