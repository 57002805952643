import { normalize } from 'normalizr';
import { camelizeKeys } from 'humps';
import {Schemas} from "../middleware/schemas";
import roles from "../utils/roles"

const trainings = []

const categories = [
  "At Work",
  "At Home"
]
const tt = [
  1, 5, 10,
]

roles.forEach((role) => {
  tt.forEach((difficulty) => {
    categories.forEach((cat) => {
      const challenges = []

      const count = 25
      for (var i = 0; i < count; i++) {
        const id = i + 1
        challenges.push({
          id: id,
          name: `Challenge ${id} title`,
          description: "Challenge description goes here. I think you should walk on a grid in new york. "
        })
      }
      trainings.push({
        personaId: role.id,
        id: role.id + "-" + difficulty + "-" + cat,
        difficulty,
        name: cat, difficulty,
        challenges, challengesCount: count,
        challengesComplete: difficulty == 1 && role.name == "Luminary" ? count : 0
      })
    })
  })
})

export const personas = normalize(roles, Schemas.personas)

export default normalize(trainings, Schemas.trainings)
