import _ from "lodash"
import mixpanel from '../utils/mixpanel'

const merge = (state, other) => {
  return _.mergeWith({}, state, other, (a, b) => {
    if (b && b.constructor === Array) {
      return b
    }
  })
} 

const defaultState = () => {
  return {
    message: null
  }
}

const actions = {
  ERRORS_CLEAR: (state, action) => {
    return defaultState()
  },
  ERRORS_API: (state, action) => {
    const newState = merge(state, {message: action.error})
    return newState
  }
}
export default function errors(state = defaultState(), action) {
  if (action.type.match(/^ERRORS_/)) {
    if (actions[action.type]) {
      return actions[action.type](state, action)
    } else {
      console.error("Action not included in errors reducer", action)
    }
  }

  return state
}
