import React from 'react'
import mixpanel from '../utils/mixpanel'
import colors from "../constants/colors"

const url = "https://calendly.com/c/FDCUU57QOUHENGN6?month=2020-11&date=2020-11-30"

export default ({}) => {
  return <a 
    className="btn"
    style={{verticalAlign: "middle", borderRadius: "50px", backgroundColor: colors.primary, color: "white", border: "none", fontWeight: "bold", width: "220px", padding: "7px"}}
    target="_blank" rel="noopener noreferrer"
    href={url}
    onClick={() => {
      mixpanel.track("Coaching Link", {
        url: window.location.pathname
      })
    }}
  >
    <i style={{marginRight: "10px", fontSize: "15px"}} className="fas fa-users" aria-hidden="true"></i>
    ROLES COACHING
  </a>
}
