import {addQueryParams} from "./urls"
export const userAnswersAnswer = (questionId, answerId) => {
  return (dispatch, getState) => {
    
    dispatch({
      type: "USER_ANSWERS_ANSWER",
      response: {
        questionId, answerId
      }
    })
    const answers =  getState().userAnswers.answers
    return dispatch(addQueryParams({answers: JSON.stringify(answers)}))
  }
}
