import React, { Component } from 'react'
import { connect } from 'react-redux'
import { errorsClear } from "../actions/errors"

class Errors extends Component {
  constructor() {
    super()
    this.state = {
      timer: 5000
    }
    this.unmounted = false
  }
  componentDidMount() {
    this.tickTime()
  }
  componentWillUnmount() {
    this.unmounted = true
  }
  tickTime() {
    setTimeout(() => {
      if (this.state.timer === 0) {
        if (!this.unmounted) {
          this.props.errorsClear()
        }
      } else {
        if (!this.unmounted) {
          this.setState({timer: this.state.timer - 1000}, () => {
              this.tickTime()
          })
        }
      }
    }, 1000)
  }
  render() {
    return <div className="alert alert-danger" style={{marginBottom: 0, display: "flex"}}>
      <div style={{flex: 1}}>An error occurred: {this.props.message}</div>
      <div style={{padding: "0 15px"}}>Hidden in {this.state.timer / 1000} seconds</div>
      <div>
        <button className="close" onClick={() => {
          this.props.errorsClear()
        }}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  }
}

const mapStateToProps = (state, props) => {
  return {
    message: state.errors.message
  }
}
export default connect(mapStateToProps, {
  errorsClear
})(Errors)
