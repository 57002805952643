import React from 'react'
import { connect } from 'react-redux'
import moment from "moment"
import {
  Link
} from "react-router-dom";
import { entitiesGetQuizzes, entitiesGetOrganizations , entitiesCreateOrganization} from "../../actions/entities"
import mixpanel from '../../utils/mixpanel'
import TeamSessionNew from '../../components/accounts/TeamSessionNew'

class Organizations extends React.Component {

  constructor() {
    super()
    this.state = {
    }
  }
  componentDidMount() {
    mixpanel.page()
    this.props.entitiesGetOrganizations()
  }
  render() {
    const {
      email, user,
      userOrganizations,
      organizations
    } = this.props
    if (!email) return <div className="text-center" style={{marginTop: "15px"}}>You need to <a href="/login">login</a> to view this page</div>
    if (!user || !user.userOrganizations) return <div className="text-center">Loading...</div>

    return <div >
        <h4 style={{marginTop: "15px"}}>Team Sessions</h4>
        <TeamSessionNew onCreate={() => {
        }}/>
        <div className="card">
          <div className="card-header">
            Team Sessions
          </div>
          <div className="card-body">
            { user.userOrganizations.length === 0 &&
              <p style={{fontWeight: "bold"}}>You do not currently have access to any team sessions.</p>
            }
            { user.userOrganizations.length > 0 &&
              <table className="table">
                <thead>
                  <tr>
                    <th>Session Name</th>
                    <th>Quizzes Taken</th>
                    <th>Created At</th>
                    <th>Active / Expired</th>
                    <th>Days Active</th>
                  </tr>
                </thead>
                <tbody>
                  {user.userOrganizations.map((uoId) => {
                    const uo = userOrganizations[uoId]
                    const organization = organizations[uo.organization]
                    let expiresOn = organization.expiresOn
                    return <tr key={uoId}>
                      <td>
                        <Link to={organization.slug}>{ organization.name }</Link>
                      </td>
                      <td>
                        {organization.quizInstances.length}
                      </td>
                      <td>
                        { moment(organization.createdAt).format("LLLL") }
                      </td>
                      <td>
                        {organization.expired ? "Expired" : "Active"}
                      </td>
                      <td>
                        {organization.activeForDays}
                      </td>
                    </tr>
                  })}
                </tbody>
              </table>
            }
          </div>
        </div>
      </div>
  }
}

const mapStateToProps = (state, props) => {
  const {
    users,
    userOrganizations,
    organizations,
    quizzes
  } = state.entities
  const {
    email,
    currentUser
  } = state.currentUser
  if (!currentUser) {
    return {
      email
    }
  }
  const user = users[currentUser.id]
  return {
    email,
    user,
    userOrganizations,
    organizations,
    quizzes
  }
}

export default connect(mapStateToProps, {
  entitiesGetOrganizations,
  entitiesCreateOrganization,
  entitiesGetQuizzes
})(Organizations)
