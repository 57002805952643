import _ from "lodash"
import { parseQuery } from "../utils/queryParams"
// import fake from "../fake.json"
const merge = (state, other) => {
  return _.mergeWith({}, state, other, (a, b) => {
      if (b && b.constructor === Array) {
        return b
      }
    })

} 

const defaultState = () => {
  // return fake
  const parsed = parseQuery()
  const answers = parsed.answers ? JSON.parse(parsed.answers) : {}
  return {
    answers
  }
}

const actions = {
  USER_ANSWERS_ANSWER: (state, action) => {
    const {questionId, answerId} = action.response
    const newState = merge(state, {})
    newState.answers[questionId] = answerId
    return newState
  }
}
export default function userAnswers(state = defaultState(), action) {
  if (action.type.match(/^USER_ANSWERS/)) {
    if (actions[action.type]) {
      return actions[action.type](state, action)
    } else {
      console.error("Action not included in user answers reducer", action)
    }
  }

  return state
}
