import React from 'react'
import { connect } from 'react-redux'
import { entitiesUpdateQuestion, entitiesGetQuiz } from "../../actions/entities"

const statuses = [
  "draft",
  "uncalculated",
  "calculated",
  "archived",
]
class Question extends React.Component {
  constructor(props) {
    super()
    this.state = this.defaultState(props)
  }
  defaultState(props) {
    const secondaryScores = {}
    return {
      personaId: null,
      question: null,
      order_key: null,
      secondaryScores: props.question.secondaryScores,
      reverseCalculate: props.question.reverseCalculate,
      status: null
    }
  }

  edited() {
    return Object.values(this.state).filter((i) => i).length > 0
  }
  updateQuestion() {
    const {question, quiz} = this.props
    if (this.state.status === "archived") {
      if (!window.confirm(`Are you sure you would like to archive "${question.question}"? It will remove it.`)) {
        return
      }
    }
    this.props.entitiesUpdateQuestion(question.id, this.state).then(() => {
      setTimeout(() => {
        this.props.entitiesGetQuiz(quiz.id).then(() => {
          this.setState(this.defaultState(this.props))
        })
      },1)
    })
  }
  currentNumber() {
    const ok = this.state.order_key
    if (ok !== null) return ok
    return this.props.number
  }
  color(status) {
    if (status === "draft") return "rgba(250,218,94,.3)"
    if (status === "uncalculated") return "rgba(0,255,0,.3)"
    if (status === "calculated") return "white"
    if (status === "archived") return "rgba(255,0,0,.3)"
    return "red"
  }
  render() {
    const {question, personas, number, quiz} = this.props
    if (!question) return null
    return <div >
      <div className="card" style={{marginTop: "15px"}}>
        <div className="card-body" style={{backgroundColor: this.color(question.status)}}>
          <div style={{display: "flex"}}>
            <div style={{flex: 1, display: "flex"}}>
              <div style={{marginRight: "15px", marginTop: "5px"}}>Question</div>
              <input className="form-control" type="number" style={{width: "100px"}} value={this.currentNumber()} onChange={(e) => {
                const val = e.target.value
                if (val === number) {
                  this.setState({
                    order_key: null
                  })

                } else {
                  this.setState({
                    order_key: val
                  })
                }
              }}/>
            </div>
            <div style={{}}>
              <select className="form-control" defaultValue={question.status} onChange={(e)=> {
                  const status = e.target.value
                  if (question.status !== status) {
                    this.setState({status})
                  } else {
                    this.setState({status: null})
                  }
                }}>
                {
                  statuses.map((status) => {
                    return <option key={status} value={status}>{status}</option>
                  })
                }
              </select>
            </div>
          </div>
          <div style={{marginTop: "15px"}}>
            <textarea onChange={(e) => {
              const val = e.target.value
              if (val == question.question) {
                this.setState({
                  question: null
                })
              } else {
                this.setState({
                  question: e.target.value
                })
              }
            }} className="form-control" placeholder="Question" value={this.state.question || question.question}/>
            <div style={{marginTop: "15px"}}>
              <input type="checkbox" onChange={() => {
                this.setState({reverseCalculate: !this.state.reverseCalculate})

              }} checked={this.state.reverseCalculate}/> Reverse Calculate
            </div>
            <div style={{marginTop: "15px", maxWidth: "600px"}}>
              <select className="form-control" defaultValue={question.personaId} onChange={(e)=> {
                  const personaId = e.target.value
                  if (question.personaId !== personaId) {
                    this.setState({personaId})
                  } else {
                    this.setState({personaId: null})
                  }
                }}>
                {
                  quiz.personas.map((pid) => {
                    const persona = personas[pid]
                    return <option key={persona.id} value={persona.id}>{persona.name}</option>
                  })
                }
              </select>
            </div>
          </div>
          <div style={{marginTop: "15px"}}>
            Scores: (Max 1 and Min -1)
            <div style={{display: "flex",flexWrap: "wrap"}}>
              {
                quiz.personas.map((pid) => {
                  const persona = personas[pid]
                  return <div key={persona.id} value={persona.id} style={{minWidth: "300px"}}>
                    {persona.name}
                    <input value={this.state.secondaryScores[persona.id]} onChange={(e) => {
                      let nv = Math.min(parseFloat(e.target.value),1)
                      nv = Math.max(nv, -1)
                      let secondaryScores = JSON.parse(JSON.stringify(this.state.secondaryScores))
                      if (window.isNaN(nv)) nv = ""
                      secondaryScores[persona.id] = nv
                      this.setState({secondaryScores})
                    }} className="form-control" type="number"/>
                    </div>
                })
              }
            </div>
          </div>
          {this.edited() &&
            <div className="text-right">
              <button onClick={() => {
                this.updateQuestion()
              }} className="btn btn-success">Save</button>
            </div>
          }
        </div>
      </div>
    </div>
  }
}

const mapStateToProps = (state, props) => {
  const {personas} = state.entities
  return {
    personas,
  }
}

export default connect(mapStateToProps, {
  entitiesUpdateQuestion,
  entitiesGetQuiz
})(Question)
