import { push } from 'connected-react-router'
import _ from "lodash"
import {encodeQuery, parseQuery} from "../utils/queryParams"

export const addQueryParams = (q, url) => {
  return (dispatch, getState) => {
    const parsed = parseQuery()
    const d = _.merge(parsed, q)
    const query = encodeQuery(d)

    dispatch(push({
      pathname: url || window.location.pathname,
      search: query
    }))
  }
}

export const changeUrl = (query) => {
  return (dispatch, getState) => {
    const q = encodeQuery(query)

    dispatch(push({
      pathname: window.location.pathname,
      search: q
    }))
  }
}

export const clearParams = () => {
  return (dispatch, getState) => {
    dispatch(push({
      pathname: window.location.pathname,
      search: "?"
    }))
  }
}

