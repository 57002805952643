import humps from "humps"
import queryString from 'query-string';

export const encodeQuery = (params) => {
  return queryString.stringify(humps.decamelizeKeys(params), {arrayFormat: 'bracket'})
}

export const parseQueryString = (search) => {
  if (!search) search = window.location.search

  const resp = humps.camelizeKeys(queryString.parse(search, {arrayFormat: 'bracket'}))
  return resp
}

export const parseHash = () => {
  const {hash} = window.location
  return parseQueryString(hash.replace("#", "?"))
}

export const parseQuery = () => {
  const {search} = window.location
  return parseQueryString(search)
}
