import React from 'react'
import {
  Link
} from "react-router-dom";
import { roleUrls } from "../components/roles/Main"
import colors from "../constants/colors"

class RoleTable extends React.Component {
  render() {
    const {orderedRoles, size} = this.props
    let fontSize = "16px"
    if (size == "sm") fontSize = "12px"
    

    return <table className="table" style={{border: "1px solid rgba(1,1,1,.1)", fontSize}}>
      <thead>
        <tr>
          <th>Rank</th>
          <th>Type</th>
          <th>Score</th>
        </tr>
      </thead>
      <tbody>
        { orderedRoles.sort((a, b) => {
          if (a.percent < b.percent) return 1
          if (a.percent > b.percent) return -1
          return 0

        }).map((item, i) => {
            return <tr key={item.name}>
              <td>{i + 1}</td>
              <td><Link to={`/roles/${roleUrls[item.name]}?type=detail`}>{item.name}</Link></td>
              <td>{item.percent}</td>
            </tr>
          })
        }
      </tbody>
    </table>
  }
}

export default RoleTable
