import img0 from "../assets/images/broll/nancy.jpg"
import img1 from "../assets/images/broll/action.jpg"
import img2 from "../assets/images/broll/camera.jpg"
import img3 from "../assets/images/broll/ek-coffee.jpg"
import img4 from "../assets/images/broll/es-laughing.jpg"
import nancy from "../assets/images/broll/nancy.jpg"
import kevin from "../assets/images/broll/kevin.jpg"
import erin from "../assets/images/broll/erin.jpg"
import ingrid from "../assets/images/broll/ingrid.jpg"
import sydney from "../assets/images/broll/sydney.jpg"
import meaghan from "../assets/images/broll/meaghan.jpg"
import colors from '../constants/colors'
import Section from "../components/Section"
import mixpanel from '../utils/mixpanel'

import React, { Component } from 'react'
import { connect } from 'react-redux'

const PicturesBoard = () => {
  return<div style={{maxWidth: "950px", margin: "0 auto"}}>
    <div style={{display: "flex"}}>
      <div style={{flex: 3}}>
        <div className="image-gallery"  style={{ height: "402.5px", backgroundPosition: "center", backgroundSize: "cover", backgroundImage: `url(${img0})`}}/>
        <div className="image-gallery"  style={{ height: "402.5px", marginTop: "5px", backgroundPosition: "center", backgroundSize: "cover", backgroundImage: `url(${img1})`}}/>
      </div>
      <div style={{flex: 2, marginLeft: "5px"}}>
        <div  className="image-gallery" style={{width: "100%", backgroundPosition: "center", height: "200px", backgroundSize: "cover", backgroundImage: `url(${img4})`}}/>
        <div className="image-gallery"  style={{width: "100%", margin: "5px 0", backgroundPosition: "center", height: "400px", backgroundSize: "cover", backgroundImage: `url(${img2})`}}/>
        <div className="image-gallery" style={{width: "100%", backgroundPosition: "center", height: "200px", backgroundSize: "cover", backgroundImage: `url(${img3})`}}/>
      </div>
    </div>
  </div>
}
class AboutUS extends Component {
  componentDidMount() {
    mixpanel.page()
  }
  render() {
    return <div className="container" style={{marginTop: "40px"}}>
        <div className="row">
          <div className="col-md-12" style={{display: "flex"}}>
            <Section faClass="fa-hand-paper" header="About Us">
              <p style={{margin: "40px 0", fontSize: "18px"}}>
                We are a company that deeply cares about every person's unique contribution to a team. Whether you are new to a team or interested in building out a new team, MyTeamRole can help you both understand your strengths and specific role set you most comfortably play in a group.
              </p>
            </Section>
          </div>
        </div>
      <PicturesBoard/>
        <div className="row" style={{ marginTop: "40px"}}>
          <div className="col-md-12" style={{display: "flex"}}>
            <Section faClass="fa-atom" header="Our Methodology">
                <p style={{margin: "40px 0", fontSize: "18px"}}>
                When we use the word ‘team’ that can be any collection of people surrounding a task or goal. This could be your direct team at work, your department, your family or your extracurricular team! Each and every team with a goal in mind plays a set of 7 key roles or compartments. At MyTeamRole, we help you understand how strongly and/or comfortably you play each 7 and we provide materials for those interested in stretching into another Role. 
                This is not a personality test or a site that will tell you what you are, we help you see where you may be strong and where you might have room for growth! 
                  The Seven Roles is founded on years of scientific research that has been statistically verified as roles needed for a team.
              </p>
            </Section>
          </div>
        </div>
        <div className="row" style={{ marginTop: "40px"}}>
          <div className="col-md-12">
            <Section faClass="fa-sitemap" header="The Lumiere Sciences Team">
              <div style={{width: "100%", marginBottom: "15px"}}>
                <h2 style={{fontWeight: "bold", marginBottom: "15px"}}></h2>
                <div className="people-grid">
                  {
                    [
                      {
                        name: "Erin McConlogue",
                        title: "CEO",
                        image: erin
                      },
                      {
                        name: "Dr. Nancy McConlogue",
                        title: "Head of R&D",
                        image: nancy
                      },
                      {
                        name: "Meaghan McConlogue",
                        title: "CSO",
                        image: meaghan
                      },
                      {
                        name: "Sydney Romanov",
                        title: "Business Development & Content",
                        image: sydney
                      },
                      {
                        name: "Kevin Woods",
                        title: "Engineering and Technology",
                        image: kevin
                      },
                      {
                        name: "Ingrid Morris",
                        title: "Marketing & Facilitation",
                        image: ingrid
                      },
                    ].map((person, i) => {
                      return <div key={i}>
                        <div className="image-gallery"  style={{ margin: "0 auto", height: "150px", width: "150px", borderRadius: "50%", backgroundPosition: "center", backgroundSize: "cover", backgroundImage: `url(${person.image})`}}/>
                        <h4 className="text-center" style={{marginTop: "15px"}}>{person.name}</h4>
                        <p className="text-center" style={{margin: "10px 0", fontSize: "18px"}}>
                          {person.title}
                        </p>
                      </div>
                    })
                  }
                </div>
              </div>
            </Section>
          </div>
        </div>

    </div>
  }
}

function mapStateToProps(state, props) {
  return {
  }
}
export default connect(mapStateToProps, {
})(AboutUS)
