import React from 'react'
import Role from "../components/Role"
import {
  Link
} from "react-router-dom";
import { roleUrls } from "../components/roles/Main"
import colors from "../constants/colors"

class StrongestWeakest extends React.Component {
  render() {
    const {orderedRoles, quizId} = this.props

    return <div>
      <div>
        <h6>
          You Are Strongest In:
        </h6>
        <div>
          <Role quizId={quizId} persona={orderedRoles[0].persona} detail all/>
        </div>
      </div>
      <div>
        <h6 style={{marginTop: "15px"}}>
          You Are Weakest In: {quizId}
        </h6>
        <div>
          <Role quizId={quizId} persona={orderedRoles[orderedRoles.length - 1].persona} detail all/>
        </div>
      </div>
    </div>
  }
}

export default StrongestWeakest
