import React from 'react'
import { connect } from 'react-redux'
import { entitiesGetQuizInstances, entitiesAdminGetSummaryGroup } from "../../actions/entities"
import {
  Link
} from "react-router-dom";
import RoleSummary from "../../components/admin/RoleSummary"

class SummaryGroup extends React.Component {
  constructor() {
    super()
    this.state = {
    }
  }
  componentDidMount() {
    this.props.entitiesAdminGetSummaryGroup(this.props.id)
  }

  renderList(roleSummary, field) {
    const list = roleSummary[field]
    if (!list) return <div style={{color: 'red'}}>error loading {field}</div>
    return <div>{field}: <ul>
      {list.map((item, i) => {
        return <li key={i}><textarea style={{width: "100%"}} value={item}></textarea></li>
      })}
    </ul>
    </div>
  }
  render() {
    const {summaryGroup} = this.props
    if (!summaryGroup) return <div>Loading...</div>

    return <div className="container"  style={{marginBottom: "30px"}}>
      <h1>Summary Group: {summaryGroup.name} {summaryGroup.id}</h1>
      {
        summaryGroup.roleSummaries.map((rs) => {
          return <RoleSummary rsId={rs} key={rs}/>
        })
      }
    </div>
  }
}

const mapStateToProps = (state, props) => {
  const {id} = props.match.params
  const {summaryGroups} = state.entities
  return {summaryGroup: summaryGroups[id], id}
}

export default connect(mapStateToProps, {
  entitiesAdminGetSummaryGroup
})(SummaryGroup)
