import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'
// import { routerReducer } from 'react-router-redux'
import entities from './entities'
import userAnswers from './userAnswers'
import errors from './errors'
import currentUser from './currentUser'

const createRootReducer = (history) => {
  return combineReducers({
    router: connectRouter(history),
    entities,
    errors,
    userAnswers,
    currentUser
  })
}

export default createRootReducer
