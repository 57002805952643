import React from 'react'
import colors from "../constants/colors"

class Question extends React.Component {
  color() {
    const { question, answered, prompt } = this.props
    if (!answered) {
      if (prompt) return colors.main.danger
      return colors.secondary
    }

    return colors.primary
  }
  render() {
    const { question, answered, prompt } = this.props
    return <div className="card" style={{marginTop: "20px", position: "relative", overflow: "hidden"}}>
      <div className="card-body">
        <div style={{borderLeft: `4px solid ${this.color()}`, position: "absolute", left: 0, top: 0, height: "100%"}}/>
        <div className="text-left" style={{fontSize: "14px", fontWeight: "500"}}>
          <div>{question.question}</div>
        </div>
        <div>{this.props.children}</div>
      </div>
    </div>
  }
}
export default Question
