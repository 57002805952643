import React from 'react'
import { connect } from 'react-redux'
import {
  Link
} from "react-router-dom";
import moment from "moment"
import { entitiesGetOrganization, entitiesAddQuizInstanceFromOrganzation, entitiesRemoveQuizInstanceFromOrganzation, entitiesCreateOrganizationUser, entitiesToggleOrganizationActive} from "../../actions/entities"
class Show extends React.Component {
  constructor() {
    super()
    this.state = this.defaultState()
  }

  defaultState() {
    return {
      qi: "",
      qi: "",
      email: "",
      saving: false,
      team: false,
      savingAdd: false
    }
  }

  addUser() {
    this.setState({saving: true}, () => {
      this.props.entitiesCreateOrganizationUser(this.props.organization.id, {email: this.state.email}).then(() => {
        this.setState(this.defaultState())
      })
    })
  }
  isValidNewUser() {
    const {email, saving} = this.state
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  render() {
    const {
      organization,
      quizInstances
    } = this.props
    const {email, saving} = this.state

    if (!organization) return null
    let cv = "not"
    if (organization.active == true) {
      cv = "active"
      if (organization.expiresIn == "no expiration") {
        cv = "always_active"
      }
    }
    return <div>
      <h4>Team Members</h4>
      <div>
        <select style={{marginBottom: "15px"}} onChange={(e) => {
            const v = e.target.value
            const active = v.match("active") ? true : false
            const expires = v.match("always") ? "never" : null
            this.props.entitiesToggleOrganizationActive(organization.id, active, expires)
          }} value={ cv } className="form-control">
          <option value="not">Inactive</option>
          <option value="active">Active (retoggle to add 31 days)</option>
          <option value="always_active">Always Active</option>
        </select>
      </div>
      <div style={{marginBottom: "15px"}}>Expires in {organization.expiresIn}</div>
      <div className="card">
        <div className="card-header">
          Current Team Members - Quiz Type "{organization.quiz && organization.quiz.category}" - Quiz Id {organization.quiz && organization.quiz.id}
        </div>
        <div className="card-body">

          <div>Share this coupon code</div>
          <pre >
            {organization.tokenText}
          </pre>
          <div>Or</div>

          <div>Share this link with people you want to take the test</div>
          <input className="form-control" value={window.location.host + organization.quizSlug}/>
          <div style={{marginTop: "15px"}}><a href={organization.teamSlug} className="btn btn-primary">Results Page</a></div>
          <div style={{margin: "15px 0"}}>Identifier: {organization.identifier}</div>
          <div>Expires in: {organization.expiresIn}</div>
          { quizInstances.length === 0 && <div style={{marginTop: "15px"}}>No team members have taken the quiz. Share the link above with them!</div>}
          <div>Taken Quizzes: {quizInstances.length}</div>
          <table className="table">
            <thead>
              <th>
                Email
              </th>
              <th>
                Date Taken
              </th>
              <th>
                Remove
              </th>
            </thead>
            <tbody>
              { quizInstances.map((quizInstance) => {
                return <tr key={quizInstance.id} key={quizInstance.id} >
                  <td>{ quizInstance.email }</td>
                  <td><a href={`/results/${quizInstance.token}`} target="_blank">{ moment(quizInstance.createdAt).fromNow() }</a></td>
                  <td><button className="btn btn-link" style={{color: "red"}} onClick={() => {
                    if (window.confirm("Are you sure you want to remove this?")) {
                      this.props.entitiesRemoveQuizInstanceFromOrganzation(organization.id, quizInstance.token)
                    }
                  }}>remove from org</button>
                  </td>
                </tr>
              }) }
            </tbody>
          </table>
          <div style={{marginTop: "15px", display: "flex"}}>
            <input
              value={this.state.qi} onChange={(e) => {
                const val = e.target.value
                this.setState({qi: val})
              }}
              className="form-control" placeholder="Link to Quiz example: https://myteamrole.com/results/112asdfwqrq"/>
            <button
              disabled={this.state.qi.length < 1 || !this.state.qi.match("/results/")}
              onClick={() => {
                if (this.state.savingAdd) return
                this.setState({savingAdd: true}, () => {
                  const a = this.state.qi.split("/results/")
                  const result = a[a.length - 1]
                  this.props.entitiesAddQuizInstanceFromOrganzation(organization.id, result).then(() => {
                    this.setState({savingAdd: false, qi: ""})
                  })
                })
              }}
              className="btn btn-primary">Add</button>
          </div>
        </div>
      </div>
      { false &&
        <div>
           <p style={{marginTop: "15px"}}>Create a new organization or ask your organization administrator to add you to the account.</p>
          <div className="card" style={{marginTop: "15px", width: "400px"}}>
            <div className="card-header">Add Team Member</div>
            <div className="card-body">
              <input
                className="form-control"
                value={email}
                onChange={(e) => this.setState({email: e.target.value})}
                placeholder="Email"
              />
              <button
                style={{width: "100%", marginTop: "15px"}}
                className="btn btn-success"
                disabled={!this.isValidNewUser()}
                onClick={() => {
                  this.addUser()
                }}
              >{ saving ? "Adding User..." : "Add User"}</button>
            </div>
          </div>
        </div>
      }
    </div>
  }
}

const mapStateToProps = (state, props) => {
  const {match: { params: { organizationId } } } = props
  const {
    organizations,
    organizationUsers,
    users,
    quizInstances
  } = state.entities
  const organization = organizations[organizationId]

  if (!organization) {
    return {
      quizInstances: []
    }
  }

  return {
    organization,
    quizInstances: organization.quizInstances ? organization.quizInstances.map((qid) => quizInstances[qid]) : [],
    users
  }
}

export default connect(mapStateToProps, {
  entitiesGetOrganization, entitiesCreateOrganizationUser, entitiesToggleOrganizationActive,
  entitiesRemoveQuizInstanceFromOrganzation, entitiesAddQuizInstanceFromOrganzation
})(Show)
