import React from 'react'
import { connect } from 'react-redux'
import {
  Link
} from "react-router-dom";
import {currentUserSignup} from "../actions/currentUser"
import mixpanel from '../utils/mixpanel'
class Signup extends React.Component {
  constructor() {
    super()
    this.state = {
      email: "",
      employer: "",
      password: "",
      confirmPassword: "",
      saving: false,
    }
  }

  signup() {
    const {email, employer, password} = this.state
    this.setState({
      saving: true
    }, () => {
      this.props.currentUserSignup({
        email,
        password
      }).then((action) => {

        if (action.type === "SIGNUP_USER_FAILURE") {
          this.setState({saving: false})
        }
      }).catch((e) => {
        this.setState({saving: false})
        console.error(e)
      })
    })
  }

  valid() {
    const {email} = this.state
    if (email.split("@").length !== 2) return false
    if (this.invalidPassword()) return false
    if (this.state.saving) return false
    return true
  }

  invalidPassword() {
    const {password, confirmPassword} = this.state
    if (password.length <= 6) return true
    if (confirmPassword.length <= 6) return true
    if (confirmPassword !== password) return true
    return false
  }

  render() {
    const {password} = this.state
    return <div>
      <div style={{width: "400px", backgroundColor: "white", border: "1px solid rgba(1,1,1,.1)", padding: "15px", margin: "100px auto"}}>
        <h4>Sign Up</h4>
        <input
          style={{marginTop: "15px"}}
          className="form-control"
          type="text"
          value={this.state.email}
          onChange={(e) => this.setState({email: e.target.value})}
          placeholder="Email *"
        />
        <input
          style={{marginTop: "15px"}}
          className="form-control"
          type="password"
          value={this.state.password}
          onChange={(e) => this.setState({password: e.target.value})}
          placeholder="Password"
        />
        <input
          style={{marginTop: "15px"}}
          className="form-control"
          type="password"
          value={this.state.confirmPassword}
          onChange={(e) => this.setState({confirmPassword: e.target.value})}
          placeholder="Confirm Password"
        />
        { password.length > 0 && password.length <= 6 && <div style={{color: "#dc3545"}}>Password must be > 6 characters</div>}
        <button
          disabled={!this.valid()}
          className="btn btn-success"
          style={{width: "100%", marginTop: "15px"}}
          onClick={() => {
            this.signup()
          }}
        >{ this.state.saving ? "Signing up..." : "Sign Up"}</button>
        <div className="text-center" style={{paddingTop: "5px", borderTop: "1px solid rgba(1,1,1,.1)", marginTop: "15px"}}>
          <Link to="/login">I have an account</Link>
        </div>
      </div>
    </div>
  }
}

const mapStateToProps = (state, props) => {
  return {
  }
}

export default connect(mapStateToProps, {
  currentUserSignup
})(Signup)
