import React, { Component } from 'react'
import { connect } from 'react-redux'
import mixpanel from '../utils/mixpanel'

class YourOrg extends Component {
  componentDidMount() {
    mixpanel.page()
    window.hbspt.forms.create({
      region: "na1",
      portalId: "20693294",
      formId: "ce6bf9c9-05e3-470e-b2fe-0b4e92ff4a2c",
      target: "#hubspot-contactus"
    });
  }
  render() {
    return <div className="container">
      <h4>Understand Your Organization</h4>
      <div id="hubspot-contactus"/>
    </div>
  }
}

function mapStateToProps(state, props) {
  return {
  }
}
export default connect(mapStateToProps, {
})(YourOrg)
