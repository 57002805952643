import React from 'react'
import { connect } from 'react-redux'
import { entitiesAdminCreateSummaryGroup } from "../../actions/entities"

class NewSummaryGroup extends React.Component {
  constructor(props) {
    super()
    this.state = this.defaultState(props)
  }
  defaultState(props) {
    return {
      name: "",
    }
    this._saving = false
  }

  createSummaryGroup() {
    if (this._saving || this.state.name === "") {
      return
    }
    this._saving = true
    this.props.entitiesAdminCreateSummaryGroup(this.state).then((action) => {
      if (action.response) {
        window.location = `/admin/summary_groups/${action.response.result}`
      }
    })
  }
  render() {
    return <div >
      New Summary Group 
      <div style={{width: "400px"}}>
        <input style={{marginTop: "5px"}} value={this.state.name} onChange={(e) => {
          this.setState({name: e.target.value})
        }} className="form-control" placeholder="Name"/>
        <button style={{width: "100%", marginTop: "5px"}} onClick={() => {
          this.createSummaryGroup()
        }} className="btn btn-success">{this._saving ? "Creating...": "Create Summary Group"}</button>
      </div>
    </div>
  }
}

const mapStateToProps = (state, props) => {
  return {
  }
}

export default connect(mapStateToProps, {
  entitiesAdminCreateSummaryGroup
})(NewSummaryGroup)
