import React, { Component } from 'react'
import { connect } from 'react-redux'
import mixpanel from '../utils/mixpanel'

class FourOFour extends Component {
  componentDidMount() {
    mixpanel.page()
  }
  render() {
    return <div className="container" style={{marginTop: "15px", marginTop: "100px"}} className="text-center">
      <h4>Page does not exist</h4>
      <span>404</span>
    </div>
  }
}

function mapStateToProps(state, props) {
  return {
  }
}
export default connect(mapStateToProps, {
})(FourOFour)
