import React from 'react'
import {
  Link
} from "react-router-dom";
import BreadCrumbs from "../../components/BreadCrumbs"

class Results extends React.Component {
  render() {
    const crumbs = [
      {name: "Tasks"}
    ]
    const {orderedRoles} = this.props

    return <div>
      <BreadCrumbs crumbs={crumbs}/>
    </div>
  }
}

export default Results
