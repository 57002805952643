export default {
  primary: "#0E7490",
  secondary: "#1D4ED8",
  // third: "rgb(80, 73, 146)",
  third: "#1D4ED8",
  nav: "rgba(10,10,10,0.24)",
  main: {
    primary: "#007bff",
    success: "#28a745",
    warning: "#ffc107",
    info: "#17a2b8",
    danger: "#dc3545",
  },
  account: "#007bff",
  admin: "#dc3545",
}
