import React from 'react'
import {
  Link
} from "react-router-dom";
import { connect } from 'react-redux'
import moment from "moment"
import { entitiesGetQuizzes, entitiesGetOrganizations , entitiesCreateOrganization} from "../../actions/entities"
import BreadCrumbs from "../../components/BreadCrumbs"

class Profile extends React.Component {
  constructor() {
    super()
    this.state = {
      name: "",
      identifier: "email",
      quizId: null,
      quizzes: [],
      saving: false
    }
  }
  componentDidMount() {
    this.props.entitiesGetOrganizations()
    this.props.entitiesGetQuizzes().then((action) => {
      if (!action.response) return
      const quizzes = action.response.result
      const quizId = quizzes.find((q) => action.response.entities.quizzes[q].category === "main")
      this.setState({
        quizzes,
        quizId
      })
    })
  }
  renderSetting(name, url) {
    const active = window.location.pathname === url
    return <div style={{borderBottom: "1px solid rgba(1,1,1,.1)", display: "flex"}}>
      <div style={{width: "3px", backgroundColor: active ? "rgba(111, 107, 145, 0.94)" : "transparent"}}></div>
      <div style={{flex: 1, padding: "7px"}}>
        {active && name}
        {!active && <Link to={url}>{name}</Link>}
      </div>
    </div>
  }
  render() {
    const {
      email, user,
      userOrganizations,
      organizations,
      currentUser,
      account
    } = this.props
    if (!email) return <div className="text-center" style={{marginTop: "15px"}}>You need to <a href="/login">login</a> to view this page</div>
    if (!currentUser) return <div>Loading</div>
    const crumbs = [
      // {name: "Leads", url: "/lrm"},
      {name: "Profile"}
    ]

    return <div>
      <BreadCrumbs crumbs={crumbs}/>
      <hr/>
      <div>
        <h6>Profile Image</h6>
        <div style={{width: "300px", padding: "40px", border: "1px dashed rgba(1,1,1,.4)"}} className="text-center">Upload Image</div>
      </div>
      <hr/>
      <div>
        <h6>Name</h6>
        <div style={{maxWidth: "300px"}}>
          <input className="form-control" placeholder="First Name"/>
          <input className="form-control" style={{marginBottom: "5px", marginTop: "5px"}} placeholder="Last Name"/>
          <button disabled className="btn btn-success" style={{width: "100%"}}>Save</button>
        </div>
      </div>
      <hr/>
      <div>
        <h6>Primary Email</h6>
        <div style={{maxWidth: "300px"}}>
          <input className="form-control" style={{marginBottom: "5px"}} value={currentUser.email} placeholder="Email"/>
          <button disabled className="btn btn-success" style={{width: "100%"}}>Save</button>
        </div>
      </div>
      <hr/>
      <div>
        <Link to="/logout">Logout</Link>
      </div>
    </div>
  }
}

const mapStateToProps = (state, props) => {
  const {
    users,
    userOrganizations,
    organizations,
    quizzes,
    accounts,
  } = state.entities
  const {
    email,
    currentUser
  } = state.currentUser
  if (!currentUser) {
    return {
      email
    }
  }
  const user = users[currentUser.id]
  if (!user) {
    return {
      email
    }
  }
  return {
    email,
    user,
    currentUser,
    account: currentUser.account
  }
}

export default connect(mapStateToProps, {
  entitiesGetOrganizations,
  entitiesCreateOrganization,
  entitiesGetQuizzes
})(Profile)
