import React, { Component } from 'react'
import { connect } from 'react-redux'
import Role from "../components/Role"
import {urlRoles} from "../components/roles/Main"
// import Role from "../components/Role"
import {parseQuery} from "../utils/queryParams"
import mixpanel from '../utils/mixpanel'

class RoleMain extends Component {
  componentDidMount() {
    mixpanel.page()
  }
  render() {
    const {name} = this.props
    const n = urlRoles[name]
    const query = parseQuery()
    return <div className="container" style={{marginTop: "15px"}}>
      <Role roleName={n} detail all={query.type == "detail"}/>
    </div>
  }
}

function mapStateToProps(state, props) {
  const {match: {params: {name}}} = props
  return {
    name
  }
}
export default connect(mapStateToProps, {
})(RoleMain)
