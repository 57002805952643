import React from 'react'
import { connect } from 'react-redux'
import { } from "../../actions/entities"
class PersonaEdit extends React.Component {
  constructor(props) {
    super()
    this.state = this.defaultState(props)
  }
  defaultState(props) {
    return {
      title: "",
      image: null,
      bio: "",
      questions: [],
      special: [],
      resistance: [],
    }
  }

  renderTextList(key) {
    const value = this.state[key]
    return <div key={key}>
      {key}
      {value.map((v, i) => {
        return <div key={i}>
          <textarea onChange={(e) => {
            const v = e.target.value
            const newV = JSON.parse(JSON.stringify(value))
            newV[i] = v
            const newState = {}
            newState[key] = newV
            this.setState(newState)

          }} placeholder={key} value={v} className="form-control"/>
        </div>
      })}
      <div>
        <button className="btn btn-link" onClick={() => {
          const newV = JSON.parse(JSON.stringify(value))
          newV.push("")
          const newState = {}
          newState[key] = newV
          this.setState(newState)
        }}>Add {key}</button>
      </div>
      <hr/>
    </div>
  }
  render() {
    const {persona} = this.props
    if (!persona) return null
    return <div style={{border: "1px solid rgba(1,1,1,.1)", padding: "15px" }}>
      {persona.name}
      <div>
        <div>
          Title:
          <input className="form-control" placeholder="Title"/>
        </div>
        <div>
          Bio
          <textarea placeholder="bio" className="form-control"/>
        </div>
        {this.renderTextList("questions")}
        {this.renderTextList("special")}
        {this.renderTextList("resistance")}
        <div style={{marginTop: "15px"}}>
          <button className="btn btn-success" onClick={() => {
          }}>
            Save
          </button>
        </div>
      </div>
    </div>
  }
}

const mapStateToProps = (state, props) => {
  const {personaId} = props
  const {personas} = state.entities
  return {
    persona: personas[personaId],
  }
}

export default connect(mapStateToProps, {
})(PersonaEdit)
