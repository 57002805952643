import React from 'react'
import { connect } from 'react-redux'
import {
  Link
} from "react-router-dom";
import BreadCrumbs from "../../components/BreadCrumbs"
import { entitiesGetMyQuizInstances } from "../../actions/entities"
import moment from "moment"

class Results extends React.Component {
  constructor() {
    super()
    this.state = {
      results: []
    }
  }
  componentDidMount() {
    this.props.entitiesGetMyQuizInstances().then((action) => {
      if (action && action.response) {
        console.log(action)
        this.setState({results: action.response.result})
      }
    })
  }
  render() {
    const crumbs = [
      // {name: "Leads", url: "/lrm"},
      {name: "My Quiz Results"}
    ]
    const {orderedRoles, quizInstances} = this.props

    
    return <div>
      <BreadCrumbs crumbs={crumbs}/>
      <div className="profile-grid">
        {this.state.results.map((id) => {
          const qi = quizInstances[id]
          const roles = qi.normalizedSummary.roles.sort((a, b) => {
            if (a.percent > b.percent) return -1
            if (a.percent < b.percent) return 1
            return 0
          })
          return <div key={id}>
            <div style={{display: "flex", justifyContent: "space-between", paddingBottom: "5px", marginBottom: "5px"}}>
              <div>
                <Link to={qi.slug}>View Quiz</Link>
              </div>
              <div>
                {moment(qi.createdAt).format("LL")}
              </div>
            </div>
            <div className="text-center">
              {roles.map((r) => r.name ).join(" - ")}
            </div>
          </div>
        })}
      </div>
    </div>
  }
}
const mapStateToProps = (state, props) => {
  return {quizInstances: state.entities.quizInstances}
}
export default connect(mapStateToProps, {
  entitiesGetMyQuizInstances
})(Results)
