import React from 'react'
import { connect } from 'react-redux'
import { entitiesGetQuizInstances } from "../../actions/entities"
import {
  Link
} from "react-router-dom";
import queryString from "query-string"
import moment from "moment"

class QuizInstances extends React.Component {
  constructor() {
    super()
    this.state = {
      newPersona: false
    }
  }
  componentDidMount() {
    this.props.entitiesGetQuizInstances(this.props.quizId)
  }

  render() {
    const {quizInstances} = this.props
    if (!quizInstances) return <div  className="container" ><div className="text-center">Loading...</div></div>

    return <div className="container"  style={{marginBottom: "30px"}}>
      <div className="card" style={{marginTop: "15px"}}>
        <div className="card-header">{quizInstances.count} Quizzes Taken</div>
        <div className="card-body">
          { quizInstances.map((qi) => {
            return <div key={qi.id}>
              <Link to={"/results/" + qi.token}>{qi.name || "no name"} - {qi.email} - {qi.gender} - {qi.age}</Link>
              <span className="text-muted" style={{marginLeft: "5px"}}>{moment(qi.createdAt).format('LLL')} </span>
            </div>
            })
          }
        </div>
      </div>
    </div>
  }
}

const mapStateToProps = (state, props) => {
  const {quizInstances, quizInstanceIds} = state.entities

  const qp = queryString.parse(window.location.search, {arrayFormat: 'bracket'})
  return {
    quizId: qp.quiz_id,
    quizInstances: quizInstanceIds.map((qi) => quizInstances[qi])
  }
}

export default connect(mapStateToProps, {
  entitiesGetQuizInstances
})(QuizInstances)
