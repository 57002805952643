import React from 'react'
import { connect } from 'react-redux'
import pdf from "../assets/terms-of-service-and-privacy.pdf"
import mixpanel from '../utils/mixpanel'

class Tos extends React.Component {
  componentDidMount() {
    mixpanel.page()
  }
  render() {
    return <div>
      <embed src={pdf} type="application/pdf" width="100%" height={(window.innerHeight - 200) + "px"} />
    </div>
  }
}

const mapStateToProps = (state, props) => {
  return {
  }
}

export default connect(mapStateToProps, {
})(Tos)
