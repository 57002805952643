import React, { Component } from 'react'
import { connect } from 'react-redux'
import Section from "../components/Section"
import Blog from "../components/blogs/Components/Blog/Blog"
import mixpanel from '../utils/mixpanel'

class Articles extends Component {
  componentDidMount() {
    mixpanel.page()
  }
  render() {
    return <div className="container">
      <Blog/>
    </div>
  }
}

function mapStateToProps(state, props) {
  return {
  }
}
export default connect(mapStateToProps, {
})(Articles)
