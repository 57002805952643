import React from 'react'
import { connect} from 'react-redux'
import moment from "moment"

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { entitiesGetQuizzes, entitiesGetOrganizations , entitiesCreateOrganization} from "../../actions/entities"

class FacilitatorsMain extends React.Component {
  constructor() {
    super()
    this.state = {
      name: "",
      identifier: "email",
      quizId: null,
      quizzes: [],
      saving: false
    }
  }
  componentDidMount() {
    this.props.entitiesGetOrganizations()
    this.props.entitiesGetQuizzes().then((action) => {
      if (!action.response) return
      const quizzes = action.response.result
      const quizId = quizzes.find((q) => action.response.entities.quizzes[q].category === "main")
      this.setState({
        quizzes,
        quizId
      })
    })
  }
  renderSetting(name, url) {
    const active = window.location.pathname === url
    return <div style={{borderBottom: "1px solid rgba(1,1,1,.1)", display: "flex"}}>
      <div style={{width: "3px", backgroundColor: active ? "rgba(111, 107, 145, 0.94)" : "transparent"}}></div>
      <div style={{flex: 1, padding: "7px"}}>
        {active && name}
        {!active && <Link to={url}>{name}</Link>}
      </div>
    </div>
  }
  render() {
    const {
      email, user,
      account,
      userOrganizations,
      organizations
    } = this.props
    if (!email) return <div className="text-center" style={{marginTop: "15px"}}>You need to login to view this page</div>
    if (!user || !user.userOrganizations) return <div>Loading...</div>
    const {name, identifier, saving, quizId} = this.state

    return <div className="container" style={{marginTop: "15px", display: "flex"}}>

      <div style={{marginRight: "15px", width: "300px"}}>
        <div className="card">
          {
             account && this.renderSetting("My Organizations", "/profile") 
          }
          { this.renderSetting("Logout", "/logout") }
        </div>
      </div>
      <div style={{flex: 1}}>
        <div className="card">
          <div className="card-header">
            Organizations
          </div>
          <div className="card-body">
            { user.userOrganizations.length === 0 &&
              <p style={{fontWeight: "bold"}}>You do not currently have access to any organizations.</p>
            }
            { user.userOrganizations.length > 0 &&
              user.userOrganizations.map((uoId) => {
                const uo = userOrganizations[uoId]
                const organization = organizations[uo.organization]
                return <div key={uoId} style={{borderBottom: "1px solid rgba(1,1,1,0.1)", padding: "5px", display: "flex", justifyContent: "space-between"}}>
                  <div>
                    <Link to={organization.slug}>{ organization.name }</Link>
                  </div>
                  <div className="text-right">
                    <div>
                      Created: { moment(organization.createdAt).fromNow() }
                    </div>
                    <div>
                      Quizes Taken: {organization.quizInstances.length}
                    </div>
                    <div>
                      {organization.expired ? "Expired" : "Active"}
                    </div>
                  </div>
                </div>

              })
            }
          </div>
        </div>
        <p style={{marginTop: "15px"}}>Create a new organization or ask your organization administrator to add you to the account.</p>
        <div className="card" style={{marginTop: "15px", width: "400px"}}>
          <div className="card-header">New Organization</div>
          <div className="card-body">
            <input
              className="form-control"
              value={name}
              onChange={(e) => this.setState({name: e.target.value})}
              placeholder="Organization name *"
            />
            <div style={{display: "flex", marginTop: "15px"}}>
              <div style={{marginRight: "15px"}}>Quiz</div>
              <select className="form-control" value={this.state.quizId} onChange={(e) => {
                this.setState({quizId: e.target.value})
                }}>
                {
                  this.state.quizzes.map((q) => {
                    const quiz = this.props.quizzes[q]
                    return <option key={q} value={q}>{quiz.category}</option>
                  })
                }
              </select>
            </div>
            <div style={{display: "flex", marginTop: "15px"}}>
              <div style={{marginRight: "15px"}}>Identifier</div>
              <select className="form-control" value={this.state.identifier} onChange={(e) => {
                this.setState({identifier: e.target.value})
                }}>
                <option value="email">Email</option>
                <option value="name">Name</option>
              </select>
            </div>
            <button
              style={{width: "100%", marginTop: "15px"}}
              className="btn btn-success"
              disabled={(name == "" || saving)}
              onClick={() => {
                this.setState({saving: true})
                this.props.entitiesCreateOrganization({name, identifier, quizId}).then(() => {
                  this.setState({saving: false})
                })
              }}
            >{ saving ? "Creating..." : "Create New Organization"}</button>
          </div>
        </div>
      </div>
    </div>
  }
}

const mapStateToProps = (state, props) => {
  const {
    users,
    userOrganizations,
    organizations,
    quizzes,
    accounts
  } = state.entities
  const {
    email,
    currentUser
  } = state.currentUser
  if (!currentUser) {
    return {
      email
    }
  }
  const user = users[currentUser.id]
  if (!user) {
    return {
      email
    }
  }
  const  account = accounts[user.account]
  console.log(account, user)
  return {
    email,
    user,
    account,
    userOrganizations,
    organizations,
    quizzes
  }
}

export default connect(mapStateToProps, {
  entitiesGetOrganizations,
  entitiesCreateOrganization,
  entitiesGetQuizzes
})(FacilitatorsMain)
