import React from 'react'
require("../stylesheets/switch.css")

export default ({onChange, value}) => {
  return <label className="switch">
    <input type="checkbox" checked={value} onChange={(e) => {
      onChange(e.target.checked)
    }} />

    <span className="slider round"></span>
  </label>
}
