import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import {
  Link
} from "react-router-dom";
import {entitiesGetCompanies, entitiesCreateCompany} from "../../actions/entities"

class Account extends Component {
  constructor() {
    super()
    this.state = {
      name: "",
      companies: []
    }
  }
  componentDidMount() {
    this.fetchCompanies()
  }
  fetchCompanies() {
    this.props.entitiesGetCompanies().then((action) => {
      const {result} = action.response
      this.setState({companies: result})
    })
  }
  create() {
    this.setState({saving: true})
    this.props.entitiesCreateCompany({name: this.state.name}).then(() => {
      this.setState({saving: false, name: ""})
      this.fetchCompanies()
    })
  }
  render() {
    const {currentUser, account, users, organizations} = this.props
    const {saving} = this.state

    if (!account) return <div>No account associated to your user</div>
      return <div>
        <button style={{margin: "15px 0"}}
          onClick={() => {
            this.setState({addNew: !this.state.addNew})
          }}
        className="btn btn-success"><i className="fas fa-plus" style={{marginRight: "5px"}}/>New Company</button>
        {
          this.state.addNew &&
            <div>
              <div className="card" style={{marginBottom: "15px", width: "400px"}}>
                <div className="card-header">New Company</div>
                <div className="card-body">
                  <input
                    className="form-control"
                    value={this.state.name}
                    onChange={(e) => this.setState({name: e.target.value})}
                    placeholder="Company Name *"
                  />
                  <button
                    style={{width: "100%", marginTop: "15px"}}
                    className="btn btn-success"
                    disabled={(this.state.name == "" || saving)}
                    onClick={() => {
                      this.create()
                    }}
                  >{ saving ? "Creating..." : "New Company"}</button>
                </div>
              </div>
            </div>
        }
      <h4>{account.name}</h4>
      <div>Companies</div>
      <div style={{padding: "5px"}}>
        <table className="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Departments</th>
            </tr>
          </thead>
          <tbody>
            {this.state.companies.map((cid) => {
              const company = this.props.companies[cid]
              return <tr key={cid}>
                <td>
                  <Link to={company.accountSlug}>{company.name}</Link>
                </td>
                <td>
                  {company.departments.length }
                </td>
              </tr>
            })}
          </tbody>
        </table>
      </div>
    </div>
  }
}

function mapStateToProps(state, props) {
  const {currentUser} = state.currentUser
  const {accounts, companies, users, organizations, departments} = state.entities
  const account = accounts[currentUser.account]
  return {
    companies,
    currentUser,
    departments,
    account,
    // users,
    // organizations
  }
}
export default connect(mapStateToProps, {
  entitiesGetCompanies,
  entitiesCreateCompany
})(Account)
